/* Blog Comments */
/* --------------------------------------------------------------------- */

.comment-wrapper {
  margin-top: 50px;

  h2.title{
    font-weight: 700;
    color: #595959;
    margin-bottom: 45px;
  }
}

.comments article{
  @include clearfix();
  .avatar{
    width: 115px;
    height: 115px;
    border: 1px solid #cecece;
    float: left;
    line-height: 0;
    margin-right: 30px;
    padding: 7px;

    img{
      width: 100%;
    }

    @media screen and (max-width: 1276px) {
      width: 75px;
      height: 75px;
    }

    @media screen and (max-width: 1002px) {
      display: none;
    }
  }

  .left & .avatar {
    width: 11%;
    height: auto;

    @media screen and (max-width: 1193px) {
      width: 9.5%;
    }
  }

  .comment{
    &:before{
      content: '';
      display: block;
      width: 17px;
      height: 28px;
      background: url('../images/comment-arrow.jpg') 0 0 no-repeat;
      position: absolute;
      top: 30px;
      left: -17px;
    }
    position: relative;
    width: 83.4%;
    float: left;
    padding: 25px;
    margin-bottom: 50px;
    background: #ededed;
    border: 1px solid #ccc;

    h3{
      margin-bottom: 0;
      font-weight: 800;
      letter-spacing: 0.5px;
      a{
        text-decoration: none;
        color: $mint;
      }
    }

    span.submitted{
      display: block;
      width: 100%;
      float: left;
      margin: 10px 0;
      border-top: none !important;
      border-bottom: none !important;
      line-height: 1em;
    }

    span.submitted > span{
      background: none;
      color: #595959 !important;
      font-weight: 700;
      margin: 20px 0;
    }

    .links a{
      color: #1c8086;
      text-transform: uppercase;
    }

    @include media($tablet){
        width: 100%;
      }
  }

}

.comment-wrapper{
  .indented{
    margin-left: 145px;
    article.comment{
      width: 100%;
    }
    article.comment .comment{
      width: 80.2%;

      @media screen and (max-width: 1025px){
        // width: 100.1%;
      }

      @include media($tablet){
        width: 100%;
      }
    }

    @media screen and (max-width: 1276px){
      margin-left: 110px;
    }
  
    @media screen and (max-width: 1025px){
      // margin-left: 110px;
    }

    @include media($tablet){
      margin-left: 20px;
    }
  }

  & > article.comment.main:last-of-type{
    margin-bottom: 2em;
    border-bottom: 1px dotted $mint;
  }
}

.comment-form{
  input[type="text"] {
    width: 440px;
    height: 30px;
    border: 2px solid  #ccc;
    border-radius: 0px;
    padding: 7.5px;
  }

  textarea{
    border: 2px solid #ccc;
    padding: 7.5px;
  }

  .filter-wrapper{
    display: none;
  }

  .captcha{
    margin-top: 2em;
    float: left;
  }

  input[type="submit"]{
    background: #fff !important;
    border: 1px solid #1c8086;
    font-size: 1.125em !important;
    color: #1c8086 !important;
    padding: 12px;
  }
}

article.node-blog .content + .links{
  display: none;
}