.user-session-management {
  line-height: 12px;

  .logged-in & {
    margin: 10px 0;
  }

  .background {
    background: rgba(#fff, 0.1);
    color: #fff;
    padding: 10px 15px;
  }

  float: right;

  .name, .actions {
    line-height: initial;
  }

  .name {
    font-size: 14px;
  }

  .actions {
    font-size: 11px;
    a, a:visited {
      color: rgba(#fff, 0.5);
    }
  }
  .center-members-link{
    display: block;
    margin-top: 10px;
    a{
      color: white;
    }
  }
}
