.primary-page {
  section#highlighted {
    margin: 0 0 40px;

    @include media($laptop) {
      margin-bottom: 0;
    }

  }

  #highlighted img {
    width: 100%;
  }

  .flex-next,
  .flex-prev {
    text-indent: -9999px;
  }

  section#content {
    padding-right: 22.5px;
  }

  aside#sidebar-second {
    padding-left: 22.5px;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      padding-top: 0 !important;
    }

    @include media($tablet) {
      padding-right: 20px;
    }

    @include media($mobile) {
      padding-right: 10px;
    }
  }

  #content h1 {
    font-family: 'HelveticaNeueW01-57Cn';
    color: #128087;
    font-size: 3em;
    line-height: 1em;
    letter-spacing: 0;
    font-weight: 800;
    margin-bottom: 0.33em !important;
    display: block;
    width: auto !important;

    @include media($tablet) {
      margin-top: 40px;
      padding-left: 20px;
    }
  }

  .field-name-body h3 {
    font-weight: 800;
    letter-spacing: 0;
  }

  .view-testimonials-block,
  #block-block-17,
  #block-block-18,
  #block-block-22 {
    // display: none;
  }

  .services {
    @include clearfix();

    @include media($tablet) {
      padding: 0 20px;
    }

    .block-inner {
      border: none;
    }
  }

  .services .service,
  .services .views-row {
    @include span-columns(4 of 8);
    @include omega(2n);

    img {
      width: 100%;
    }

    a {
      text-decoration: none;
    }

    @include media($mobile) {
      width: 100%;
      float: none;
    }
  }

  .wrapper {
    @include clearfix();
    padding: 25px 0;
  }

  .networks {
    @include media($tablet) {
      padding: 0 20px;
    }
  }

  .networks img {
    display: block;
    width: 100%;
    margin-top: 25px;
  }

  #sidebar-second {
    padding-top: 0;

    h1.title {
      margin-top: 20px;
      line-height: 1em;
    }
  }

  #sidebar-second i {
    display: block;
    float: left;
    font-size: 60px;
    color: #6DA5AA;
    margin: 25px 25px 25px 0;
  }

  .bucket-title.cta {
    padding: 20px 0 40px;
  }

  .bucket-title.cta h3 {
    left: -85px;
  }

  .bucket-title.cta p:last-of-type {
    margin-bottom: 0;
  }

  .member.bucket-title {
    p {
      margin-bottom: 0;
    }
  }

  .video {
    margin-top: 60px;
  }

  .spotlight .spotlight {
    @include span-columns(4 of 8);
    @include omega(2n);

    img {
      width: 100%;
    }

    p {
      margin-bottom: 0;
    }

    @include media($tablet) {
      width: 100%;
      float: none;
    }
  }

  .field-name-field-gallery-images {
    .field-item img {
      width: 100%;
      height: auto;
    }
  }

  .field-name-field-gallery-images-slider {
    margin-top: 30px !important;

    .flex-viewport {
      padding-top: 11px;
      overflow-y: visible !important;
    }

    .field-item {
      position: relative;
      padding: 10px;
      margin-right: 30px;
      background-color: transparent;
      -webkit-transition: background-color 0.3s ease;
      -moz-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
      overflow: visible;

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 11px;
        z-index: 5;
        position: absolute;
        top: -1px;
        left: 50%;
        margin-left: -7px;
        opacity: 0;
        background-color: transparent;
        background-image: none !important;
        -webkit-transition: top 0.3s ease 0.3s,
          opacity 0.3s ease 0.2s;
        -moz-transition: top 0.3s ease 0.3s,
          opacity 0.3s ease 0.2s;
        -o-transition: top 0.3s ease 0.3s,
          opacity 0.3s ease 0.2s;
        transition: top 0.3s ease 0.3s,
          opacity 0.3s ease 0.2s;
      }

      &.flex-active-slide {
        background-color: $mint;

        &::before {
          top: -11px;
          opacity: 1;
          background-image: url('../images/gal_thumb_arrow.png') !important;
        }
      }
    }
  }
}

#event-footer-wrapper {
  margin-top: 0 !important;
  padding-top: 50px;
  padding-bottom: 50px;

  @include media($laptop) {
    display: block !important;
    padding: 0 20px;
  }

  @include media($mobile) {
    padding: 0 10px;
  }

  h3,
  h2.block-title {
    font-family: 'HelveticaNeueW01-57Cn', sans-serif !important;
    font-size: 19.5px !important;
    letter-spacing: 1px;
    font-weight: 300 !important;
    color: #6DA5AA !important;
    background: #fff !important;

    a {
      text-decoration: none;
    }
  }

  h3 a {
    font-size: 14px !important;
    text-decoration: underline;
  }
}

#block-bvc_page_attachments-gallery {
  margin-top: 60px;

  .block-inner {
    .block-inner {
      padding: 0;
      border: none;
    }
  }

  @include media($mobile) {
    margin-top: 0;
  }
}

#block-views-calendar-block_3 {
  .event {
    @include span-columns(3 of 12);
    @include omega(4n);
    background: #fff;
    padding: 15px !important;
    border: 1px solid #cccccc !important;
    border-radius: 8px;
    min-height: 125px;
    margin-right: 2.22563% !important;
    width: 23.33078% !important;

    p,
    span {
      font-family: 'Helvetica W01 Roman', arial, sans-serif !important;
    }

    h3 {
      width: 100%;
    }

    &:nth-of-type(4) {
      margin-right: 0 !important;
    }

    &:nth-of-type(5) {
      display: none !important;
    }

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n);
      margin-right: 3.37602% !important;
      width: 48.31199% !important;
      margin-bottom: 25px;

      &:nth-of-type(2) {
        margin-right: 0 !important;
      }
    }

    @include media($mobile) {
      width: 100% !important;
      margin-right: 0 !important;
    }


  }

  .pager {
    display: none;
  }
}
