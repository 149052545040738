.button,
input[type="submit"].button {
	display: block !important;
	font-family:'Helvetica W01 Roman', helvetica, arial, sans-serif;
	color: #fff !important;
	text-align: center;
	background: #2f7eb1; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  #2f7eb1 0%, #1c5c86 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#2f7eb1), color-stop(100%,#1c5c86)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,	#2f7eb1 0%,#1c5c86 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #2f7eb1 0%,#1c5c86 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,	#2f7eb1 0%,#1c5c86 100%); /* IE10+ */
	background: linear-gradient(to bottom,	#2f7eb1 0%,#1c5c86 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7eb1', endColorstr='#1c5c86',GradientType=0 ); /* IE6-8 */
	margin: 10px 0;
	padding: 5px 10px;
	&.green{
		background: #80b65e; /* Old browsers */
		background: -webkit-gradient(linear, 0 0, 0 100%, from(#80b65e), to(#6a9450));
		background: -webkit-linear-gradient(#80b65e 0%, #6a9450 100%);
		background: -moz-linear-gradient(#80b65e 0%, #6a9450 100%);
		background: -o-linear-gradient(#80b65e 0%, #6a9450 100%);
		background: linear-gradient(#80b65e 0%, #6a9450 100%); /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */ /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80b65e', endColorstr='#6a9450',GradientType=0 ); /* IE6-9 */
	}
}

@mixin button{
	display: block !important;
	font-family:'Helvetica W01 Roman', helvetica, arial, sans-serif;
	color: #fff !important;
	text-align: center;
	background: #2f7eb1; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  #2f7eb1 0%, #1c5c86 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#2f7eb1), color-stop(100%,#1c5c86)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,	#2f7eb1 0%,#1c5c86 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #2f7eb1 0%,#1c5c86 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,	#2f7eb1 0%,#1c5c86 100%); /* IE10+ */
	background: linear-gradient(to bottom,	#2f7eb1 0%,#1c5c86 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7eb1', endColorstr='#1c5c86',GradientType=0 ); /* IE6-8 */
	margin: 10px 0;
	padding: 5px 10px;
}

.button-icon.button-icon {
  font-family: 'Arial';
  font-size: 16px;
  cursor: pointer;
  padding: 25px 60px;
  letter-spacing: 1px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 25px 30px 25px 80px;
  color: #fff;
  text-decoration: none;
  &:active {
    top: 2px;
  }
  &:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    text-align: center;
    width: 60px;
    background: rgba(0,0,0,0.15);
    font-family: 'FontAwesome';
    speak: none;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
  }
}
