@mixin AreaColor($area, $opacity: 1) {
  $color: transparent;
  @if $area == 'intercultural' {
    $color: $areaIntercultural;
  } @else if $area == 'workforce' {
    $color: $areaWorkforce;
  } @else if $area == 'language' {
    $color: $areaLanguage;
  } @else if $area == 'research' {
    $color: $areaResearch;
  } @else if $area == 'all' {
    $color: $areaAll;
  }

  background-color: rgba($color, $opacity);
  a.area-link {
    background:transparent;
  }
}

span.area-link,
a:link.area-link,
a:visited.area-link,
.page-members a.area-link{
  display: inline-block;
  padding: 3px 8px;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  width: auto;
  font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
  font-size: 13px;
  line-height: 19.5px;
  margin-right: 4px;
  cursor: pointer;

  &.Intercultural { @include AreaColor('intercultural'); }
  &.Workforce,
  &.Career        { @include AreaColor('workforce'); }
  &.Language,
  &.English      { @include AreaColor('language'); }
  &.Research      { @include AreaColor('research'); }
  &.All           { @include AreaColor('all'); }
}

.view-content .cbp .views-row {
  &.Intercultural h3.title a,
  &.intercultural-development h3.title a { @include AreaColor('intercultural', 0.8); }
  &.Workforce     h3.title a,
  &.workforce-development h3.title a { @include AreaColor('workforce', 0.8); }
  &.Career     h3.title a,
  &.career-advancement h3.title a { @include AreaColor('workforce', 0.8); }
  &.Language      h3.title a,
  &.language-development h3.title a { @include AreaColor('language', 0.8); }
  &.English      h3.title a,
  &.english-language-learning h3.title a { @include AreaColor('language', 0.8); }
  &.Research      h3.title a,
  &.research-and-development h3.title a { @include AreaColor('research', 0.8); }
  a.area-link {
    background:transparent;
  }
}

span.area-link{
  cursor: default;
}

// Related posts

#sidebar-first{
  .view-content .cbp-item-wrapper{
    width: 300px;
    height: 200px;
    max-width: 100%;
    position: relative;
    .split-left{
      width: 100%;
      position: absolute;
      z-index: 1;
      img{
        width: 100%;
        height: 200px;
        position: absolute;
        z-index: 1;
      }
    }

    .split-right{
      margin-top: 0 !important;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      h3.title{
        top: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        line-height: 26px;
        z-index: 2;
      }
      h3.title a{
        color: #fff;
        display: block;
        padding: 20px;
        width: 100%;
        height: 100%;
      }
      & > a:first-of-type,
      .area-link{
        display: inline-block;
        padding: 3px 8px;
        text-decoration: none;
        position: absolute;
        z-index: 3;
        bottom: 15px;
        left: 10px;
        width: 150px;
        font-size: 16px !important;
        border-radius: 0;
        color: #fff;
        font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
        font-size: 13px;
        line-height: 19.5px;
      }
      .created{
        position: absolute;
        z-index: 3;
        bottom: 5px;
        right: 20px;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
      }
    }
    &.Intercultural h3.title a,
    &.intercultural-development h3.title a { @include AreaColor('intercultural', 0.8); }
    &.Workforce     h3.title a,
    &.workforce-development h3.title a { @include AreaColor('workforce', 0.8); }
    &.Career     h3.title a,
    &.career-advancement h3.title a { @include AreaColor('workforce', 0.8); }
    &.Language      h3.title a,
    &.language-development h3.title a { @include AreaColor('language', 0.8); }
    &.English      h3.title a,
    &.english-language-learning h3.title a { @include AreaColor('language', 0.8); }
    &.Research      h3.title a,
    &.research-and-development h3.title a { @include AreaColor('research', 0.8); }
    a.area-link {
      background:transparent;
    }

  }
  .view-blog-entry-views .views-row{
    @media screen and (max-width: 910px){
      float: left;
      width: 45% !important;
      margin: 2% 2% 0 0;
      .cbp-item-wrapper{
        width: 100%;
      }
    }
    @media screen and (max-width: 615px) {
      width: 100% !important;
      float: none;
    }
  }
}
