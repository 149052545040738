.node-type-second-level-page,
.node-type-network,
.node-type-page,
.node-type-tool,
.node-type-resource-finder {
  overflow-x: hidden;

  #page #main {
    padding-top: 50px;

    @include media($tablet) {
      margin-bottom: 0;
    }

    @include media($mobile) {
      padding-top: 0;
    }
  }

  .breadcrumb {
    margin-top: 0;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 12px;
    // font-weight: bold;
    letter-spacing: 1px !important;
  }

  h1.title {
    padding-left: 0;
    padding-top: 20px;
  }

  article.node-second-level-page>header {
    @include row;
  }

  .left,
  .right {
    @include span-columns(6 of 12);
  }

  #block-system-main .node>header>.right {
    margin-top: 45px;

    @include media($tablet) {
      margin-top: 0;
    }
  }

  .field-name-field-slogan {
    font-size: 2em;
    line-height: 1.3em;
    font-family: 'HelveticaNeueW01-57Cn';
    letter-spacing: -0.1px;
    color: $mint;
    margin-bottom: 1.5em;
  }

  .field-name-body p {
    @include media($mobile) {
      margin-top: 1.2em;
      line-height: 1.5em;
    }
  }

  .section-links {
    li {
      margin-bottom: 10px !important;
    }

    li a {
      font-size: 1.24em !important;
    }

    .field-name-field-page-attachments .field-item {
      border-bottom: none;

      &.odd {
        background: none;
      }
    }

    .field-name-field-slogan {
      font-style: italic;
      color: $darkGrey;
      font-size: 1.0em;
    }
  }

  a.multilevel-anchor {
    position: absolute;
    top: -220px;
  }

  // the page attachments
  .field-name-field-page-attachments>.field-items>.field-item {
    @include row;
    position: relative;

    .paragraphs-item-block-attachment,
    article.node-page-attachment-tabs,
    article.node-page-attachment-slideshow,
    article.node-page-attachment-basic {
      @include media($tablet) {
        padding: 0 20px;

        .right,
        .left {
          margin-top: 30px;
        }

        .right {
          margin-bottom: 30px;
        }

        .field-group-accordion {
          margin-top: 0;
        }

        .field-name-field-bottom-tab-label {
          margin-top: 30px;
        }
      }

      @include media($mobile) {
        padding: 0 10px;
      }
    }


    .node h2 {
      color: #128087;
      font-family: 'HelveticaNeueW01-57Cn';
      font-size: 3em;
      line-height: 1em;
      margin-bottom: 0.75em;
    }

    .field-name-field-slogan,
    .field-name-field-slogan p {
      color: #6DA5AA;
      font-family: 'Helvetica W01 Cn';
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.7rem;
      font-size: 1.3rem;
      margin-bottom: 1.5em;
    }

    .field-name-field-page-attachment-info {
      font-size: 1.2em;
      padding: 15px;
      color: $mint;
      background-color: #fff;
      border: 3px solid $mint;
      font-style: italic;
      font-family: 'HelveticaNeueW01-57Cn';
    }

    .paragraphs-item-page-attachment-tab {
      .field-name-field-display-title {
        font-size: 1.2em;
        color: #595959;
        font-family: 'HelveticaNeueW01-57Cn';
        line-height: 1.5em;
        color: #595959;
        font-size: 1.2em;
        letter-spacing: normal;
        margin: 20px 0;
      }

      .field-name-field-display-title {
        font-weight: bold;
      }
    }

    .field-name-body ul {
      margin-left: 25px;
      margin-bottom: 25px;

      li {
        font-family: 'HelveticaNeueW01-57Cn';
        font-size: 1.2em;
        line-height: 1.5em;
        color: $darkGrey;
        margin: 0;
      }
    }

    &:last-child {
      border-bottom: none !important;
    }

    &.even {
      // border-bottom: 2px solid #ececec;
      // border-top: 2px solid #ececec;
      background: #f5f5f5;
    }

    article {
      padding-top: 50px;
      padding-bottom: 50px;
      @include outer-container();
    }

    .fluid-width-video-wrapper {
      width: 40%;
      margin: 0 auto -33.5%;

      iframe {
        width: 100%;
        height: 40%;
      }

      @include media($tablet) {
        width: 66%;
        margin: 0 auto;

        iframe {
          height: 100%;
        }
      }

      @include media($mobile) {
        width: 90%;
      }
    }

    // block reference fields
    .block {
      padding-top: 50px;
      padding-bottom: 50px;
      @include outer-container();

      // blocks inside blocks dont get block rules #turtle
      .block {
        padding-top: 20px;
        padding-bottom: 0;
      }

      @include media($mobile) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    #block-bvc_page_attachments-social_hub {

      .twitter-link,
      .youtube-link {
        display: block;
        text-indent: -9999px;
        overflow: hidden;
        height: 49px;
      }

      .twitter-link {
        background: transparent url('../images/twitter-follow.jpg') top center no-repeat;
      }

      .youtube-link {
        background: transparent url('../images/youtube-watch.jpg') top center no-repeat;
      }

      .content>div>a {
        margin-bottom: 30px;
      }

      .youtube.hub-post {
        @include span-columns(3 of 12);
        @include omega(4n);
        margin-bottom: 12px;
        height: 0;
        padding-top: 13.1%;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: auto;
          position: absolute;
          top: -16.55%;
        }
      }

      .flexslider {
        background-color: transparent;

        .slides li {
          text-align: center;
        }
      }
    }

    // different than default behavior for link widget
    article.node-page-attachment-full-width-link {
      width: 100%;
      max-width: 100%;
      text-align: center;

      h2 {
        color: #fff;
      }

      .field-item {

        p,
        a {
          color: #fff;
        }
      }

      .field-name-field-link {
        margin-top: 45px;
      }

      .field-item a {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.0em;
        width: auto;
        border: 5px solid #fff;
        border: 5px solid rgba(255, 255, 255, 0.7);
        padding: 10px;
        text-decoration: none;
        background-color: transparent;

        &:hover {
          background-color: #fff;
          color: $darkGrey;
        }
      }

      h2 {
        font-family: "HelveticaNeueW01-57Cn";
        font-size: 3em;
        font-weight: bold;
        letter-spacing: -1px;
      }

      .field-name-field-slogan .field-item {
        color: white;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.3em;
        font-size: 1em;
        font-family: "HelveticaNeueW01-57Cn";
        font-style: italic;
        opacity: .6;
        padding-bottom: 20px !important;
      }
    }

    article[style*="tedx-width-"] {
      // * temp style override

    }
  }

  .field-name-field-tab-label,
  .field-name-field-bottom-tab-label {
    .field-item {
      font-family: 'HelveticaNeueW01-57Cn';
      font-size: 1.2em;
      line-height: 2.5em;
      color: $darkGrey;
      font-style: italic;
    }
  }

  .top-link {
    position: fixed;
    bottom: 0;
    right: 50px;
    background-color: #333;
    box-shadow: 3px 2px 7px #222;
    -webkit-box-shadow: 3px 2px 7px #222;
    border-radius: 20px 20px 0 0;

    a {
      display: block;
      padding: 15px;
      text-decoration: none;
      font-family: 'HelveticaNeueW01-57Cn';
      font-size: 18px;
      color: #fff;
      transition: padding-bottom 0.3s ease-in-out;

      &:hover {
        padding-bottom: 30px;
      }
    }

    @include media($mobile) {
      display: none;
    }
  }

  /* Fix for flexslider blocks that have different heighted content */
  #block-bvc_page_attachments-tool_feature {
    h2.block-title~.content {
      padding-top: 30px;
    }
  }

  .contextual-links-region {
    @include clearfix();
  }

  #top-footer-outer {
    margin-top: 0;
  }

  @include media($tablet) {

    .right,
    .left {
      @include span-columns(8 of 8);
    }
  }

  &.page-language-development-ell-programs #main #content h1.title {
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    color: #128087;
    font-size: 3em;
    line-height: 1em;
    letter-spacing: 1px;
  }

  .content .messages.error {
    background: #ffdada;
    border: 1px solid #FF9B9B;
    padding: 20px 20px 0;
  }
}
