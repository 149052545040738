//************************************************************************//
// Example: @include prefixer(border-radius, $radii, webkit ms spec);
//************************************************************************//
$prefix-for-webkit:    true !default;
$prefix-for-mozilla:   true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera:     true !default;
$prefix-for-spec:      true !default; // required for keyframe mixin

@mixin prefixer ($property, $value, $prefixes) {
  @each $prefix in $prefixes {

    @if $prefix == webkit and $prefix-for-webkit == true {
      -webkit-#{$property}: $value;
    }
    @else if $prefix == moz and $prefix-for-mozilla == true {
      -moz-#{$property}: $value;
    }
    @else if $prefix == ms and $prefix-for-microsoft == true {
      -ms-#{$property}: $value;
    }
    @else if $prefix == o and $prefix-for-opera == true {
      -o-#{$property}: $value;
    }
    @else if $prefix == spec and $prefix-for-spec == true {
      #{$property}: $value;
    }
    @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}

@mixin disable-prefix-for-all() {
  $prefix-for-webkit:    false;
  $prefix-for-mozilla:   false;
  $prefix-for-microsoft: false;
  $prefix-for-opera:     false;
  $prefix-for-spec:      false;
}
