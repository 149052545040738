.featured-content {
  .flexslider {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
  }

  .flex-direction-nav a {
    width: 44px;
    height: 44px;

    &::before {
      content: '';
    }

    &.flex-prev {
      background: transparent url('../images/gal-nav-left.png') top left no-repeat;
      left: 0;
    }

    &.flex-next {
      background: transparent url('../images/gal-nav-right.png') top left no-repeat;
      right: 0;
    }
  }

  ul.slides {
    margin: 0 !important;
  }

  .split-left {
    @include span-columns(6 of 12);

    @include media($tablet) {
      @include span-columns(8 of 8);
      @include row();
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
      @include row();
    }
  }

  .split-right {
    @include span-columns(6 of 12);
    @include omega();

    @include media($tablet) {
      //padding: 0 20px;
      margin-top: 30px;
      @include span-columns(8 of 8);
      @include row();
    }

    @include media($mobile) {
      padding: 0 5px;
      margin-top: 30px;
      @include span-columns(4 of 4);
      @include row();
    }

    span.area {
      display: block;
      width: 100%;
    }

    h3.title {
      display: block;
      float: none !important;
      clear: both;
      width: 100% !important;
      font-size: 28px;
      margin: 10px 0 0 0;
      padding-bottom: 30px;

      a {
        text-decoration: none;
      }
    }

    .created {
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
    }

    img {
      width: auto;
    }
  }

  .read-more {
    font-size: 16px;
    font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
    font-style: italic;
    color: #333;
  }
}
