// PRODUCTS & 
.page-products-services #main{
	#content{
		#breadcrumb{
      display: none !important;
		}
		h1.title.title{
      margin-top: 40px;
      @include media($mobile){
        margin-top: 0 !important;
      }
		}
		h3 {
			font-family: 'Helvetica W01 Light Cn',helvetica,arial,sans-serif;
			margin-bottom: 45px;
		}
	}
	.teaser{
		p{
			font-size: 1em;
		}

		a{
			color: $green;
		}
	}

	.ajax-progress .throbber{
		top: 0;
		left: 35%;
	}
}

.node-type-product{
	#main {
    @include media($tablet) {
      background: transparent;
    }
		#content{
      width: 100%;
      @include outer-container();
      .flex-control-nav {
        bottom: -60px;
      }
      #content-header {
        margin-top: 30px;
        @include span-columns(6 of 12);
        @include media($tablet) {
          @include span-columns(8 of 8);
          @include row();
        }
        @include media($mobile) {
          @include span-columns(4 of 4);
          padding: 0 10px;
        }
        #breadcrumb {
          margin-top: 0;
          margin-bottom: 30px;
        }
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
      #content-area {
        margin-top: 105px;
        @include span-columns(6 of 12);
        @include omega();
        @include media($tablet) {
          margin-top: 20px;
          @include span-columns(8 of 8);
          @include row();
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        @include media($mobile) {
          @include span-columns(4 of 4);
          padding: 0 20px !important;
        }
      }
          .flexslider{
            margin-bottom: 0;
          }
			h1.title {
        width: 100%;
				position: static !important;
				//margin: 0 !important;
				padding-top: 0 !important;
				left: 0 !important;
        @include media($tablet) {
          padding: 0;
        }
        @include media($mobile){
          margin-bottom: 0.3em;
        }
			}

			.button{
				width: 150px;
			}
			.field-name-field-file-upload, .field-name-field-product-link {
				float: left;
				margin-right: 10px;
			}

			.field-name-field-product-category{
				clear: left;
				.field-item {
					float: left;
					margin-right: 10px;
				}
			}

			.field-name-field-related-products{
        @include span-columns(12 of 12);
				.field-item{
					p.body {
						line-height: 1.2em;
						margin-bottom: 50px;
					}
					.button{
						position: absolute;
						bottom: 0;
						max-width: 130px;
					}
					@include media($mobile) {
						@include row();
						@include span-columns(4 of 4);
						p.body {
							margin-bottom: 0;
						}
						.button{
							position: static;
							margin-bottom: 20px;
						}
					}
				}
			}
      .field-name-field-related-products {
        @include media($mobile) {
          padding: 0 20px;
          margin-top: 60px;
        }
        .field-label {
          display: inline;
          padding: 0 15px;
          margin-left: 15px;
          background: #fff;
          position: relative;
          top: -1.6em;
          font-family: 'HelveticaNeueW01-57Cn' !important;
          color: #6da5aa !important;
          font-size: 1.5em;
          line-height: 1em;
          letter-spacing: 1px;
          @include media($mobile) {
            top: -0.5em;
          }
        }
        margin-top: 25px;
        p, a{
          font-size: 1em;
        }
        .field-items .field-item{
          .title{
            position: static;
            font-family: 'Helvetica W01 Bold';
            font-size: 1em;
            line-height: 1.2em;
            width: 100%;
            padding: 0;
            margin-bottom: 5px;
            a{
              color: $green;
              &:hover{
                color: $lightGreen;
                text-decoration: none;
              }
            }
          }
          @include span-columns(3 of 12);
          @include media($tablet) {
            @include span-columns(4 of 8);
            @include omega(2n);
          }
          @include media($mobile) {
            margin: 10px 0;
            @include span-columns(4 of 4);
            @include omega();
          }
        }
      }
		}
	}
}
