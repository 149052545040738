// Flexslider specific styles

.flexslider{
	border: none;
  border-radius: 0;
  box-shadow: none;
  ul.slides {
    // fix global ul margin
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    li{
      margin-bottom: 0;
    }
    li img {
      // make sure images always fill up slide container
      margin: 0 !important;
    }
  }
  &:hover {
    .flex-next {
      right: 0;
    }
    .flex-prev {
      left: 0;
    }
  }
}

.flexslider .slides img{
	height: auto;
  border: 1px solid #ccc;
}

// replace nav arrows with sitewide style
.flex-direction-nav {
  margin: 0 !important;
}
.flex-direction-nav .flex-prev {
  width: 44px;
  height: 44px;
  color: transparent;
  background: transparent url('../images/gal-nav-left.png') top left no-repeat;
  text-shadow: none;
}
.flex-direction-nav .flex-next {
  width: 44px;
  height: 44px;
  color: transparent;
  background: transparent url('../images/gal-nav-right.png') top left no-repeat;
  text-shadow: none;
}

.flex-direction-nav {
  a::before, a::after {
    content: "" !important;
  }
} 
