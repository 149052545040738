@mixin row($display: block) {
  @include clearfix;
  @if $display == table {
    display: table;
    $container-display-table: true;
  }

  @else {
    display: block;
    $container-display-table: false;
  }
}

@mixin reset-display {
  $container-display-table: false;
}
