.page-user-register,
.page-user-edit {
  .column {
    @include span-columns(6 of 12);
    @include omega(2n);

    input {
      max-width: 100%;
    }

    input[type="text"],
    input[type="select"],
    textarea {
      border: 2px solid #ccc;
      border-radius: 0;
      padding: 8px;
    }

    @include media($tablet) {
      width: 100%;
    }
  }

  input[type="submit"] {
    @include button();
    // @include shift(6);
    padding: 10px;
    font-family: 'Helvetica W01 Cn', helvetica, arial, sans-serif;
    font-weight: bold;
    display: inline;

    &:hover {
      opacity: 0.7;
    }

    @include media($mobile) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .description {
    width: 70%;
  }

  .form-actions {
    padding-top: 20px;
    clear: both;
  }

  .form-item-picture-custom {
    display: none;
  }

  .form-item-picture-select {
    display: inline-block;
    border: 2px solid #ccc;

    &.active {
      border-color: #000;
    }

    .form-radio {
      display: none;
    }

    .profile-default {
      width: 62px;
      height: 63px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      .form-file {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .password-strength {
    float: none;
  }

  .form-radios.picture-select+fieldset {
    display: none;
  }
}

.section-user {
  #tabs {
    top: 0;
    left: 0;
    position: static;
    padding-top: 200px;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    padding-top: 0;

    ul {
      display: inline;
      padding-right: 30px !important;
      padding-left: 0;
      margin-left: 0;

      li {
        padding: 10px;
        display: inline;
        border: 1px solid $mint;

        a {
          padding: 0;
          display: inline;

          span {
            padding: 0;
            display: inline;
            color: $darkMint;
          }
        }
      }
    }

    #tab-handle {
      display: none;
    }

    @include media ($mobile) {
      margin-top: 25px;
    }
  }

  .form-wrapper.captcha {
    width: 33%;
  }

  .tabs.primary {
    @include media($tablet) {
      margin-left: 20px !important;
    }

    @include media($mobile) {
      li {
        display: block !important;
        width: 100%;
        float: none;
        margin-bottom: 10px !important;
      }
    }
  }

  #content {
    @include media($mobile) {
      padding: 0 20px !important;
    }
  }

  #user-login,
  #user-pass {
    @include media($mobile) {

      input[type="text"],
      input[type="password"] {
        width: 100%;
      }
    }
  }

  #main #content-area {
    @include media($mobile) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.page-user-register,
.page-user-edit,
.page-user-password,
.page-user-login,
.page-user {
  h1.title {
    margin-top: 80px;
  }
}

.page-user-password,
.page-user-login,
.page-user {

  input[type="text"],
  input[type="password"] {
    height: auto;
    border: 2px solid #ccc;
    border-radius: 0;
    padding: 8px;
  }

  .form-actions {
    padding-top: 20px;
  }

  input[type="submit"] {
    padding: 10px;
    font-family: 'Helvetica W01 Cn', hevetica, arial, sans-serif;
    font-weight: bold;

    &:hover {
      opacity: 0.7;
    }
  }
}

.page-group-node-subscribe {
  #main #content {
    margin-top: 90px;
    @include span-columns(8 of 12);

    h1.title {
      line-height: 1.2em;
    }

    form {
      font-family: 'Helvetica W01 Cn', hevetica, arial, sans-serif;
      font-size: 1.2em;
    }

    label {
      margin-bottom: 10px;
    }

    .button {
      font-family: 'Helvetica W01 Cn', hevetica, arial, sans-serif;
      margin-right: 20px;
      padding: 10px;
      display: inline-block !important;
    }
  }
}

.page-user-edit.role-authenticated-user {
  #edit-timezone {
    @include span-columns(6 of 12);

    .chosen-container {
      width: 100% !important;
    }
  }

  input#edit-submit {
    margin-left: 0 !important;
  }
}

#user-cancel-confirm-form {
  input[type="submit"] {
    @include button();
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b12f2f+0,861c1c+100 */
    background: #b12f2f;
    /* Old browsers */
    background: -moz-linear-gradient(top, #b12f2f 0%, #861c1c 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #b12f2f 0%, #861c1c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #b12f2f 0%, #861c1c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b12f2f', endColorstr='#861c1c', GradientType=0);
    /* IE6-9 */
  }

  .form-actions a {
    @include button();
    float: left;
  }
}
