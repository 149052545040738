@mixin retina-image($filename, $background-size, $extension: png, $retina-filename: null, $asset-pipeline: false) {
  @if $asset-pipeline {
    background-image: image_url($filename + "." + $extension);
  }
  @else {
    background-image: url($filename + "." + $extension);
  }

  @include hidpi {

    @if $asset-pipeline {
      @if $retina-filename {
        background-image: image_url($retina-filename + "." + $extension);
      }
      @else {
        background-image: image_url($filename + "@2x" + "." + $extension);
      }
    }

    @else {
      @if $retina-filename {
        background-image: url($retina-filename + "." + $extension);
      }
      @else {
        background-image: url($filename + "@2x" + "." + $extension);
      }
    }

    background-size: $background-size;

  }
}
