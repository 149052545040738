// Old styles, imported from custom.scss

.front {

  #page {
    max-width: 1416px;
    // @include pad(0 57px);
    position: relative;
    // margin: -250px auto 0;
    background: #FFF;

    #main {
      background: #fff !important;
      margin-top: 0;

      #content {
        margin-top: 0;
        padding-top: 20px;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    @media screen and (max-width: 1435px) {
      margin-top: -65px;
    }

    @include media ($tablet) {
      margin-top: -65px;
    }

    @include media ($mobile) {
      margin-top: -65px;
      @include pad(0);
    }
  }

  #highlighted~#page>#main {
    margin-top: 0;
    padding-top: 0;
    ;
  }

  // Lookbook
  .right {
    h2.dotted a {
      font-size: 0.7em;
      margin-left: 10px;
    }

    .tight {
      margin-bottom: 5px;
    }
  }

  .testimonials.wrap {
    margin-top: 55px;
    float: left;
  }

  //separating to avoid getting too
  #sidebar-second {
    width: 45.88%;
    padding: 0;
    margin-top: -50px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .statement {
    border-bottom: none;
    font-size: 5.6em;
    margin-bottom: 0;

    @include media ($mobile) {
      font-size: 3em !important;
    }
  }

  p.half {
    @include span-columns(6 of 12);
    float: left;
    font-size: 1em;

    &.quote {
      background: url('../images/pullquote.jpg') 0px 0px no-repeat;
      font-style: italic;
      padding: 5px 15px 0 50px;
    }

    @include media ($mobile) {
      width: 100%;
      float: none;
    }
  }

  #event-footer {
    @include pad(0 57px);

    @include media ($mobile) {
      padding: 0 20px;
    }
  }

  #top-footer {
    @include media ($mobile) {
      padding: 0 20px;
    }
  }

  #footer {

    @include media ($mobile) {
      padding: 0 20px;
    }
  }

}

/*
*
* New styles. Delete old when done porting
*
*/

.front {

  .flexslider ul.slides li img {
    border-top: none;
  }
}

/*
*
* Portable elements. Move to elements when done front page redo
*
*/

.front.node-type-network #block-system-main .node>header>.right {
  margin-top: 0;
}

// Stories from the blog

#block-views-blog_list-blog_list {
  .attachment {
    @include clearfix();
    @include span-columns(6 of 12);
    width: 51.887%;
    margin-right: 0.7%;

    .views-row {
      height: 430px;

      @include media($mobile) {
        height: 0;
        margin-bottom: 10px;
        padding-top: 62.5%;
      }
    }

    @include media($tablet) {
      @include span-columns(8 of 8);

      .views-row .split-left img {
        max-width: 900%;

      }
    }
  }

  .attachment+.view-content {
    @include clearfix();
    @include span-columns(6 of 12);
    width: 47.33%;
    @include omega();

    @include media($tablet) {
      @include span-columns(8 of 8);
    }

    .views-row {
      @include span-columns(3 of 6);
      @include omega(2n);
      width: 49.182%;
      height: 210px;
      margin-right: 1.6%;
      margin-bottom: 10px;
      overflow: hidden;

      @include media($mobile) {
        @include span-columns(4 of 4);
        height: 0;
        padding-top: 62.5%;
      }
    }
  }

  .views-row {
    @include clearfix();
    position: relative;
    margin-top: 0;
    overflow: hidden;
    z-index: 2;

    .area-link {
      background: none;
    }
  }

  .split-left {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .split-left img {
    min-width: 100%;
    min-height: 100%;
    line-height: 0;

    @include media($mobile) {
      width: auto;
    }
  }

  .split-right {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .split-right h3.title {
    top: 0;
    left: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    line-height: 26px;
    z-index: 2;
  }

  .split-right h3.title a {
    color: #fff;
    display: block;
    padding: 20px;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .split-right .area-link {
    text-transform: none;
    position: absolute;
    z-index: 3;
    bottom: 15px;
    left: 10px;
    width: 150px;
    font-size: 16px;
    border-radius: 0;
  }

  // background-color: #769b60; Language
  // background-color: #525384; Intercultural
  .split-right .area-link {
    &.Language+h3.title a {
      background-color: #769b60;
    }

    &.Intercultural+h3.title a {
      background-color: #525384;
    }

    &.Workforce+h3.title a,
    &.Career+h3.title a {
      background-color: #297BB3;
    }

    &.Research+h3.title a {
      background-color: #059e99;
    }
  }

  .split-right p,
  .split-right .disqus,
  .split-right .read-more {
    display: none;
  }

  .split-right .created {
    position: absolute;
    z-index: 3;
    bottom: 5px;
    right: 20px;
    color: #fff;
    font-weight: normal;
    font-style: italic;
  }
}

// Photo gallery

#block-bvc_page_attachments-gallery {
  .views-row {
    // photo
    width: 11.88%;
    margin-right: 0.7%;
    float: left;

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n);
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
      height: 100%;
    }
  }

  .views-row:nth-of-type(8) {
    margin-right: 0;
  }

  // Image
  .views-row img {
    width: 100%;
    height: auto;
  }
}

// Workshops

.front #block-bvc_page_attachments-workshops {
  @include clearfix();

  .block-inner {
    @include clearfix();
  }

  .views-row {
    // workshop
    position: relative;
    @include span-columns(3 of 12);
    @include omega(4n);
    // height: 265px;

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n);
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
    }
  }

  .views-row img {
    // ported from workshops
    margin: 0 auto;
    // min-height: 100%;
    // min-width: 100%;
    max-width: 100% !important;
    height: auto !important;
    // width: auto !important;
    // center vessel :/
    position: relative;
  }

  .views-row a {
    display: block;
    width: 100%;
    // height: 225px;
    overflow: hidden;
  }

  .overlay {
    width: 100%;
    height: 225px;
    padding-top: 45%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    overflow: hidden;
    z-index: 4;
    display: none;

    &:hover {
      opacity: 0.8;
    }

    span.status {
      text-transform: uppercase;
      display: block;
      width: 40%;
      left: 30%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
      border: 3px solid #fff;
      border: 3px solid rgba(255, 255, 255, 0.7);
      color: #fff;
      color: rgba(255, 255, 255, 0.7);
    }

    &.completed span.status {
      // background-color: #fff;
      // background-color: rgba(255, 255, 255, 0.7);
      // border: 3px solid transparent;
      // color: #000;
      // color: rgba(0,0,0, 0.7);
    }
  }

  .area-link {
    display: none;
  }

  .area-link+a {
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    font-size: 18px;
    margin: 7px 0;
    line-height: 1em;
    letter-spacing: 0px;
  }

  .date {
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    color: #000 !important;
    font-size: 15.6px;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 7px;
    font-weight: bold;
  }

  .read-more {
    color: #595959;
  }

}

// Testimonials

.front .testimonials.wrap {
  width: 100%;
}

.front .field-name-field-testimonial-slider .field-collection-item-field-testimonial-slider {
  background: none;
}

.front .field-name-field-testimonial-slider li.slide {
  background: transparent url('../images/testimonial-icon.png') bottom left no-repeat;
}

// Products

#block-bvc_page_attachments-centre-products,
#block-bvc_page_attachments-networks {
  .views-row {
    // product
    @include span-columns(3 of 12);
    @include omega(4n);

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n);
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  li a,
  .views-field-title a {
    display: block;
    float: left;
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    font-size: 18px;
    margin: 20px 0 7px 0;
    line-height: 1em;
    letter-spacing: 0px;
  }
}

// Applied Research

#block-bvc_page_attachments-applied-research-projects {
  .views-row {
    // product
    @include span-columns(3 of 12);
    @include omega(4n);

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n);
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .views-field-title a {
    display: block;
    float: left;
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    font-size: 18px;
    margin: 20px 0 7px 0;
    line-height: 1em;
    letter-spacing: 0px;
  }

  .views-field-body {
    display: none;
  }
}


// Events

#block-bvc_page_attachments-events {
  .views-row {
    @include span-columns(3 of 12);
    @include omega(4n);
    background: #fff;

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n);
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
    }
  }

  .views-row-5 {
    display: none;
  }
}
