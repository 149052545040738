.fancy-categories {
  .area,
  .categories {
    padding-bottom: 8px;
  }

  a{
    margin-bottom: 8px;
  }

  .area-link {
    text-transform: uppercase;
    &.active {
      background-color: #4F6C6F !important;
    }
  }

  .categories.inline {
    display: none;
    @include media(max-width 610px) {
      display: block;
    }
  }
  // .block-title{
  //   margin-top: 20px;
  // }
  // .categories.main{
  //   border-top: 1px $lightGreen dotted;
  //   margin-top: -20px;
  //   padding-top: 40px;
  // }
  @include media(max-width 610px) {
    .categories.main {
      display: none;
    }
  }
  .areas > div {
    display: inline;

    @include media(max-width 610px) {
      .categories {
        display: none;
      }
      &.active  .categories {
        display: block;
      }
    }
  }
}
