.node-type-tool {
  /* Fix radio buttons on toolbox filter */
  .form-radios {
    margin-top: 3px;
  }
  .form-item-toolboxes {
    display: inline;
    label {
      margin-right: 5px;
      color: #fff;
      font-family: 'HelveticaNeueW01-57Cn';
      font-weight: normal;
    }
  }

  .breadcrumb {
    margin-top: 40px;
  }

  .left, .right {
    @include span-columns(6 of 12);
    @include media($tablet) {
      @include span-columns(8 of 8);
    }

    .meta-left, .meta-right {
      margin-top: 20px;
      @include span-columns(3 of 6);
      .field {
        margin-bottom: 15px;
      }
      .field-type-taxonomy-term-reference .field-items  {
        @include clearfix();
        & > div {
          float: left;
          margin-right: 5px;
        }
      }
    }
  }

  #main #content h1.title{
    @include media($tablet){
      margin-top: 40px !important;
    }
  }
  
  .flexslider{
    margin-bottom: 25px;
  }

  .right {
    @include omega();
  }

  .tools {
    @include span-columns(12 of 12);
    margin-top: 40px;
  }

  .exposed-form-label {
    color: #fff;
    margin-left: 15px;
    margin-top: 8px;
  }

  .field-name-field-link .field-item a {
    background-color: #186cb0;
    &:active {
      background-color: #2980b9;
    }
  }

  .field-name-field-file-upload .field-item a {
    background-color: #1f9c99;
    &:active {
      background-color: #27ae60;
    }
  }

  .field-name-field-link,
  .field-name-field-file-upload {
    display: inline-block;
    margin-right: 20px;
    margin-top: 20px;
    .field-item {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  

  .block ~ .view {
    margin-top: 20px;
  }

}
