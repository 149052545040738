
/*------------------------------------------------------------------------

Project 

-------------------------------------------------------------------------*/
.node-type-project{
  overflow-x: hidden;
  #page #main #content{
    @include span-columns(12 of 12);
    @include row();
    h1.title {
      @include span-columns(12 of 12);
      margin-top: 0;
      margin-bottom: 0 !important;
      position: static !important;
      @include media($tablet) {
        width: 100%;
        margin-bottom: 20px !important;
      }
    }
    ul{
      margin-left: 0;
      li{
        margin-top: 0;
      }
    }
    @include media($laptop) {
      @include span-columns(12 of 12);
      @include pad(0 20px);
    }
    @include media(max-width 960px) {
      margin: 0 !important;
      @include span-columns(12 of 12);
      @include pad(0 20px);
    }
    @include media($mobile){
      padding: 0;
    }
  }

  #breadcrumb {
    margin-top: 90px !important;
    display: block;
    width: 100%;
    float: none !important;
    position: static !important;
    .breadcrumb {
      display: block;
      width: 100% !important;
      float: none !important;
      clear: both;
    }

    @include media($tablet) {
      margin-left: 6px;
      width: 100%;
      top: 190px;
    }
    @include media($mobile) {
      top: 0;
      margin-top: 0 !important;
    }
  }

  .field-name-field-project-image {
    margin-bottom: 40px !important;
  }

  .group-project-left{
    @include span-columns(6 of 12);
    @include media($tablet) {
      @include span-columns(8 of 8);
    }
    @include row();
    .group-details-left{
      @include span-columns(2 of 6);
			.field {
				margin-bottom: 15px;
			}
			@include media($tablet) {
				@include span-columns(8 of 8);
			}
			@include media($mobile) {
				@include span-columns(4 of 4);
			}
		}

		.group-details-right{
			@include span-columns(2 of 6);
			h3{
				color: #000;
				text-decoration: none;
        font-family: "Helvetica W01 Roman", helvetica, Arial, sans-serif;
				font-size: 13px;
				font-weight: 600;
				margin: 0;
				a{
					color: #000 !important;
					text-decoration: none !important;
				}
			}
      .project-contact-group h3{
        letter-spacing: 0.48px !important;
      }
			.field.field-name-field-contact-name{
				margin-bottom: 0;
			}
			.field-name-field-related-products {
				margin-top: 0 !important;
				border-top: none;
				padding-top: 0 !important;
				.field-label {
					display: block;
					padding: 0;
					margin-left: 0;
					background: #fff;
					position: static;
					font-family: 'Helvetica W01 Roman', arial, sans-serif !important;
					color: #000 !important;
					font-size: 13px;
					line-height: 1em;
					letter-spacing: 0;
				}
				.field-items .field-item{
					display: block !important;
					width: 100% !important;
					float: none !important;
					clear: both;
					margin-right: 0 !important;
				}
			}

			@include media($mobile) {
				@include span-columns(4 of 4);
			}
		}

		.group-details-right + .group-details-right{
			@include omega();
		}

		.flexslider {
			@include span-columns(6 of 6);
			@include row();
			margin-top: 0;
			.flex-viewport ul.slides {
				margin-bottom: 0 !important;
			}
			.flex-direction-nav {
				display: none;
				margin-bottom: 0;
			}



		}


		.field {
			margin-bottom: 15px;
		}

		#node_project_full_group_contact_information {
			border: none;
			padding: 0;
			legend {
				font-weight: bold;
			}
			.field {
				margin-bottom: 0;
			}
		}
	}
	.group-phases-accordion {
		@include row();
		@include span-columns(8 of 8);
		margin-top: 20px;
		h3.ui-accordion-header {
			border: 1px solid #ccc !important;
			border-radius: 0 !important;
			background: none !important;
			font-size: 16px;
			font-weight: bold;
			&.ui-state-default a{
				color: $mint !important;
			}
			&.ui-state-active a {
				color: #000 !important;
			}
		}
		.ui-accordion-content-active {
			border-top: 3px solid $mint;
			border-radius: 0;
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}
	}
	.group-phases-render {
		@include span-columns(6 of 12);
		@include omega();
	}


	#top-footer {
		margin-top: 0;
	}
}

.project-contact-group h3 {
	letter-spacing: 1px !important;
}
