/*---------------------------------------------------------------------

EVENTS 

---------------------------------------------------------------------*/

.page-centre-events{
	#main{
		#sidebar-second{
			padding-top: 175px;

			@include media($mobile){
				padding-top: 0;
			}
		}
	}
}

.page-events.no-sidebars {
	#main #content {
		@include span-columns(12 of 12);
		.content {
			h3 {
				width: 100%;
			}
			.pager li{
				margin-top: 0;
			}

			.calendar-calendar .month-view .full tr.date-box td {
				height: 25px;
			}

			.single-day .field-content a {
				font-size: 13px;
			}

			.multi-day	a{
				font-size: 13px;
				color: #fff !important;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}

	#top-footer {
		margin-top: 0;
	}
}

.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview {
	overflow: visible;
}

.calendar-calendar .month-view td.single-day a {
	display: block;
}

.dark-tooltip p {
	color: #fff;
}

@include media(max-width 1150px) {
	.menu-block-5 {
		position: static;
		top: 0;
		right: 0;
	}
}

@include media($mobile) {
	#main #content-area {
		padding: 0 10px !important;
	}

	.page-events #main #sidebar-second {
		padding: 0 10px !important;
		margin-top: 0;
	}

	.calendar-calendar .month-view {
		display: none;
	}

	.view-calendar .date-nav-wrapper {
		// styles for legend and pager
	}

	#sidebar-second .view-events .view-filters form input[type="text"] {
		width: auto !important;
	}
}
// EVENT 
body.node-type-event-calendar{
	#highlighted .view-id-page_banners .page-banner{
		height: auto;
	}
	#content-header .button {
		width: auto;
		padding: 5px 15px;
		margin-right: 10px;
		font-size: 14px;
		margin-bottom: 25px;
		@include media($mobile){
			display: none !important;
		}
	}
	#highlighted ~ #page > #main {
		margin-top: 0 !important;
		h3.large{
			padding: 0;
			margin: 0 0 40px;
		}
		#content {
			.field-name-field-event-image img{
				width: 100%;
				height: auto;
			}
			#breadcrumb {
				width: inherit !important;
				float: none !important;
				clear: both;
				position: static !important;
				margin: 20px 0 15px;
				padding-bottom: 0 !important;
			}
			h1.title {
				width: inherit !important;
				position: static !important;
				margin: 0 !important;
				left: 0 !important;
			}
			.button {
				width: 150px;
			}
			.field-name-body {
				margin-bottom: 40px;
			}

			.event-passed {
				background-color: #e65050;
				padding: 8px 14px;
				border-radius: 2px;
				color: #fff;
				font-family: 'HelveticaNeueW01-57Cn';
				font-size: 14px;
			}
			.field-name-field-event-photos {
				position: relative;
				margin-top: 70px;
				h2 {
					display: inline;
					padding: 0 15px;
					margin-left: 15px;
					background: #fff;
					position: relative;
					top: -0.6em;
					font-family: 'HelveticaNeueW01-57Cn';
					color: #6da5aa;
					font-size: 1.5em;
					line-height: 1em;
					letter-spacing: 1px;
				}
				.view-full {
					position: absolute;
					right: 0;
					bottom: 0;
					line-height: 35px;
					font-family: 'HelveticaNeueW01-57Cn';
					font-size: 14px;
				}
				.field-items {
					border-top: 1px dotted $mint;
					margin-top: -23px;
					> .field-item {
						margin-top: 40px;
					}
				}
				.flex-viewport {
					ul.slides {
						margin: 0 !important;
					}
					li {
						margin-top: 0;
						margin-right: 20px;
					}
				}
				.flex-control-nav {
					text-align: left;
					bottom: -60px;
				}
				.flex-direction-nav {
					a:before {
						display: none !important;
					}
					.flex-prev {
						left: 0;
						width: 44px;
						height: 44px;
						background: transparent url('../images/gal-nav-left.png') top left no-repeat;
					}
					.flex-next {
						right: 0;
						width: 44px;
						height: 44px;
						background: transparent url('../images/gal-nav-right.png') top left no-repeat;
					}
				}
				.flexslider {
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
				}
			}
		}
	}
	#sidebar-first {
		margin-top: 60px !important;
		.block-title {
			display: inline;
			padding: 0 15px;
			margin-left: 15px;
			background: #fff;
			position: relative;
			top: -0.6em;
			font-family: 'HelveticaNeueW01-57Cn';
			color: #6da5aa;
			font-size: 1.5em;
			line-height: 1em;
			letter-spacing: 1px;
		}
		.views-field .field-content {
			font-family: 'Helvetica W01 Light Cn';
			font-size: 16px;
			line-height: 1.4em;
		}
		.views-field-title .field-content {
				font-size: 20px;
		}
		.views-row {
			margin-top: 40px;
			&:first-child {
				margin-top: 20px;
			}
		}
	}
	.messages {
		@include span-columns(6 of 12);
    padding: 2em;
    text-align: center;

    &.error {
      background: #ffdada;
      border: 1px solid #FF9B9B;
    }

    .event-passed{
    	color: #595959;
    }

    @include media($mobile){
    	width: 100%;
    	float: left;
			margin-top: 20px;
    }
  }

}


/*------------------------------------------------------------------------

Calendar 

-------------------------------------------------------------------------*/
.page-events {
	#main {
		@include media($laptop) {
			margin-top: 50px;
		}
		@include media($tablet){
			margin-top: 0;
		}
		#content {
			margin-top: 70px;
			.view-header {
				@include outer-container();
				
			}
			.subhead-wrapper {
				@include row();
				position: relative;
			}
			.calendar-subhead {
				font-size: 0.95em;
				@include span-columns(7 of 12);
				@include media(max-width 640px) {
					@include span-columns(4 of 4);
					@include omega();
				}
			}
			.mode-select { 
				@include span-columns(5 of 12);
				@include omega();
				text-align: right;
				position: absolute;
				bottom: 20px;
				right: 0;
				input {
					// @include button();
					display: inline-block !important;
					padding: 5px 10px;
					text-align: center;
					color: #fff !important;
			    background: #2f7eb1;
			    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
			    background: -moz-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
			    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2f7eb1), color-stop(100%, #1c5c86));
			    background: -webkit-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
			    background: -o-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
			    background: -ms-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
			    background: linear, to bottom, #2f7eb1 0%, #1c5c86 100%;
			    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7eb1', endColorstr='#1c5c86',GradientType=0 );
					border-radius: 2px;
					font-family: 'Helvetica W01 Cn';
					margin-left: 5px;
				}
				@include media($mobile){
					display: none;
				}
			}
			.date-nav-wrapper {
				@include row();
			}
			.date-nav {
				border-top: 1px $mint dotted;
				margin: 0;
				padding: 20px 0;
				@include clearfix();
				width: 100%;
				.date-heading, .legend {
					width: auto !important;
					display: inline;
					float: left;
				}
				.date-heading {
					text-transform: uppercase;
					margin-right: 30px;
					@include media(max-width 740px) {
						display: block;
						width: 100% !important;
						h3 {
							text-align: left;
						}
					}
				}
				.legend-items {
					list-style: none;
					margin-bottom: 10px;
					margin-left: 0;
					> li {
						display: inline-block;
						margin-top: 0;
						margin-left: 0;
						@include media(max-width 740px) {
							display: block;
							margin-top: 10px;
						}
					}
				}
				.legend {
					margin-top: 5px;
					.icon {
						width: 18px;
						height: 18px;
						display: inline-block;
						&.intercultural {
							background-color: #43447b;
						}
						&.language {
							background-color: #6a9452;
						}
						&.workforce {
							background-color: #116fac;
						}
						&.research {
							background-color: #128087;
						}
					}
					.text {
						font-family: 'Helvetica W01 Cn';
						font-size: 16px;
						margin-right: 20px;
					}
					@include media(max-width 740px) {
						width: 100% !important;
					}
				}
				ul.pager {
					float: right;
					margin: 0;
					width: auto !important;
					clear: none;
					.date-prev, .date-next {
						position: static !important;
						float: left;
						margin-left: 10px !important;
					}
				}

			}
			.calendar-calendar td {
				&.today {
					border: none;
					color: #589AC5 !important;
					&.single-day {
						border-bottom: 1px solid rgb(204,204,204);
					}
				}
        .hc-preview {
          .hc-name {
            width: 100%;
            .calendar {
              background-color: transparent;
            }
            .event-visible {
              font-family: 'Helvetica W01 Cn', arial, sans-serif !important;
              background-color: transparent;
              font-size: 14px;
              border: 1px solid #ccc;
              padding: 10px;
              &.Language,
              &.English {
                border-top: 5px solid #6a9452;
              }
              &.Intercultural {
                border-top: 5px solid #43447b;
              }
              &.Workforce,
              &.Career {
                border-top: 5px solid #116fac;
              }
              &.Research {
              	border-top: 5px solid #128087;
              }
            }
          }
          .hc-details .event-tooltip-body {
            .event {
              &>* {
                display: block;
              }        
              &>br {
                display: none;
              }
              &>span:first-child, &>a {
                margin-bottom: 10px;
              }
              padding: 20px;
              &.Intercultural {
                border-top: 5px solid #43447b;
              }
              &.Language {
                border-top: 5px solid #6a9452;
              }
              &.Workforce,
              &.Career {
                border-top: 5px solid #116fac;
              }
              &.Research {
              	border-top: 5px solid #128087;
              }
              margin-bottom: 15px;
            }
            a {
              font-family: 'Helvetica W01 Cn';
              font-size: 16px;
            }
            span {
              font-family: 'Helvetica W01 Cn';
              font-size: 14px;
            }
          }
        }
				a, span {
					font-weight: normal;
					text-decoration: none;
				}
			}

			.calendar-calendar .month-view .full td.multi-day div.monthview,
			.calendar-calendar .month-view .full td.multi-day .inner .monthview .continues,
			.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff {
				background: #557194;
			}

			.calendar-calendar .month-view .multi-day a {
				color: #fff;
			}

			.calendar-calendar tr.single-day {
				height: 115px;
			}

			@include media($tablet) {
				margin-top: 50px;
			}

			@include media($mobile){
				margin-top: 40px;
			}
		}

		.view-display-id-event_attach {
			tbody tr {
				border-left: 5px solid #dedede;
				&.Intercultural {
					border-left: 5px solid #43447b;
				}
				&.Language {
					border-left: 5px solid #6a9452;
				}
				&.Workforce {
					border-left: 5px solid #116fac;
				}
				border-bottom: 10px solid #fff;
			}
			tbody tr td {
				background-color: #dedede;
				font-weight: 300;
				padding-left: 10px;
				span {
					color: rgb(89,89,89);  
				}
				.tablesaw-cell-label {
					display: block;
				}
				p, a, h3, span {
					margin: 0;
					font-family: 'Helvetica W01 Cn';
					font-size: 16px;
					letter-spacing: 1px;
				}
			}
		}
	}
}

.dark-tooltip {

	&.medium {
		padding: 0 !important;
	}

	&.light {
		background-color: #fff !important;
		box-shadow: 0 0 13px #666;
	}

	.event {
		padding: 20px;
		border-top: 5px solid #fff;
	}

	.event {
		&.Intercultural {
			border-top: 5px solid #43447b;
		}
		&.Language {
			border-top: 5px solid #6a9452;
		}
		&.Workforce {
			border-top: 5px solid #116fac;
		}
	}

	.event-tooltip-header h3 {
		font-size: 20px;
	}

	.event-tooltip-body {
		a {
			font-family: 'Helvetica W01 Cn';
			font-size: 16px;
		}
		span {
			font-family: 'Helvetica W01 Cn';
			font-size: 14px;
		}
		.event {
			margin-bottom: 15px;
		}
	}
}


.view .date-nav-wrapper .date-prev a {
	margin-right: 10px;
}

.view .date-nav-wrapper .date-next a {
	margin-left: 10px;
}

// sidebar top border fix
.node-type-event-calendar {
  #sidebar-first {
    .block-inner {
      border-top: none;
    }
  }
}

// Events View 4 column widget

#block-bvc_page_attachments-events .views-row {
	padding: 15px;
	border: 1px solid rgb(204, 204, 204);
	border-radius: 8px;
	min-height:125px;
	h2 {
		margin-bottom: 5px;
		font-weight: 300;
		font-size: 1.5em;
	}
}