/* reset the nice menu packaged styles */

ul.nice-menu,
ul.nice-menu a,
ul.nice-menu ul,
ul.nice-menu li,
ul.nice-menu-down,
ul.nice-menu-down ul,
ul.nice-menu-down li,
ul.nice-menu li.menuparent,
ul.nice-menu li.menuparent a,
ul.nice-menu li.menuparent a:hover,
ul.nice-menu li.menuparent:hover,
ul.nice-menu li.menuparent:hover a {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: none;
}

ul.nice-menu {
  float: none;
}

ul.nice-menu-down li.menuparent {
  background: transparent url('../images/arrow-down.png') calc(100% - 5px) center no-repeat;
  background-size: 10px 8px;

  &:hover {
    background: transparent url('../images/arrow-down.png') calc(100% - 5px) center no-repeat;
    background-size: 10px 8px;
  }
}

#header-outer {
  font-family: 'HelveticaNeueW01-57Cn';
  background-color: $green;
  z-index: 99;
  position: fixed;
  width: 100%;

  @include media($mobile) {
    height: 58px;
  }

  #preheader {
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;

    .container {
      @include outer-container();
    }

    ul {
      float: right;

      @include media($tablet) {
        padding-right: 40px;
      }
    }

    li {
      display: inline;
      padding: 0 8px;
      margin: 0;
      font-size: 10px;
      border-right: 1px solid rgba(#fff, 0.5);

      &.no-border {
        border: none;
      }

      &,
      a {
        color: #fff;
        text-decoration: none;
      }

      &.last {
        // margin-left: 50px;
        border-right: none;
        border-left: 1px solid rgba(#fff, 0.5);

        img {
          position: relative;
          top: 3px;
        }
      }

    }
  }

  #header {
    background: #094040;
    height: 90px;
    line-height: 90px;

    #logo {
      display: block;
      float: left;
      margin-right: 96px;

      img {
        height: 75px;
        vertical-align: middle;
      }

      @media screen and (max-width: 605px) {}
    }

    >.content {
      @include row();
      @include outer-container;

      @media screen and (max-width: 1365px) {
        @include pad(0 20px);
      }
    }

    .flyout-button {
      display: block;
      height: 42px;
      float: left;
      border: 1px solid rgba(#fff, 0.25);
      padding: 0 24px;
      text-transform: uppercase;
      color: #fff;
      margin: 24px 32px 0 0;
      font-weight: 600;
      line-height: 42px;

      &.active {
        background: lighten(#094040, 3%);
      }

      &:nth-of-type(3) {
        margin-right: 95px;
      }
    }

    .flyout-button {
      display: block;
      height: 42px;
      float: left;
      border: 1px solid rgba(#fff, 0.25);
      padding: 0 24px;
      text-transform: uppercase;
      color: #fff;
      margin: 24px 32px 0 0;
      font-weight: 600;
      line-height: 42px;

      &.active {
        background: lighten(#094040, 3%);
      }

      &:nth-of-type(3) {
        margin-right: 95px;
      }
    }

    .button {
      height: initial;
      line-height: initial;
      margin: 0;
      line-height: 45px;
      padding: 0 22px 0 45px;
      margin-top: 22.5px;
      // @include linear-gradient(#b4d462, #9dbc4a);
      background: #b4d462;
      /* Old browsers */
      background: url('../images/lock.png') 20px center no-repeat, -moz-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
      /* FF3.6+ */
      background: url('../images/lock.png') 20px center no-repeat, -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b4d462), color-stop(100%, #9dbc4a));
      /* Chrome,Safari4+ */
      background: url('../images/lock.png') 20px center no-repeat, -webkit-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
      /* Chrome10+,Safari5.1+ */
      background: url('../images/lock.png') 20px center no-repeat, -o-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
      /* Opera 11.10+ */
      background: url('../images/lock.png') 20px center no-repeat, -ms-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
      /* IE10+ */
      background: url('../images/lock.png') 20px center no-repeat, linear-gradient(to bottom, #b4d462 0%, #9dbc4a 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b4d462', endColorstr='#9dbc4a', GradientType=0);
      /* IE6-9 */
      /* We need to remove the important in _custom.scss for .button */
      color: #033 !important;
      font-size: 14px;
      text-transform: uppercase;
    }

    #block-search_api_page-search_page,
    #block-search-form,
    #search-block-form {
      position: relative;
      display: block;
      float: left;

      h2 {
        display: none;
      }

      .container-inline,
      .block-inner {
        height: 45px;
      }

      .form-item {
        margin-top: 0;
        margin-bottom: 0;
      }

      .form-type-textfield {
        input {
          position: relative;
          height: 45px;
          font-size: 16px;
          padding: 5px 10px;
          padding-right: 50px;
          width: 250px;
          border: 1px solid #444;
          border-radius: 5px;
          background: #ccd6d6;

          &:focus {
            outline: none;
          }
        }
      }

      input.form-submit {
        display: none !important;
      }

      .fa {
        font-size: 25px;
        position: relative;
        right: 41px;
        top: 4px;
        color: #777;
        cursor: pointer;
      }

      @include media($laptop) {
        float: right;
      }

      @media screen and (max-width: 805px) {
        &.abs {
          position: absolute;
          right: 20px;
          width: 94%;
        }

        input[type="text"] {
          width: 0;
          overflow: hidden;
          padding: 0;
          border: none;
          @include transition(width 0.3s ease-out);

          &.open {
            width: 100%;
            padding: 8px 10px;
            padding-right: 50px;
            border: 1px solid #444;
          }
        }
      }

      @media screen and (max-width: 480px) {

        // display: none;
        &.abs {
          width: 90%;
        }

        input.form-submit {
          background-size: 15px;
          top: 10px !important;
        }
      }

      @media screen and (max-width: 375px) {
        display: none;
      }
    }

    @include media($laptop) {
      padding: 0 50px;
    }

    @include media($tablet) {
      padding: 0 10px;
    }

    @include media($mobile) {
      padding: 0 10px;

      .block-inner {
        height: 25px;
      }

      input.form-submit {
        top: 5px !important;
      }
    }
  }
}

#header-outer .secondary-header,
#block-nice_menus-1 {
  position: relative;

  /* Hide the menu titles. */
  h2 {
    display: none;
  }
}

#header-outer .secondary-header .menu-block-wrapper,
#block-nice_menus-1 .content {
  position: relative;

  /* Nerf Kenny's hover-opacity in the menu */
  a:hover {
    opacity: 1;
  }

  ul {
    @include outer-container();
    height: 45px;

    @media screen and (max-width: 1320px) {
      padding: 0 20px;
    }

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

    }
  }

  >ul>li {
    >a {
      border-top: 3px solid transparent;
      font-weight: 600;
    }

    &.active-trail,
    &:hover,
    &.active-hover {
      >a {
        border-top: 3px solid $mint;
      }
    }

    &.active-trail>a {
      color: $mint;
    }
  }

  li.menuparent ul {
    background: $mint;
    height: auto;
    top: 48px;
    padding: 0 15px;

    li {
      border: none;
    }

  }

  .menu-background {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    height: 0;
    transition: height 0.5s ease-in-out;
    overflow: hidden;
    background: $mint;

    &.open {
      height: 45px;
    }

    .menu li {
      a {
        color: $green;
      }

      &.active-trail,
      &:hover {
        >a {
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }

  a {
    color: #fff;
    line-height: 45px;
    text-decoration: none;
    display: inline-block;
    margin: 0 15px 0 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600 !important;
    word-spacing: -1px;

    // moz hack
    body:last-child &,
    x:-moz-any-link {
      // margin: 0 18px;
      font-weight: 400 !important;
    }

    @media screen and (max-width: 1320px) {

      // margin: 0 px;
      body:last-child &,
      x:-moz-any-link {
        margin: 0 7px;
      }
    }

  }

  li.first a {
    margin-left: 0;
  }

  li::after {
    content: '';
    width: 0;
    height: 100%;
    border-right: 1px solid #fff;
  }

  li.last::after {
    border-right: none;
  }
}

#highlighted,
#page {
  padding-top: 176px;

  @include media(max-width 1320px) {
    padding-top: 130px;
  }

  @media screen and (max-width: 570px) {
    padding-top: 58px;
  }
}

#highlighted~#page {
  padding-top: 0;
}

#hamburger-toggle {
  display: none;
  background: transparent url('../images/hamburger-tablet.png') 0 0 no-repeat !important;
  margin: 0 57px 0 0;
  float: left;
  cursor: pointer;
  width: 70px;
  height: 100%;

  @media screen and (max-width: 605px) {
    margin-right: 20px;

    &.hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 1320px) {
  #header-outer {
    #header {

      .flyout-button,
      .user-session-management {
        display: none;
      }

      #logo {
        margin-right: 0;
      }
    }
  }

  .secondary-header {
    display: none;
  }

  #hamburger-toggle {
    display: block;
  }
}

@media screen and (max-width: 570px) {
  #header-outer {
    #preheader {
      display: none;
    }

    #header {
      height: 58px;
      line-height: 58px;

      #logo {
        max-width: 250px;

        img {
          display: inline-block;
          position: static;
          height: 55px;
          width: auto;
          //padding-top: 5.8%;
          // margin-top: 10px;
        }
      }
    }
  }

  #hamburger-toggle {
    background: transparent url('../images/hamburger-mobile.png') 0 0 no-repeat !important;
    margin: 0 0 0 20px;

    @include media($mobile) {
      width: 35px;
      margin-top: 15px !important;
      margin-right: 20px !important;
      background-size: contain !important;
    }
  }
}

.nice-menu .menuparent ul {
  width: auto;
  min-width: 100%;
  white-space: nowrap;

  li {
    width: 100%
  }
}

#block-nice_menus-1 .content .menuparent li a {
  width: 100%;
  margin: 0;
  color: #003333;

  &:hover {
    color: #fff;
  }
}

#block-nice_menus-1 .content .menuparent li::after {
  display: none !important;
  border: none !important;
}

.menu-name-main-menu {
  display: none;
}

#jPanelMenu-menu {
  .nice-menu {
    background: none;

    li {
      width: 100%;
    }

    li.menuparent ul {
      width: 100%;
      display: block !important;
      position: static;
      visibility: visible !important;
    }
  }

  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}

.right-float {
  float: right;
}
