// Remove `false` values from a list

@function compact($vars...) {
  $list: ();
  @each $var in $vars {
    @if $var {
      $list: append($list, $var, comma);
    }
  }
  @return $list;
}
