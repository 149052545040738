.field-group-htabs-wrapper {
  .horizontal-tabs ul.horizontal-tabs-list li a, 
  .horizontal-tabs ul.horizontal-tabs-list li.selected a {
    padding: 10px 15px;
  }
  .horizontal-tabs {
    border: none;
    .horizontal-tabs-panes{
      padding: 15px;
      border: 1px solid #ccc;
      background-color: #fff;
      ul,ol{
        margin-bottom: 1.7em;
        li{
          margin-bottom: .85em;
        }
      }
      fieldset.horizontal-tabs-pane {
        padding: 0;
        margin: 0;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .horizontal-tabs ul.horizontal-tabs-list {
    font-family: 'HelveticaNeueW01-57Cn';
    font-size: 1.2em;
    position: relative;
    top: 6px;
    border: none;
    background-color: transparent;
    margin-bottom: 0 !important;
    display: table-row;
    li{
      max-width: 25%;
      display: table-cell;
      float: none;
      border: none;
      background-color: transparent;
      margin-top: 0;
      border-top: 3px solid transparent !important;
      &.selected {
        background-color: #fff;
        border-top: 3px solid $mint !important;
        border-right: 1px solid #ccc !important;
        border-left: 1px solid #ccc !important;
        &:hover {
          background-color: #fff;
        }
      }
      &:hover {
        background-color: transparent;
      }
      a:hover {
        background-color: transparent;
      }
    }
    #content & {
      // frikking ul fix
      margin-left: 0;
    }
  }
  .field-items ul, 
  .field-items ol {
    padding-left: 20px;
    li {
      color: #595959;
      font-family: 'HelveticaNeueW01-57Cn';
      font-size: 1.2em;
    }
  }
  .media-image {
    margin-top: 20px;
    width: 100%;
    height: auto;
  }
  @include media($tablet) {
    @include span-columns(8 of 8);
    margin-top: 20px;
  }
}
.field-group-accordion {
  @include row();
  margin-top: 20px;
  display: none;
  font-family: 'Helvetica W01 Cn';
  font-weight: 300;
  color: #595959 !important;
  
  h3.ui-accordion-header {
    border: 1px solid #ccc !important;
    border-radius: 0 !important;
    background: none !important;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    &.ui-state-default a{
      color: $mint !important;
    }
    &.ui-state-active a {
      color: #000 !important;
    }
  }
  .ui-accordion-content-active {
    border-top: 3px solid $mint;
    border-radius: 0;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    @include media($tablet){
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      -ms-box-sizing: content-box;
      box-sizing: content-box;
    }
  }
  @include media($mobile){
    min-height: auto !important;
  }
}

.paragraphs-item-page-attachment-tab{
  .fluid-width-video-wrapper{
    width: 100% !important;
  }
  iframe{
    height: 100% !important;
    @include media($tablet){
      width: 100% !important;
      height: auto !important;
      min-height: 375px;
    }
    @include media($mobile){
      min-height: 0;
    }
  }
}

@include media($tablet) {
  .field-group-htabs-wrapper {
    display: none;
  }
  .field-group-accordion {
    display: block; 
  }
  .field-group-format-wrapper.ui-accordion-content{
    // height: auto !important;
    img{
      width: 100%;
      height: auto;
    }
  }
}