.page-members{
  #main{
    padding-top: 50px;
  }
  // Disable chosen and default filter
  .chosen-container,
  #edit-field-user-areas-of-interest-value-wrapper{
    display: none;
  }
  .views-exposed-form{
    margin-top: 1.57%;

    input[type="text"]{
      background: #ced9d9 url('../images/search-icon.png') 95% center no-repeat;
      background-size: 16px 16px;
    }

    @include media($tablet){
      padding-top: 20px;
    }
  }
  .views-exposed-widgets label{
    display: none;
  }
}

.area-link{
  background: #7daeb3;
  &.Technology,
  &.Financial,
  &.ESL{
    background: #7daeb3;
  }

  @include media($tablet){
    margin-bottom: 5px;
  }
}

.view-centre-memebers{

  .views-row{
    position: relative;
    width: 11.01%;
    // max-height: 140px;
    margin: 0 1.57% 75px 0;
    float: left;
    position: relative;

    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }
    

    &:nth-of-type(8n){
      margin-right: 0;
    }

    &:hover{
      .views-field-name{
        background: rgba(0,0,0,0.75);
        @include transition(all 0.3s ease-in-out);
      }
    }

    @media screen and (max-width: 1205px) {
      @include span-columns(3 of 12);
      @include omega(4n);
    }

    @include media($tablet){
      @include span-columns(2 of 8);
      @include omega(4n);
      margin-bottom: 6.98%;
    }

    @include media($mobile){
      @include span-columns(2 of 4);
      @include omega(2n);
    }
  }

  .views-field-name{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 0.5em !important;
    background: rgba(0,0,0,0.25);
    text-align: center;


    a{
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      padding: 0 25px;
      font-size: 1.2em;
      color: #fff;
      text-decoration: none;
      word-wrap: break-word;
      @include transform(translateY(-50%));
    }
  }

  #main & .views-field-field-name-first a {
    text-decoration: none;

    &:hover {
      opacity: 0.8 !important;
    }
  }

  .views-field-field-user-address-administrative-area,
  .views-field-field-user-address-country {
    color: #595959;
    float: left;
    font-family: 'Helvetica W01 Cn', sans-serif;
    font-size: 14px;
    width: 100%;
  }

  .views-field-field-user-address-country  .location {
    border-bottom: 1px dotted #6aa7aa;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
  }



  .views-field-field-name-first {
    border-top: 1px dotted #6aa7aa;
    color: #595959;
    font-family: 'Helvetica W01 Cn', sans-serif;
    font-size: 24px;
    line-height: 1.2em;
    margin-top: 1em;
    padding-top: 0.5em;
    text-align: center;
    text-transform: uppercase;
  }

  .views-field-picture{
    width: 85px;
    height: 85px;
    margin: 0 auto;

    img{
      width: 100%;
      height: 100%;
    }
  }
}
