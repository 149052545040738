.fancyForm{
  form.main{

  }
  .views-exposed-widgets .views-exposed-widget{
    float: left;
  }


  .views-exposed-widgets .form-item{
    margin-top: 0;
    margin-bottom: 0;
  }

  .form-type-radio{
    margin-left: 10px;
    @media screen and (max-width: 995px) {
      &.form-item.form-type-radio{
        width: auto !important;
      }
    }
  }

  input[type="radio"]{
    margin-right: 5px !important;
  }

  // Hide form
  form.ctools-auto-submit-full-form,
  span.exposed-form-label,
  #views-exposed-form-workshops-workshops-filter,
  #views-exposed-form-blog-list-blog-filter{
    display: none;
  }
}