
/***********************************************************

          Global Views Display Toggle Styles

**********************************************************/

.block-views {
  .exposed-form-label,
  .mode-box label {
    padding: 7px;
    margin-left: 15px !important;
    color: #fff !important;
    font-size: 14px !important;
    @include media($laptop) {
      margin-left: 40px !important;
    }
    @include media(max-width 995px) {
      margin-left: 30px !important;
    }
  }
  .mode-box label{
    float: left;
    padding: 0;
  }
}
#highlighted {
  .fancy-categories {
    @include media($laptop) {
      margin-left: 20px;
      margin-right: 20px;
    }
    @include media($tablet){
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.form-item-afterDate,
.form-item-beforeDate{
  float: left;
}
.form-item-beforeDate{
  margin-left: 15px;
  @media screen and (max-width: 995px) {
    margin-left: 0;
  }
}
.form-item-afterDate{
  @media screen and (max-width: 995px) {
    margin-bottom: 10px !important;
  }
}
.views-exposed-form,
.fancyForm {
  @include outer-container();
  background: rgb(39, 87, 87);
  background: -moz-linear-gradient(270deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
  background: -webkit-linear-gradient(270deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
  background: -o-linear-gradient(270deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
  background: -ms-linear-gradient(270deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
  background: linear-gradient(0deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
  border-radius: 5px;
  .description {
    display: none;
  }
  @media screen and (max-width: 995px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .views-exposed-widget {
    padding: 15px 0;
    margin-left: 15px;
    .form-item, input {
      margin-right: 0 !important;
    }
    input[type="text"] {
      border-radius: 3px;
      color: #595959;
      width: 150px !important;
      font-size: 14px !important;
      border: none;
      background-color: #ced9d9;
      font-family: 'HelveticaNeueW01-57Cn';
      &[name="keys"] {
        background: #ced9d9 url(../images/search.jpg) 130px center no-repeat;
        padding-right: 30px;
      }
    }
    input[type="submit"]{
      text-transform: uppercase;
      font-family: 'HelveticaNeueW01-57Cn';
      height: 25px;
      background-color: #abc761;
      color: #000;
      margin-bottom: 0;
      border-radius: 3px;
    }
  }
  .mode-box{
    float: right !important;
    margin-right: 15px;
    line-height: 25px;
    .exposed-form-label {
      padding: 0;
      line-height: inherit;
    }
    .form-type-radio{
      width: 20px;
      float: left;
      cursor: pointer;
      &.form-item-viewmode-2{
        width: 35px;
      }
      a.card-mode,
      a.list-mode{
        margin-left: 0;
      }
    }
    a{
      margin-left: 10px;
      &.card-mode {
        background: transparent url('../images/card_inactive.png') 0 center no-repeat;
        display: block;
        float: left;
        width: 20px;
        text-indent: -9999px;
        &.active, &:hover{
          background-image: url('../images/card_active.png');
        }
      }
      &.list-mode {
        background: transparent url('../images/list_inactive.png') 0 center no-repeat;
        display: block;
        float: left;
        width: 35px;
        text-indent: -9999px;
        &.active, &:hover{
          background-image: url('../images/list_active.png');
        }
      }
    }
  }
  #highlighted & {
    @include media($laptop) {
      margin: 0 20px;
    }
    @media screen and (max-width: 995px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media(max-width 995px) {
    .views-exposed-widget {
      float: none;
      clear: both;
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      .form-item, .chosen-container {
        width: 100% !important;
      }
      input[type="text"] {
        width: 100% !important;
        &[name="keys"] {
          background-position: 98.5% 50%;
        }
      }
      .container-inline-date .date-padding{
        float: none;
        clear: both;
      }
    }
    .views-submit-button {
      margin-left: 15px !important;
    }
    .views-reset-button {
      margin-left: 0;
    }
    .views-submit-button,
    .views-reset-button,
    .mode-box {
      float: left;
      clear: none;
      display: inline;
      margin-right: 0;
    }
    .mode-box {
      float: right;
      margin-right: 15px;
    }
    &.drawer-closed {
      .views-exposed-widget {
        // display: none;
      }
      .mode-box {
        display: block;
        padding-top: 15px !important;
      }
    }
  }
}

.view  {
  .views-display-toggle-list {
    // TODO: scope #content ul better
    ul {
      #content & {
        margin-left: 0;
      }
    }
  }
  .views-row {
    @include row();
    margin: 30px 0;

    .split-left {
      @include span-columns(3 of 8);
      @include media($tablet) {
        @include span-columns(4 of 4);
      }
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
    .split-right {
      @include span-columns(5 of 8);
      @include omega();
      @include media($tablet) {
        margin-top: 10px;
        @include span-columns(4 of 4);
      }
      h3.title {
        width: auto;
        font-size: 24px;
        margin: 10px 0 0 0;
        a {
          text-decoration: none;
        }
        .disqus {
          margin-left: 5px;
          a {
            color: #666 !important;
            font-size: 14px;
            line-height: 16px;
            margin-left: 5px;
          }
        }
      }
      .created {
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
      }
      .read-more {
        font-size: 16px;
        font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
        font-style: italic;
        color: #333;
      }
    }
  }

  .item-list .pager.infinite-scroll {
    margin: 0 !important;
    margin-top: 20px !important;
    overflow: hidden;
    .pager-previous {
      display: none;
    }
    .pager-next {
      padding: 0;
      display: block;
      margin: 0;
      width: 100%;
      a {
        display: block;
        width: 100%;
        text-indent: 9999px;
        background: transparent url(../images/loadmore.png) center center no-repeat;
        height: 85px;
      }
    }
  }

  .view-filters + .view-content {
    margin-top: 20px;
  }

  .view-content .cbp {
    img {
      width: 100%;
      height: auto;
    }
    // cube portfolio specific code
    .cbp-wrapper {
      /* TODO: Fix #content ul margins */
      margin: 0 !important;
    }
    .views-row {
      /* TODO: Fix #content li margins */
      margin-top: 0 !important;
      margin: 0;
      .split-left {
        width: 100%;
        position: absolute;
        z-index: 1;
        img {
          width: 110%;
          max-width: 110%
        }
      }
      .split-right {
        margin-top: 0 !important;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        h3.title {
          top: 0;
          left: 0;
          margin-top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          line-height: 26px;
          z-index: 2;
          a{
            color: #fff;
            display: block;
            padding: 20px;
            width: 100%;
            height: 100%;
          }
          .disqus {
            display: none;
          }
        }
        p {
          display: none;
        }
        .read-more {
          display: none;
        }
        .created {
          position: absolute;
          z-index: 3;
          bottom: 5px;
          right: 20px;
          color: #fff;
          font-weight: normal;
          font-style: italic;
        }
        a.area-link {
          // text-transform: capitalize;
          position: absolute;
          z-index: 3;
          bottom: 15px;
          left: 10px;
          width: 150px;
          font-size: 16px;
          border-radius: 0;
          &.Intercultural {
            background-color: transparent;
          }
          &.Workforce {
            background-color: transparent;
          }
          &.Language {
            background-color: transparent;
          }
          &.Research {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.view-tools {
  .item-list .pager {
    margin: 0 !important;
    margin-top: 20px !important;
    overflow: hidden;
    .pager-previous {
      display: none;
    }
    .pager-next {
      padding: 0;
      display: block;
      margin: 0;
      width: 100%;
      a {
        display: block;
        width: 100%;
        text-indent: 9999px;
        background: transparent url(../images/loadmore.png) center center no-repeat;
        height: 85px;
      }
    }
  }
}

.fancy-categories {
  @include media(max-width 610px) {
    a:link.area-link {
      display: block;
    }
    a.area-link + a:link.area-link {
      margin-left: 0;
    }
    .categories  {
      a:link.area-link {
        margin-left: 20px;
        margin-right: 20px;
      }
      a.area-link + a:link.area-link {
        margin-left: 20px;
      }
    }
  }
}

.filter-toggle {
  cursor: pointer;
  padding-left: 30px !important;
  background-image: url('../images/blog-filter-arrow-closed.png');
  background-repeat: no-repeat;
  background-position: 0 50%;
  &.open {
    background-image: url('../images/blog-filter-arrow-open.png');
    background-repeat: no-repeat;
    background-position: 0 50%;
  }
}
