//************************************************************************//
// Background-image property for adding multiple background images with
// gradients, or for stringing multiple gradients together.
//************************************************************************//

@mixin background-image($images...) {
  background-image: add-prefix($images, webkit);
  background-image: add-prefix($images, moz);
  background-image: add-prefix($images, ms);
  background-image: add-prefix($images, o);
  background-image: add-prefix($images);
}


@function add-prefix($images, $vendor: false) {
  $images-prefixed: ();

  @for $i from 1 through length($images) {
    $type: type-of(nth($images, $i)); // Get type of variable - List or String

    // If variable is a list - Gradient
    @if $type == list {
      $gradient-type: nth(nth($images, $i), 1); // Get type of gradient (linear || radial)
      $gradient-args: nth(nth($images, $i), 2); // Get actual gradient (red, blue)

      $gradient: render-gradients($gradient-args, $gradient-type, $vendor);
      $images-prefixed: append($images-prefixed, $gradient, comma);
    }

    // If variable is a string - Image
    @else if $type == string {
      $images-prefixed: join($images-prefixed, nth($images, $i), comma);
    }
  }
  @return $images-prefixed;
}


//Examples:
  //@include background-image(linear-gradient(top, orange, red));
  //@include background-image(radial-gradient(50% 50%, cover circle, orange, red));
  //@include background-image(url("/images/a.png"), linear-gradient(orange, red));
  //@include background-image(url("image.png"), linear-gradient(orange, red), url("image.png"));
  //@include background-image(linear-gradient(hsla(0, 100%, 100%, 0.25) 0%, hsla(0, 100%, 100%, 0.08) 50%, transparent 50%), linear-gradient(orange, red));
