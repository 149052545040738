/*---------------------------------------------------------------------

                            Blog

---------------------------------------------------------------------*/
// breadcrumb reset for blog filter views pages
.section-blog {
  #main #content #breadcrumb {
    padding-top: 0;
    margin-top: 0;
    position: static;
  }
}

.page-blog {
  #highlighted {
    margin-bottom: 0 !important;

    .view-id-page_banners {
      height: auto;
    }

    #content-header {
      @include outer-container();

      .breadcrumb {
        margin: 40px 0 10px 0;
      }

      h1.title {
        font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
        color: $titleGreen;
        font-size: 3em;
        line-height: 1em;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 0;

      }
    }

    // blog filters need outer-container styles
    // because they are in highlighted
    #block-views-blog-featured_blog,
    #block-views-blog_list-featured_blog,
    #block-views--exp-blog_list-blog_filter,
    #block-views--exp-blog_list-blog_areas,
    #block-views--exp-blog_list-blog_archive,
    #block-views--exp-blog_list-blog_tags,
    #block-views--exp-blog_list-blog_category {
      @include outer-container();

      .block-inner {
        @include span-columns(12 of 12);
        padding: 0;
      }

      @include media($tablet) {
        padding: 0 60px;
      }

      @include media($mobile) {
        padding: 0 20px;
      }
    }

    #block-views--exp-blog_list-blog_areas,
    #block-views--exp-blog_list-blog_archive,
    #block-views--exp-blog_list-blog_tags,
    #block-views--exp-blog_list-blog_category {
      margin-top: 40px;
    }
  }


  .page-banner {
    height: auto !important;
  }

  #main {
    margin-top: 0 !important;

    #content {
      #block-search-form {
        margin: 30px 0;
      }

      .views-field.views-field-rendered-entity,
      .views-field.views-field-created {
        margin: 0 10px 10px 0;

        span {
          font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
          color: $green;
        }
      }

      .views-label.views-label-created {
        margin-left: -5px;
      }

      .views-field.views-field-rendered-entity {
        float: left;
      }

      .views-field-field-category {
        padding-top: 10px;

        .views-label {
          color: $lightGreen;
        }

        .field-content {
          display: inline;
        }
      }

      .views-field-field-department {
        .views-label {
          color: $lightGreen;
        }

        .field-content {
          display: inline;
        }
      }
    }
  }



  #block-views-blog-block_1 {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .view-blog {
    font-family: 'Helvetica W01 Roman', helvetica, arial, sans-serif;

    .views-field-field-blog-image {
      position: relative;

      .views-label {
        display: block;
        position: absolute;
        top: 15px;
        left: -5px;
        background: $turquoise;
        color: #FFF;
        font-weight: 700;
        padding: 5px 5px 5px 20px;
      }
    }

    .views-field-title {
      width: 75%;

      h1 {
        display: none;
      }

      h2 {
        margin: 10px 0 5px;
        font-size: 1.8em;
      }

      color: $green;
      font-weight: 700;
    }

    .views-field-title-1 {
      a {
        display: block;
        width: 130px;
        color: white;
        background: #2f7eb1;
        /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2f7eb1), color-stop(100%, #1c5c86));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
        /* IE10+ */
        background: linear-gradient(to bottom, #2f7eb1 0%, #1c5c86 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1', endColorstr='#1c5c86', GradientType=0);
        /* IE6-8 */
        padding: 5px 10px;
        text-align: center;
      }
    }
  }

  .region-sidebar-second {

    .block-views {
      .block-title {
        width: 100%;
        background: url('../images/post-icon.jpg') right 0px no-repeat;
        border-bottom: 1px $lightGreen dotted;
        font-size: 1.1em;
        font-weight: 700;
        color: $lightGreen;
        margin-bottom: 10px;
        padding-bottom: 5px;
      }

      .views-field-field-blog-image {
        padding-bottom: 15px;
      }

      .views-field-name {
        float: left;
      }

      .views-label-created {
        margin-left: 5px;
      }

      .views-field-field-category {
        .views-label {
          color: $lightGreen;
        }

        .field-content {
          display: inline;
        }
      }

      .views-field-field-department {
        .views-label {
          color: $lightGreen;
        }

        .field-content {
          display: inline;
        }
      }
    }

  }


  #block-views-blog-block_5 {
    // Recent Blog
    margin-top: 60px;

    .views-row {
      @include row();
      padding: 15px 0;
      border-top: 1px $lightGreen dotted;
    }

    .views-field-field-blog-image {
      float: left;
      margin-right: 10px;
    }

    a {
      // display: block;
      color: $green;
      // float: left;
    }

    .views-field-title {
      width: 100%;

      h1 {
        font-family: 'Helvetica W01 Bold';
        display: block;
        font-size: 1em;
        margin: 0;
      }
    }

    .views-field-created,
    .views-field-name {
      color: $lightGreen;
    }

    .view-header {
      @include row();
      margin-bottom: 3px;

      a {
        margin-left: 10px;
        border-left: dotted 1px;
        padding-left: 10px;
      }
    }

    .recent,
    .archive a {
      display: block;
      float: left;
      font-family: 'Helvetica W01 Bold';
      display: block;
      font-size: 83%;
      margin: 0 5px 0 0;
      padding-right: 5px;
      border-right: 1px $lightGreen dotted;
    }

    .archive a {
      border-right: none;
    }

  }

  .statement {
    font-size: 5.6em;
    border-bottom: none;
    margin-top: 20px;

    @include media($mobile) {
      font-size: 2.5em;
    }
  }

  #top-footer {

    @include media($mobile) {
      margin-top: 0;
    }
  }

}

.page-blog-tags {
  #block-views--exp-blog_list-blog_tags {
    margin-bottom: 20px;
  }
}

/*******************************************************

November 2014 blog

 *******************************************************/
// global blog section
.page-blog {
  #main {
    background: transparent;
  }

  &.node-type-page {
    h1.title {
      display: none !important;
    }

    .views-exposed-form {
      margin-top: 0 !important;
    }
  }

  #highlighted {
    #block-views-blog_list-featured_blog {
      margin-top: 40px;

      .more-link {
        display: none;
      }
    }

    /*
    .views-exposed-form {
      @include outer-container();
      background: rgb(39, 87, 87);
      background: -moz-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
      background: -webkit-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
      background: -o-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
      background: -ms-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
      background: linear-gradient(180deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
      border-radius: 5px;
      margin-top: 30px;
      .description {
				display: none;
			}
      .views-exposed-widget {
        padding: 15px 0;
        margin-left: 15px;
        .form-item, input {
          margin-right: 0 !important;
        }
        input[type="text"] {
          border-radius: 3px;
          color: #595959;
          width: 150px;
          border: none;
          background-color: #ced9d9;
          font-family: 'HelveticaNeueW01-57Cn';
          &[name="keys"] {
            background: #ced9d9 url(../images/search.jpg) 130px center no-repeat;
            padding-right: 30px;
          }
        }
				input[type="submit"]{
          text-transform: uppercase;
          font-family: 'HelveticaNeueW01-57Cn';
          height: 25px;
          background-color: #abc761;
          color: #000;
          margin-bottom: 0;
          border-radius: 3px;
				}
			}
		}
    */
  }

  h1.title {
    left: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    position: static !important;
    width: 100% !important;
  }

  #sidebar-first {
    .block-mailchimp-lists {
      .block-inner {

        .form-item,
        input {
          float: none !important;
          display: block;
        }

        .form-item {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }

        input[type="text"] {
          width: 100%;
          border-radius: 0;
          padding: 10px;
          font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
          font-size: 16px;
          height: 35px;
          margin-bottom: 15px;
        }

        input[type="submit"] {
          font-size: 14px;
          margin-left: 0 !important;
          margin-bottom: 30px;
          background-color: #FFF;
          height: 30px;
          background: rgb(39, 87, 87);
          background: -moz-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: -webkit-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: -o-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: -ms-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: linear-gradient(180deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
        }
      }
    }

    #block-views-blog-wfd_4_short,
    #block-views-blog-ld_4_short,
    #block-views-blog-icd_4_short {
      .item-list {
        ul {
          list-style: none;
          margin-left: 10px;

          li {
            background: transparent url(../images/blogbullet.jpg) 0 7px no-repeat;
            margin: 10px 0;
            padding-left: 20px;
          }
        }

        a {
          font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
          text-decoration: none;
          color: #666;
          font-size: 14px;
        }

        margin-bottom: 30px;
      }
    }
  }
}



// Responsive stuff for
.page-blog,
.node-type-blog {
  .rss-feed {
    float: right;
    display: block;
    line-height: 40px;
    height: 40px;
    width: 95px;
    padding-left: 10px;
    background: #fff url(../images/rss.png) right center no-repeat;
    margin-top: -60px;
    font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
    text-decoration: none;
    color: #747474;
  }

  .submitted {
    @include clearfix();
    line-height: 55px;
    border-bottom: 1px dotted $mint;
    border-top: 1px dotted $mint;

    .rss-feed {
      margin-top: 1px;
      padding-left: 10px;
      line-height: 55px;
      height: 52px;
    }

    .social {
      color: #747474;
      display: inline;
      float: right;

      .sharethis-buttons {
        display: inline;
        line-height: 55px;
      }

      .sharethis-wrapper {
        display: inline;

        &::after {
          content: '|';
        }
      }

      @include media($mobile) {
        float: none;
      }
    }

    @include media($tablet) {
      margin-top: 20px;

      &>span:first-of-type {
        display: block;
        line-height: 1.6em;
      }
    }
  }

  .flexslider {
    @include media($mobile) {
      margin-bottom: 1.6em;
    }
  }

  .field-name-body {
    img.media-image {
      padding: 1em 1em 1em 0;
    }
  }
}

.page-blog .submitted {
  display: none;
}

#block-views-blog_entry_views-related {
  margin-bottom: 40px;
}

// blog jump link block
#block-centers_admin-blog_jump_link_block {
  .chosen-container.chosen-container-single {
    width: 100% !important;
    font-size: 16px;
    line-height: 30px;

    .chosen-single {
      border-radius: 0 !important;
      height: 30px;

      div b {
        background-position: 0 5px !important;
      }
    }

    .chosen-drop {
      border-radius: 0 !important;
      border: 1px solid #bcbcbc !important;
      border-top: none !important;

      li {
        line-height: 30px;

        &.highlighted {
          color: #000;
          background-color: #ced9d9;
        }
      }
    }
  }

  .content>div {
    padding: 15px 0 30px 0;
  }

  h3 {
    width: 100%;
    padding-top: 15px;
    border-top: 1px dotted $mint;
  }

}

#sidebar-first #block-views-blog_entry_views-related {
  .view-header h3 {
    width: 100%;
    padding-top: 15px;
    border-top: 1px dotted $mint;
  }
}

// BLOG

.node-type-blog {

  // add spacing beside node page main column
  #main #content {
    padding-right: 80px;

    @include media($laptop) {
      padding-right: 0;
    }

    @media screen and (max-width: 910px) {
      width: 100%;
    }
  }

  #sidebar-first {
    @media screen and (max-width: 960px) {
      width: auto;
    }

    @media screen and (max-width: 910px) {
      width: 100%;
    }
  }

  #highlighted {
    background: none;

    img {
      display: block;
    }

    .view-id-page_banners {
      height: auto;
    }

    .page-banner {
      height: auto;
    }
  }

  #main {
    margin-top: 40px;
    background: none;

    #content {
      h1.title {
        // left: 62.6%%;
        position: static;
        margin-top: 0;
        padding-top: 0;
        width: inherit;

      }

      .flexslider {
        padding: 0 !important;
        box-shadow: none;

        .slides {
          margin-bottom: 0;
          margin-left: 0;

          li {
            margin-top: 0;
          }
        }
      }

      .flex-direction-nav .flex-prev {
        left: 0;
        width: 44px;
        height: 44px;
        background: transparent url('../images/gal-nav-left.png') top left no-repeat;

        &::before {
          content: '';
        }
      }

      .flex-direction-nav .flex-next {
        right: 0;
        width: 44px;
        height: 44px;
        background: transparent url('../images/gal-nav-right.png') top left no-repeat;

        &::before {
          content: '';
        }
      }

      #breadcrumb {
        // padding-top: 90px;
        position: static;
        margin-top: 0;
        padding-top: 0;
      }

      .submitted {
        margin-bottom: 10px;

        &>span {
          font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
          color: $green;
        }
      }

      // .links{
      //   display: none;
      // }

      .comments {
        .links.inline {
          margin-left: 0;
        }

        .captcha {
          @include span-columns(6 of 12);
        }

        .form-actions {
          width: 100%;
          clear: both;
          float: left;
          margin-top: 3em;
        }

        input[type="submit"] {
          margin-right: 10px;
          background: #2f7eb1;
          /* Old browsers */
          /* IE9 SVG, needs conditional override of 'filter' to 'none' */
          background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
          background: -moz-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
          /* FF3.6+ */
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2f7eb1), color-stop(100%, #1c5c86));
          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
          /* Opera 11.10+ */
          background: -ms-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
          /* IE10+ */
          background: linear-gradient(to bottom, #2f7eb1 0%, #1c5c86 100%);
          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1', endColorstr='#1c5c86', GradientType=0);
          /* IE6-8 */
          color: #fff;
          padding: 5px 10px;
          font-family: 'Helvetica W01 Bd Cn';
          font-size: 1em;
        }
      }
    }

    .field-name-field-category {
      @include row();

      .field-label {
        float: left;
      }

      a {
        float: left;
        margin-right: 10px;
      }
    }

    .field-name-field-department {
      clear: both;

      .field-label {
        float: left;
      }
    }

    .field-group-htabs-wrapper {
      margin-top: 30px;

      ul {
        margin-left: 0;
        position: relative;
        top: 4px;

        li {
          margin-top: 0;
          border-top: 3px solid #fff !important;
          border-bottom: 1px solid transparent !important;

          &.selected {
            position: relative;
            top: 3px;
            border-top: 3px solid $mint !important;
            border-right: 1px solid #ccc !important;
            border-bottom: none !important;
            border-left: 1px solid #ccc !important;
          }

          border-right: none;

        }
      }

      .horizontal-tabs {
        margin-top: -6px;
        border: none;

        .horizontal-tabs-panes {
          padding-top: 25px;
          border: 1px solid #ccc;
        }
      }

      .horizontal-tabs ul.horizontal-tabs-list {
        border: none;
        background-color: #fff;
        margin-bottom: 0 !important;

        li {
          border: none;
          background-color: #fff;

          a:hover {
            background: none !important;
          }
        }
      }

      .field-items ul {
        padding-left: 20px;
      }

      @include media($tablet) {
        @include span-columns(8 of 8);
        margin-top: 20px;
      }

      .view .views-row {
        @include row();
        padding: 15px 0;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-bottom: 1px dotted $mint;

        &.views-row-last {
          border-bottom: none;
        }

        .blog-inner-views-left {
          @include span-columns(2 of 8);

          @include media($mobile) {
            @include span-columns(4 of 4);
          }
        }

        .blog-inner-views-right {
          @include span-columns(6 of 8);
          @include omega();

          .posted-info {
            display: block;
            margin-bottom: 10px;
          }

          @include media($mobile) {
            @include span-columns(4 of 4);
          }
        }
      }
    }

    .flippy {
      width: 100%;
      margin-left: 0;
      margin: 30px 0;
      padding: 10px 0;
      border-top: 1px dotted $mint;
      border-bottom: 1px dotted $mint;
      @include clearfix();

      li {
        display: block;
        float: left;
        padding: 0;
        width: 50%;
        margin: 10px 0;

        span {
          display: block;
          font-weight: bold;
        }

        &.prev {
          text-align: left;
          padding: 0 80px;
          background: transparent url(../images/flippy-prev.jpg) left top no-repeat;

          @include media($mobile) {
            padding: 0 0 0 25px;
          }
        }

        &.next {
          text-align: right;
          padding: 0 80px;
          background: transparent url(../images/flippy-next.jpg) right top no-repeat;

          @include media($mobile) {
            padding: 0 25px 0 0;
          }
        }

        a {
          white-space: pre-line;
        }
      }
    }

    #disqus_thread {
      margin-top: 30px;
    }
  }

  #sidebar-first {
    .block-mailchimp-lists {
      .block-inner {

        .form-item,
        input {
          float: none !important;
          display: block;
        }

        .form-item {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }

        input[type="text"] {
          width: 100%;
          border-radius: 0;
          padding: 10px;
          font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
          font-size: 16px;
          height: 35px;
          margin-bottom: 15px;
        }

        input[type="submit"] {
          font-size: 14px;
          margin-left: 0 !important;
          margin-bottom: 30px;
          background-color: #FFF;
          height: 30px;
          background: rgb(39, 87, 87);
          background: -moz-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: -webkit-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: -o-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: -ms-linear-gradient(90deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
          background: linear-gradient(180deg, rgb(39, 87, 87) 0%, rgb(8, 57, 57) 100%);
        }
      }
    }

    #block-block-23 {
      img {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
      }

      margin-bottom: 30px;
      border-bottom: 1px dotted $mint;
    }

    #block-centers_admin-blog_jump_link_block {

      .ui-state-active,
      .ui-state-default,
      .ui-widget-content {
        border: none;
        background: none;
      }

      .ui-accordion-header {
        font-size: 16px;
      }

      .ui-accordion-content {
        ul {
          list-style: none;

          li {
            background: transparent url(../images/blogbullet.jpg) 0 7px no-repeat;
            margin: 10px 0;
            padding-left: 20px;
          }
        }

        a {
          font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
          text-decoration: none;
          color: #666;
          font-size: 14px;
        }
      }
    }
  }

  .comment-form {
    @include media($tablet) {
      input {
        width: 100% !important;
        max-width: 100% !important;
      }

      .captcha.form-wrapper {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin: 20px 0 0 0;
        border: none;
      }

      .captcha.form-wrapper,
      #recaptcha_table,
      #recaptcha_table img {
        width: 100% !important;
      }

      #recaptcha_table td {
        padding: 0;
        max-width: 90%;
      }

      #recaptcha_image {
        width: 100% !important;
      }

      #edit-submit {
        margin: 10px 0;
      }
    }
  }
}

.big-box {
  margin-bottom: 30px;
  width: 100%;
  padding-top: 83.3%;
  background: transparent url('../images/300x250.gif') top left no-repeat;
  background-size: contain;
}

.node-type-blog {
  #main {
    #content {
      .field-name-body {
        ol {
          margin-left: 25px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
