.node-page-attachment-three-column,
.node-page-attachment-two-column,
.node-page-attachment-four-column,
.node-page-attachment-one-column,
.node-page-attachment-basic,
  {
  @include outer-container();

  .field-name-field-two-column>.field-items>.field-item {
    @include span-columns(6 of 12);
    @include omega(2n);

    @include media($tablet) {
      @include span-columns(8 of 8);
    }
  }

  .field-name-field-three-column>.field-items>.field-item {
    @include span-columns(4 of 12);
    @include omega(3n);

    @include media($tablet) {
      @include span-columns(8 of 8);
    }

    img {
      width: 100%;
      margin-bottom: 30px;
    }

    .field-name-field-column-content {
      float: left;
      width: 100%;
    }

    .field-name-field-column-title {
      margin-bottom: 10px;
      float: left;
      width: 100%;
      padding-left: 0;
      margin-left: 0;
    }
  }

  .field-name-field-four-column>.field-items>.field-item {
    @include span-columns(3 of 12);
    @include omega(4n);

    @include media($tablet) {
      @include span-columns(4 of 8);
      @include omega(2n)
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
    }

    img {
      width: 100%;
      margin-bottom: 18px;
    }
  }

  blockquote {
    @include span-columns(5 of 8);
    float: right;
  }

  .field-name-field-three-column .field-name-field-column-title,
  .field-name-field-two-column .field-name-field-column-title,
  .field-name-field-four-column .field-name-field-column-title {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #595959;
    float: left;
    display: block;
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 5px !important;
    font-family: inherit;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 0;
      font-size: 1.2em;
      line-height: 1.5em;
    }
  }

  p+ul {
    margin-top: -0.95em;
  }

  .field-type-image img {
    width: 100%;
    height: auto;
    margin-bottom: 20px !important;
  }
}



.block-bvc-page-attachments {
  @include media($laptop) {
    padding: 0 60px;
  }

  @include media($tablet) {
    padding: 0 40px;
  }

  @include media($mobile) {
    padding: 0 10px;
  }
}

.field-name-field-page-attachments {
  .field-name-field-page-attachment {
    @include media($laptop) {
      padding: 0 60px;
    }

    @include media($tablet) {
      padding: 0 40px;
    }
  }

  @include media($mobile) {
    padding: 0;

    .field-name-field-page-attachment {
      padding: 0 10px;
    }
  }
}

img.media-element.file-default {
  width: 100%;
  height: auto;
  margin-top: 25px;
}

.field-name-field-two-column {
  .field-item {
    span {
      margin-bottom: 1.7em;
    }
  }
}

.node-page-attachment-basic {
  .field-name-field-column-title {
    margin-bottom: 1.5em;
    margin-top: 0.3em;
  }
}

.paragraphs-item-page-attachment-column,
.paragraphs-item-page-attachment-two-column,
.paragraphs-item-page-attachment-four-column {
  .content {
    display: none;
  }
}
