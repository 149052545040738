@mixin columns($arg: auto) {
// <column-count> || <column-width>
  @include prefixer(columns, $arg, webkit moz spec);
}

@mixin column-count($int: auto) {
// auto || integer
  @include prefixer(column-count, $int, webkit moz spec);
}

@mixin column-gap($length: normal) {
// normal || length
  @include prefixer(column-gap, $length, webkit moz spec);
}

@mixin column-fill($arg: auto) {
// auto || length
  @include prefixer(columns-fill, $arg, webkit moz spec);
}

@mixin column-rule($arg) {
// <border-width> || <border-style> || <color>
  @include prefixer(column-rule, $arg, webkit moz spec);
}

@mixin column-rule-color($color) {
  @include prefixer(column-rule-color, $color, webkit moz spec);
}

@mixin column-rule-style($style: none) {
// none | hidden | dashed | dotted | double | groove | inset | inset | outset | ridge | solid
  @include prefixer(column-rule-style, $style, webkit moz spec);
}

@mixin column-rule-width ($width: none) {
  @include prefixer(column-rule-width, $width, webkit moz spec);
}

@mixin column-span($arg: none) {
// none || all
  @include prefixer(column-span, $arg, webkit moz spec);
}

@mixin column-width($length: auto) {
// auto || length
  @include prefixer(column-width, $length, webkit moz spec);
}
