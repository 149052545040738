// This function is required and used by the background-image mixin.
@function radial-gradient($G1,        $G2,
                       $G3: false, $G4: false,
                       $G5: false, $G6: false,
                       $G7: false, $G8: false,
                       $G9: false, $G10: false,
                       $pos: 50% 50%,
                       $shape-size: ellipse cover) {

  @each $value in $G1, $G2 {
    $first-val: nth($value, 1);
    $pos-type:  type-of($first-val);

    @if ($pos-type != color) or ($first-val != "transparent") {
      @if ($pos-type == number)
      or ($first-val == "center")
      or ($first-val == "top")
      or ($first-val == "right")
      or ($first-val == "bottom")
      or ($first-val == "left") {

        $pos: $value;

        @if $pos == $G1 {
          $G1: false;
        }
      }

      @else if
         ($first-val == "ellipse")
      or ($first-val == "circle")
      or ($first-val == "closest-side")
      or ($first-val == "closest-corner")
      or ($first-val == "farthest-side")
      or ($first-val == "farthest-corner")
      or ($first-val == "contain")
      or ($first-val == "cover") {

        $shape-size: $value;

        @if $value == $G1 {
          $G1: false;
        }

        @else if $value == $G2 {
          $G2: false;
        }
      }
    }
  }

  $type: radial;
  $gradient: compact($pos, $shape-size, $G1, $G2, $G3, $G4, $G5, $G6, $G7, $G8, $G9, $G10);
  $type-gradient: append($type, $gradient, comma);

  @return $type-gradient;
}
