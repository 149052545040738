// Config
// ---------
// Use the same color all over the place? Need to do
// some math with height and width and text size?
// Sass supports variables as well as basic math
// operations and many useful functions.
//
// For complete documentation:
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#variables_
//
// Available functions:
// http://sass-lang.com/docs/yardoc/Sass/Script/Functions.html

// Available functions:
// SASS:				http://sass-lang.com/docs/yardoc/Sass/Script/Functions.html
// Bourbon:				http://bourbon.io/docs/
// Boubon Neat (grid):	https://github.com/thoughtbot/neat#using-the-grid

// Import variables and mixins to be used (Bourbon)
@import "components/bourbon/bourbon";
@import "components/neat/neat-helpers";

// Bourbon grid display. Comment out to remove display.
$visual-grid: false;
$visual-grid-color: #EEEEEE;

// Set to false if you'd like to remove the responsiveness.
$responsive: true;

// Setup the column, grid, and gutter amounts.
$column: 82px;
$gutter: 29px;
$grid-columns: 12;
$max-width: em(1601);

// Define your breakpoints.
$mobile: new-breakpoint(max-width 480px 4);
$tablet: new-breakpoint(max-width 960px 8);
$laptop: new-breakpoint(max-width 1320px 12);

// Import grid to be used (Bourbon Neat).
// We load this after we provide our overrides: https://github.com/thoughtbot/neat#changing-the-defaults
@import "components/neat/neat";

// Import local files
@import "_variables.sass";
@import "_mixins.sass";
