/*-------------------------

  User profile

--------------------------*/

body[class*="page-users-"]{

  &.not-logged-in{
    #tabs{
      display: none;
    }
  }

  #content h1.title{
    display: none !important;
  }

  .breadcrumb .label{
    color: #000;
    text-decoration: underline;
  }

  .profile{
    @include outer-container();
    font-family: 'Helvetica W01', sans-serif;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #595959;
  }

  // Profile items
  .user-picture{
    float: left;
     img {
      border-radius: 50%;
    }
  }

  .field{
    margin-bottom: 30px;
  }

  .user-picture{
    margin-right: 55px;
  }

  .half{
    @include span-columns(4 of 12);
    @include media($tablet){
      width: 100%;
    }
  }
  
  .half.right{
    @include span-columns(4 of 12);
    @include media($tablet){
      width: 100%;
    }
  }

  .field-label{
    font-weight: 300;
  }

  .field-items{
    color: #1a9e98;
  }

  .field-name-field-terms-and-conditions{
    display: none;
  }
}



