.page-search {
  .block-views {
    padding-top: 20px;
  }

  .views-row {
    border-bottom: 1px dotted #6DA5AA;
    padding-bottom: 13px;

    .views-label {
      font-weight: bold;
    }
    .views-field-type,
    .views-field-field-department {
      margin:8px 0;
    }
  }

  #main#main {
    margin-top: 0;
  }

  .reset {
    float: right;
  }
}

.fancy-search {

  i.fa {
    position: absolute;
    right: 15px;
    top: 30px;
    color: #777;
    font-size:30px;
  }

  input.fancy-search-text {
    width: 100%;
    padding: 15px;
    font-size: 28px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    height: 56px;
    margin-bottom: 20px;
    color: #777;
    border: 1px solid #ccc;
  }

  h2.block-title {
    font-family: 'HelveticaNeueW01-57Cn';
    font-size: 20px;
    color: #6DA5AA;
    background: #fff;
    display: inline-block;
    letter-spacing: 1px;
    line-height: 21px;
    padding: 0 15px 0 0;
    position: relative;
    top: 5px;
    width: 100%;

    span {
      margin-left: 15px;
      background-color:#fff;
      padding:0 15px;
    }

    a {
      margin:0 15px;
      border-left: 10px solid #fff;
      border-right: 10px solid #fff;
    }

    &:after {
      content: '';
      display: block;
      margin-top: -8px;
      border-bottom: 1px dotted #6DA5AA;
    }
  }

  .fancy-search-categories {
    border-bottom: 1px dotted #6DA5AA;
    padding-bottom: 20px;

    .fancy-search-departments {
      margin-bottom: 10px;
    }
  }

  .area-link {
    text-transform: uppercase;

    &.active {
      background-color: #4F6C6F !important;
    }
  }
}
