@mixin hide-text {
  color:            transparent;
  font:             0/0 a;
  text-shadow:      none;
}

// A CSS image replacement method that does not require the use of text-indent.
//
// Examples
//
//   .ir {
//     @include hide-text;
//   }
