//
// ------
// This file is used to define custom overrides
// style changes to be used for building your theme.
// If you wish to use the SCSS syntax, please use this.
// Documentation: http://sass-lang.com/docs/yardoc/_index.

// Import variables and mixins to be used.

// Variables so we don't forget:
// $
@import "_config.sass";

//
//$red: #cc3333;
// test
$red: #e02d2d;
$green: #003333;
$lightGreen: #339999;
$mint: #6da5aa;
$darkMint: #128087;
$turquoise: #6be4ef;
$white: #fff;
$blueGreen: #49d0d9;
$darkGrey: #595959;
$lightGrey: #d9d9d9;
$titleGreen: #128087;

$areaTags: #859c9c;
$areaIntercultural: #525384;
$areaWorkforce: #287bb2;
$areaLanguage: #769b60;
$areaResearch: #059e99;
$areaAll: #7daeb3;

//
// $boldCondensed: font-family:'Helvetica W01 Bd Cn';
// $condensed: font-family:'Helvetica W01 Cn';

html {
  background-color: #ccc;
}

body,
.jPanelMenu-panel {
  background-color: #fff !important;
}

body {
  margin: 0;
  font: 13px / 1.5em "Helvetica W01 Roman", helvetica, Arial, sans-serif;
  letter-spacing: 0.03em;
}

.role-administrator #highlighted {
  //visibility: hidden;
}

.page-404 {
  #breadcrumb {
    display: none;
  }
}

h2.sidr-class-block-title {
  display: none;
}

.sidr ul {
  margin: 0 !important;
}

/*---------------------------------------------------------------------



---------------------------------------------------------------------*/

@font-face {
  font-family: 'thousandsticks_wfregular';
  src: url('../fonts/thousandstickswf-webfont.eot');
  src: url('../fonts/thousandstickswf-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/thousandstickswf-webfont.woff') format('woff'),
    url('../fonts/thousandstickswf-webfont.ttf') format('truetype'),
    url('../fonts/thousandstickswf-webfont.svg#thousandsticks_wfregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

h2 {
  color: $green;
}

h3 {
  font-family: 'Helvetica W01 Cn', Helvetica, Arial, sans-serif;
  font-size: 1.45em;
  line-height: 1.5em;
  color: $darkMint;
  margin-top: 15px;

  @include media ($mobile) {
    font-size: 100%;
  }

  &.large {
    color: #128087;
    font-size: 3em;
    line-height: 1em;
    letter-spacing: 1px;
    padding-bottom: 25px;
    font-weight: normal;
  }
}

p,
#content li,
#content .ui-widget-content,
.entity-paragraphs-item li {
  font-family: 'Helvetica W01 Roman';
  font-size: 15.6px;
  line-height: 23.4px;
  color: $darkGrey;
  margin-bottom: 1.7em;

  @include media ($mobile) {
    font-size: 100%;
  }
}

#content li,
.entity-paragraphs-item li {
  margin-bottom: 0.9em;
}

#content ul {
  margin-left: 20px;
}

.field-name-field-statement,
.statement,
.statement p {
  font-family: 'thousandsticks_wfregular';
  // font-size: 3.25em;
  font-size: 42.25px;
  // line-height: 1.1em;
  line-height: 46.475px;
  color: $green;
  padding: 20px 0;
  border-top: 1px $mint dotted;

  // border-bottom: 1px $mint dotted;
  @include media ($mobile) {
    font-size: 2.5em;
  }
}

.statement p {
  padding-top: 0;
  border-top: none;
}

a,
a:link,
a:visited {
  color: $green;
}

/*---------------------------------------------------------------------

REUSABLE

---------------------------------------------------------------------*/

.teaser {
  @include span-columns(3 of 6);

  @include media($mobile) {
    @include span-columns(4 of 4);
  }

  p {
    &.title {
      margin-bottom: 0;
    }

    &.body {
      font-size: 1em;
    }

    &.image-container,
    &.blog-image,
    &.blog-title {
      margin-bottom: 0;
    }
  }

  a {
    font-family: 'Helvetica W01 Bold';
    font-weight: 300 !important;

    &.button {
      display: block !important;
      width: 100px;
      font-family: 'Helvetica W01 Roman', helvetica, arial, sans-serif;
      color: #fff !important;
      text-align: center;
      background: #2f7eb1;
      /* Old browsers */
      /* IE9 SVG, needs conditional override of 'filter' to 'none' */
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
      background: -moz-linear-gradient(top,
          #2f7eb1 0%,
          #1c5c86 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear,
          left top,
          left bottom,
          color-stop(0%, #2f7eb1),
          color-stop(100%, #1c5c86));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top,
          #2f7eb1 0%,
          #1c5c86 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top,
          #2f7eb1 0%,
          #1c5c86 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(top,
          #2f7eb1 0%,
          #1c5c86 100%);
      /* IE10+ */
      background: linear-gradient(to bottom,
          #2f7eb1 0%,
          #1c5c86 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
          endColorstr='#1c5c86',
          GradientType=0);
      /* IE6-8 */
      margin: 10px 0;
      padding: 5px 10px;
    }

    &.dark {
      display: block;
      color: $green !important;
    }
  }
}

blockquote {
  @include span-columns(4 of 8);
  border-top: dotted 1px $mint;
  border-bottom: dotted 1px $mint;
  background: url('../images/blockquote.jpg') left 2em no-repeat;
  margin: 10px 0 20px 20px;
  padding: 2em 0 2em 6em;

  p {
    color: #6da5aa;
    font-family: 'Helvetica W01 Cn', sans-serif;
    font-weight: 300;
    line-height: 1.2em;
    font-size: 1.7em;
    letter-spacing: 0px;
    margin-bottom: 0;
    font-style: italic;

    @include media($mobile) {
      font-size: 1.7em;
    }
  }

  float: right;
  margin: 10px 0 25px 20px;

  @include media($tablet) {
    width: 100%;
    float: none;
    margin: 2em auto;
    font-size: 100%;
  }

  @include media($mobile) {
    width: 90%;
    font-size: 100%;
  }
}

// testimonial on
.testimonial {
  margin-top: 20px;
  border-top: 1px $mint dotted;
  padding-top: 20px;

  p {
    font-style: italic;

    .attribution {
      display: block;
      padding-top: 10px;
      font-family: 'Helvetica W01 Bold', helvetica, arial, sans-serif;
      font-style: normal;
    }

    .role {
      font-style: normal;
      font-size: 0.8em;
    }
  }
}

//ajax

html.js input.form-autocomplete {
  background-image: url('../images/loading.gif') 0px 0px no-repeat;
}

.ajax-progress .throbber {
  //position based on
  position: absolute;
  margin-left: -8px;
  background: transparent url('../images/loading.gif') 0px 0px no-repeat;
}

//

#breadcrumb {
  float: none !important;
  z-index: 10;

  a {
    color: $green;
  }

  @include media ($tablet) {
    display: none;
  }

  .breadcrumb,
  .breadcrumb a {
    font-size: 16px !important;
  }
}

.breadcrumb {
  margin: 25px 0 15px;
}

// Form

.exposed-form-label {
  display: block;
  float: left;
  font-size: 0.9em;
  line-height: 40px;
  padding-right: 5px;
}

input[type="submit"] {
  background: #c8d9dc;
  color: $green;
  height: 23px;
  border: none;
  margin-top: 0 !important;
  padding: 0 5px;
}

input[type="text"] {
  height: 25px;
  border-radius: 5px;
  // border: none;
  border: 1px solid #009899;
  color: #fff;
  font-family: 'Helvetica W01 Roman', helvetica, arial, sans-serif;
  font-size: 1em;
  padding: 2px 5px;
}

.views-exposed-widgets {
  margin-bottom: 0;
}

#views-exposed-form-events-block {
  border-top: 1px $lightGreen dotted;

  .description {
    display: none;
  }

  .views-submit-button {
    float: right;
  }
}

/*---------------------------------------------------------------------



---------------------------------------------------------------------*/

.hidden {
  display: none !important;
}

/*---------------------------------------------------------------------



---------------------------------------------------------------------*/

#highlighted {
  width: 100%;
  padding-top: 90px;
  -webkit-transition: padding 0.4s ease-in-out;
  -moz-transition: padding 0.4s ease-in-out;
  -ms-transition: padding 0.4s ease-in-out;
  -o-transition: padding 0.4s ease-in-out;
  transition: padding 0.4s ease-in-out;

  &.open {
    // padding-top: 290px;
  }

  .with-subnav & {
    @media screen and (max-width: 1150px) {
      padding-top: 95px;
    }

    @include media ($mobile) {
      padding-top: 58px;
    }
  }

  .view-id-page_banners {
    position: relative;
    height: 250px;
    // height: 370px;
    overflow: hidden;

    .page-banner {
      background: transparent;
      height: 197px;
      overflow: hidden;

      img {
        display: block;
      }

      .banner-overlay {
        height: 250px;
        width: 100%;
        position: absolute;
        top: 0;
        background: transparent url('../images/banner-overlay.png') bottom right no-repeat;
      }
    }
  }

  @include media($mobile) {
    padding-top: 55px;
  }
}

/*---------------------------------------------------------------------

PRIMARY

---------------------------------------------------------------------*/
body.primary-page,
body.page-research-development {
  #main #content {
    #breadcrumb {
      display: none;
    }
  }

  #main {
    margin-top: 60px;

    @include media($mobile) {
      padding-top: 0;
      margin-top: 20px;
    }
  }
}

#content-header {
  img {
    max-width: 100%;
    height: auto;
  }
}

/*---------------------------------------------------------------------



---------------------------------------------------------------------*/

#block-views-testimonials_block-block_1 {
  margin-top: 25px;
}

/*---------------------------------------------------------------------

                TAB DRAWER

---------------------------------------------------------------------*/
#tabs {
  position: fixed;
  left: -5px;
  top: 200px;
  width: 180px;
  background-color: #333;
  z-index: 100;
  -webkit-border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-radius-topright: 20px;
  -moz-border-radius-bottomright: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 3px 2px 7px #222;
  -webkit-box-shadow: 3px 2px 7px #222;
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out;
  transition: left 0.5s ease-in-out;

  &.collapsed {
    left: -150px;

    #tab-handle p {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }

  ul {
    padding-right: 30px !important;
  }

  #tab-handle {
    position: absolute;
    font-size: 40px;
    right: 0;
    width: 30px;
    text-align: center;
    height: 100%;
    top: 0;
    cursor: pointer;

    p {
      color: $darkMint;
      margin: 0;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
      -moz-transition: -webkit-transform 0.5s ease-in-out;
      -o-transition: -webkit-transform 0.5s ease-in-out;
      transition: -webkit-transform 0.5s ease-in-out;
      -webkit-transition: transform 0.5s ease-in-out;
      -moz-transition: transform 0.5s ease-in-out;
      -o-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
    }
  }
}

/*---------------------------------------------------------------------

MAIN

---------------------------------------------------------------------*/

#main {
  @include outer-container;
  //background: url('../images/new-triangles.png') right 0px no-repeat;
  margin-bottom: 50px;

  @include media($mobile) {
    background: #fff;
    margin-top: 75px;
  }

  @include media ($laptop) {
    padding: 20px 0;
  }

  @include media($tablet) {
    padding-top: 0;
  }

  &.no-triangles {
    background: none;
  }

  #content {

    h1.title,
    .video-page h2.block-title {
      // max-width: 70%;
      font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
      color: $titleGreen;
      font-size: 3em !important;
      line-height: 1em;
      letter-spacing: 1px;
      display: block;

      // padding-top: 50px;
      @include media($tablet) {
        position: static;
        left: 0;
        width: inherit;
        padding-top: 0;
        display: none;
      }

      @include media($mobile) {
        position: static;
        left: 0;
        width: inherit;
        padding-top: 0;
        display: none;
        margin-bottom: 20px !important;
      }
    }

    @include media($tablet) {
      @include pad(0 20px);
    }

    @include media($mobile) {
      @include pad(0 20px);
    }

    .node .content img {
      width: 100%;
      height: auto;

      &.file-icon {
        width: auto;
      }
    }

    .interlinking {
      margin-bottom: 40px;
      overflow: hidden;
      @include row();
      @include span-columns(2 of 6);

      .block-title {
        display: block;
        width: 85%;
        font-size: 1em;
        line-height: 1.2em;
        color: $green;
        font-weight: 700;
        margin-bottom: 3px;
      }

      p {
        font-size: 1em;
        line-height: 1.3em;
        padding-bottom: 200px;
        margin-bottom: -200px;

        a {
          position: absolute;
          bottom: 0;
          color: $white;
          background: #2f7eb1;
          /* Old browsers */
          /* IE9 SVG, needs conditional override of 'filter' to 'none' */
          background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
          background: -moz-linear-gradient(top,
              #2f7eb1 0%,
              #1c5c86 100%);
          /* FF3.6+ */
          background: -webkit-gradient(linear,
              left top,
              left bottom,
              color-stop(0%, #2f7eb1),
              color-stop(100%, #1c5c86));
          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(top,
              #2f7eb1 0%,
              #1c5c86 100%);
          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(top,
              #2f7eb1 0%,
              #1c5c86 100%);
          /* Opera 11.10+ */
          background: -ms-linear-gradient(top,
              #2f7eb1 0%,
              #1c5c86 100%);
          /* IE10+ */
          background: linear-gradient(to bottom,
              #2f7eb1 0%,
              #1c5c86 100%);
          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
              endColorstr='#1c5c86',
              GradientType=0);
          /* IE6-8 */
          padding: 5px 10px;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      @include media($tablet) {
        @include span-columns(2 of 6);
      }

      @include media($mobile) {
        @include row();
        @include span-columns(4 of 4);
      }
    }

    .teaser img {
      max-width: 100%;
      height: auto;
    }

    .stats {
      padding: 10px 0;
      border-top: 1px $mint dotted;
      border-bottom: 1px $mint dotted;
    }
  }
}

// NO

.no-image {
  #main {
    #content {
      margin-top: 30px;
    }
  }

  &.sidebar-second,
  &.sidebar-first {
    #breadcrumb {
      padding-top: 70px !important;
    }
  }

  &.sidebar-second,
  &.sidebar-first {
    #main {
      #content {
        h1.title {
          padding-top: 70px !important;
        }
      }
    }
  }
}

// tags and department taxonomy
// TODO: This style of list page is deprectaed and this should be removed
// but we need to remove the taxonomy pages from the CMS somehow
.section-tags,
.section-department,
.section-blogs {
  #main {
    #content {
      margin-top: 50px !important;

      #breadcrumb {
        padding-top: 0;
        display: none;
      }

      h1.title {
        position: static !important;
        left: 0 !important;
        padding-top: 0 !important;
        width: inherit !important;
        margin-top: 0 !important;
        text-transform: capitalize;
      }

      .blog-teaser {
        p {
          display: inline;

          &.blog-title {
            margin-right: 5px;
          }
        }

        .image-container img {
          width: 100%;
          height: auto;
        }

        .button {
          width: 140px;
        }

        @include span-columns(4 of 8);
        margin-right: 1.5% !important;

        @include media($tablet) {
          @include span-columns(4 of 4);
        }

        @include media($tablet) {
          @include span-columns(4 of 4);
        }
      }
    }
  }
}

/*---------------------------------------------------------------------

MAIN CONTENT WITH FIRST

---------------------------------------------------------------------*/

.node-type-page.primary-page #main section#content {
  margin-top: 20px;
}

.page-node.secondary {
  #main {
    #content {
      p {
        @include pad(0 111px 0 0);

        @include media ($mobile) {
          padding-right: 0;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .gallery-preview-container {
        img {
          height: 165px;
          object-fit: cover;
        }
      }

      #breadcrumb {
        margin-top: 0 !important;
      }

      h1.title {
        font-family: 'HelveticaNeueW01-57Cn';
        color: $mint;
        font-size: 3em;
        line-height: 1em;
        letter-spacing: 1px;
        display: block;
        width: auto !important;

        @media screen and (max-width: 1150px) {
          // left: 68.69916%;
        }

        @include media($tablet) {
          position: static;
          padding: 0;
        }

        @include media ($mobile) {
          display: none;
        }
      }

      #breadcrumb {
        float: none !important;
        clear: both;
        position: static !important;
        left: 0 !important;
        width: auto !important;
        padding-top: 0 !important;

        a {
          color: $green;
        }
      }

      .teaser {
        @include span-columns(2 of 6);

        .image-container {
          display: none;
        }

        p {
          background: transparent url('../images/small-triangles.jpg') top left no-repeat;
          border: 1px solid #ccc;
          width: 100%;
          height: 150px;
          padding-right: 0;
        }

        .teaser-body {
          display: none;
        }

        a {
          font-family: 'Helvetica W01 Cn', helvetica, arial, sans-serif;
          color: $darkMint;
          font-size: 2em;
          display: block;
          height: 100%;
          width: 100%;
          line-height: 1.2em;
          margin-top: 40px;
          text-align: center;

          &:hover {
            text-decoration: none;
            color: $mint;
          }
        }

        @include media($mobile) {
          @include span-columns(4 of 4);
          padding: 0 15px;
        }
      }

      @include media($tablet) {
        @include span-columns(8 of 8);
      }

      @include media($mobile) {
        @include span-columns(4 of 4);
      }
    }
  }
}

/*---------------------------------------------------------------------

FRONT

---------------------------------------------------------------------*/

// Moved to pages/_front.scss

/*---------------------------------------------------------------------

SIDEBAR

---------------------------------------------------------------------*/

#sidebar-first {
  @include omega();
  background: none;
  // padding-left: 5px;

  .region-sidebar-first {
    @include span-columns(4 of 4);

    // @include shift(1);
    @include media($mobile) {
      @include pad(0 20px);
    }
  }

  .teaser {
    @include span-columns(2 of 2);

    p {
      font-size: 1em;
    }

    a {
      color: $mint;
    }
  }

  @include media($tablet) {
    @include span-columns(8 of 8);
  }

  @include media($mobile) {
    @include span-columns(4 of 4);
  }
}

/*---------------------------------------------------------------------

SIDEBAR

---------------------------------------------------------------------*/
.sidebar-second {
  #breadcrumb {
    left: 51.5%;
    // padding-top: 100px;
  }

  #main #content {
    h1.title {
      // padding-top: 100px;
    }
  }

  &.with-subnav {
    #breadcrumb {
      // padding-top: 100px;
    }

    #main #content {
      h1.title {
        // padding-top: 100px;
      }
    }
  }
}

#sidebar-second {
  // background: url('../images/bg-sidebar.jpg') 55px 0px no-repeat;
  background: transparent;
  @include pad(220px 0 0 10px);

  @include media($laptop) {
    margin-top: 40px;
    padding-right: 20px;
  }

  @include media($tablet) {
    @include pad(0 20px);
  }

  @include media($mobile) {
    @include pad(0 20px);
    padding-top: 0;
  }

  img {
    max-width: 100%;
  }

  .teaser {
    margin-bottom: 40px;
    @include span-columns(3 of 6);

    p {
      font-size: 1em;

      a {
        color: $darkMint;
        font-weight: 700;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    .button {
      display: none;
    }

    @include media($tablet) {
      @include span-columns(4 of 8);
    }

    @include media($mobile) {
      @include row();
      @include span-columns(4 of 4);
    }
  }

  #block-block-7,
  #block-views-blog-block_1 {
    @include span-columns(3 of 6);
  }

  #block-views-testimonials_block-block {
    border-top: 1px $mint dotted;
    border-bottom: 1px $mint dotted;
    padding: 20px 0;

    .field-name-field-testimonial-image {
      float: left;
      margin-right: 20px;
    }

    .field-name-body {
      padding-top: 7px;
    }

    .field-name-field-testimonial-attribution {
      padding-bottom: 15px;
    }
  }

  .interlinking {
    margin-bottom: 40px;
    @include row();
    @include span-columns(2 of 6);

    .image-container {
      margin-bottom: 0;
    }

    .block-title {
      font-size: 1em;
      color: $green;
      font-weight: 700;
    }

    p {
      font-size: 1em;

      a {
        color: $white;
        background: #2f7eb1;
        /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(0%, #2f7eb1),
            color-stop(100%, #1c5c86));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* IE10+ */
        background: linear-gradient(to bottom,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
            endColorstr='#1c5c86',
            GradientType=0);
        /* IE6-8 */
        padding: 5px 10px;
      }
    }

    img {
      max-width: 100%;
      height: auto;

      p & {
        margin-bottom: 0;
      }
    }

    @include media($tablet) {
      @include span-columns(4 of 8);
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
    }
  }

  .view-events {
    .views-row {
      @include row();
      min-width: 100%;
      border-top: 1px $lightGreen dotted;
      padding-top: 20px;
      display: table;

      .event-date {
        display: table-cell;
        vertical-align: top;
        text-align: center;
        float: left;
        height: inherit;
        margin-right: 20px;
        width: 80px;
        background: #2f7eb1;
        /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(0%, #2f7eb1),
            color-stop(100%, #1c5c86));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* IE10+ */
        background: linear-gradient(to bottom,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
            endColorstr='#1c5c86',
            GradientType=0);
        /* IE6-8 */
        line-height: 1.2em;
        padding: 5px 10px;
        margin-bottom: 20px;
        font-size: 0.8em;
        color: #fff;

        .month {
          font-size: 1.4em;
        }

        .date {
          font-size: 3em;
          line-height: 1em;
        }
      }

      .event-text {
        display: table-cell;
        vertical-align: top;
        width: 100%;

        .time {
          display: inline;
          color: $green;
          font-size: 0.8em;
          font-family: 'Helvetica W01 Bold';
          float: right;
          margin-top: 2px;
        }

        .title {
          display: inline;
          color: $green;
          font-size: 1.05em;
          font-family: 'Helvetica W01 Bold';
          margin-bottom: 0 !important;

          a {
            color: $green;

            &:hover {
              color: $lightGreen;
              text-decoration: none;
            }
          }
        }

        .body {
          font-size: 1.05em;
          line-height: 1.7em;
          margin-bottom: 10px;
        }

        .button {
          width: 130px;
          margin-bottom: 20px;
        }

        @include media($mobile) {
          display: block;
          clear: both;
        }
      }
    }
  }

  .view-products-services {
    .views-exposed-widgets {
      @include row();

      .exposed-form-label {
        font-family: 'Helvetica W01 Bold';
        font-size: 0.8em;
      }
    }

    .views-row {
      @include row();
      border-top: 1px $lightGreen dotted;
      padding: 30px 0;
      display: table;

      .product-icon {
        display: table-cell;
        vertical-align: top;
        text-align: center;
        float: left;
        height: 63px;
        margin-right: 20px;
        width: 49px;
        background: transparent url('../images/prodservsprites.png') 0 0 no-repeat;

        &.wd-icon {
          background-position: -101px 0;
        }

        &.ld-icon {
          background-position: -51px 0;
        }

        &.id-icon {
          background-position: -152px 0;
        }
      }

      .product-text {
        display: table-cell;
        vertical-align: top;

        .title {
          display: inline;
          color: $green;
          font-size: 1.05em;
          font-family: 'Helvetica W01 Bold';

          a {
            color: $green;

            &:hover {
              color: $lightGreen;
              text-decoration: none;
            }
          }
        }

        .body {
          font-size: 1.05em;
          line-height: 1.7em;
          margin-bottom: 10px;
        }

        .size {
          display: inline;
          color: $green;
          font-size: 0.8em;
          font-family: 'Helvetica W01 Bold';
          float: right;
          margin-top: 2px;
        }

        .button {
          width: 130px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .product-view-title h3 {
    font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
    color: #128087 !important;
    font-size: 3em !important;
    line-height: 1em !important;
    letter-spacing: 1px !important;
    padding-bottom: 25px;
    font-weight: normal;
  }

  #block-views-testimonials_block-block_1 {
    //about us
    border-bottom: 1px $mint dotted;
    margin-bottom: 20px;

    .interlinking {
      margin-bottom: 10px;
    }
  }

  //about us sidebar
  #block-block-5,
  #block-block-6,
  #block-block-13 {
    .block-title {
      font-size: 1em;
      color: $green;
      font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
      font-weight: 300;
      margin-bottom: 3px;
    }

    p {
      font-size: 1em;
    }
  }

  #block-block-7 {
    //
    @include span-columns(3 of 6);
    background: url('../images/twitter.jpg') left 0px no-repeat;

    .block-title {
      padding-left: 25px;
      padding-bottom: 10px;
    }

    p {
      font-size: 1em;
      border-bottom: 1px $lightGreen dotted;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &:last-of-type {
        border-bottom: none;
      }
    }

    a {
      color: $green;
    }

    .date {
      color: #999999;
    }
  }

  #block-views-b537a91afa05b2875c306c11b36d881e {
    //recent
    @include span-columns(3 of 6);
    margin-right: 0;

    .teaser {
      width: 100%;
    }
  }

  #block-block-7,
  #block-views-b537a91afa05b2875c306c11b36d881e {
    .block-title {
      font-size: 1em;
      color: $green;
      font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
      font-weight: 300;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px $lightGreen dotted;
      //background: url('../images/post-icon.jpg') right 0px no-repeat;
    }

    @include media($mobile) {
      @include row();
      @include span-columns(4 of 4);
    }
  }

  .block.contact-us {
    clear: both;
    @include clearfix();
    @include span-columns(6 of 6);
    width: 100%;
    padding: 20px 0 5px 0;
    border-top: 1px $lightGreen dotted;

    // border-bottom: 1px $lightGreen dotted;
    p {
      font-size: 1em;
    }

    .title {
      width: 14.87284%;
      margin-right: 2.55%;
      float: left;
      height: 40px;
      font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
      color: $green;
      border-right: 1px solid $mint;
      padding-right: 5px;
    }

    .address {
      @include span-columns(2 of 6);
      height: 40px;
      margin-right: 2.55%;
      border-right: 1px solid $mint;
    }

    .address+p {
      @include span-columns(2 of 6);
      @include omega();
    }
  }

  .view-products-services,
  .view-events,
  .view-display-id-block_5 {
    .view-filters {
      font-family: 'Helvetica W01 Roman';
      color: $darkMint;

      .exposed-form-label {
        display: block;
        float: left;
        font-size: 0.9em;
        line-height: 40px;
        padding-right: 5px;

        @include media($tablet) {
          float: none;
        }
      }

      form {
        border-top: 1px $darkMint dotted;

        // border-bottom: 1px $darkMint dotted;
        input {
          color: $darkMint;
          -webkit-border-radius: 5px;
          border-radius: 5px;

          &[type="text"] {
            border: 2px solid #cedcdf;
            outline: none;
            height: 23px;
            padding: 4px;
          }

          &[type="submit"] {
            background: #c8d9dc;
            color: $green;
            height: 23px;
            border: none;
            padding: 0 5px;
          }
        }

        .chosen-container-single .chosen-single {
          color: $darkMint;
        }

        @include media($mobile) {
          padding: 20px 0;
        }
      }
    }

    .views-exposed-widget {
      // margin-right: 10px;
      // width: 155px;
      padding-right: 7px;

      &.views-submit-button {
        width: auto;
        height: 24px;

        input {
          margin-top: 0;
        }

        @include media($mobile) {
          float: none;
          margin: 10px 0;
          clear: both;
        }
      }
    }

    .views-exposed-widgets {
      margin-bottom: 0;
      position: relative;

      .ajax-progress .throbber {
        top: 7px;
        right: -10px;
      }
    }

    //Pager
    .item-list {
      .pager {
        position: relative;

        li {
          font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
          color: $green;

          a {
            color: $green;
          }
        }
      }
    }
  }
}

// more about us sidebar
body.page-about-us #sidebar-second .interlinking {
  margin-bottom: 40px !important;

  p a {
    position: absolute;
    right: 0;
  }
}

/*---------------------------------------------------------------------

PRE

---------------------------------------------------------------------*/
#pre-footer-wrapper {
  width: 100%;
  margin: 40px 0;

  #pre-footer {
    @include outer-container;
    @include pad(0 57px);

    @include media ($mobile) {
      @include pad(0 20px);
    }
  }

  .interlinking {
    position: relative;
    padding-bottom: 60px;
    margin: 40px 0;
    @include row();
    @include span-columns(3 of 12);
    // min-height: 250px;

    &.views-row-last {
      @include omega();
    }

    .block-title {
      font-size: 1em;
      color: $green;
      font-weight: 700;
      margin-top: 0;
    }

    >p {
      margin-bottom: 0;
    }

    p {
      font-size: 1em;

      a {
        color: $white;
        background: #2f7eb1;
        /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(0%, #2f7eb1),
            color-stop(100%, #1c5c86));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* IE10+ */
        background: linear-gradient(to bottom,
            #2f7eb1 0%,
            #1c5c86 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
            endColorstr='#1c5c86',
            GradientType=0);
        /* IE6-8 */
        padding: 10px 20px;
        position: absolute;
        bottom: 0;
        right: 0;

        @include media($mobile) {
          position: static;
          display: block;
          margin: 10px 0;
          text-align: center;
        }
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;

      @include media($mobile) {
        height: auto;
      }

      @include media($tablet) {
        height: auto;
      }
    }

    @include media($mobile) {
      @include span-columns(4 of 4);
      min-height: 0 !important;
      height: auto;
    }

    @include media($tablet) {
      @include span-columns(2 of 2);
      min-height: 365px;
    }
  }
}

.page-workforce-development,
.page-language-development {
  #pre-footer-wrapper {
    #pre-footer {
      @include outer-container;
      @include pad(0 0px);

      @include media ($tablet) {
        @include pad(0 20px);
      }

      h2.block-title {
        margin-bottom: 0.75em;
        background: #fff;
      }

      .content {
        padding-top: 10px;
      }
    }
  }
}

/*---------------------------------------------------------------------

INTERCULTURAL

---------------------------------------------------------------------*/
.page-intercultural-development-intercultural-centre {
  .breadcrumb {
    // display: none !important;
  }

  h1 {
    // text-indent: -9999px;
    // height: 0;
    // padding: 0;
    // margin: 0;
  }

  #highlighted {
    a:hover {
      opacity: 1.0 !important;
    }

    .flexslider {
      margin-bottom: 0 !important;
    }

    @include media($mobile) {
      display: none;
    }

    .flex-control-paging {
      display: none;
    }

    .flex-direction-nav a:before {
      content: "";
    }

    .flex-direction-nav .flex-prev {
      left: 0;
      width: 44px;
      height: 44px;
      background: transparent url('../images/gal-nav-left.png') top left no-repeat;
    }

    .flex-direction-nav .flex-next {
      right: 0;
      width: 44px;
      height: 44px;
      background: transparent url('../images/gal-nav-right.png') top left no-repeat;
    }
  }

  // special formatting for when there is a
  #highlighted~#page {
    #main {
      background: none !important;
      margin-top: 0 !important;
    }
  }

  #main {
    @include span-columns(12 of 12);

    // background: url("../images/new-triangles.png") right 0px no-repeat;
    padding: 0 20px;
    // I think we're doing the nav bar different now?
    // margin-top: 135px;

    @include media($mobile) {
      padding: 0 10px;
    }

    #content {
      padding-top: 100px;
      width: 100% !important;

      h3 {
        width: 100%;
      }

      #content-header {
        @include span-columns(4 of 12);

        @include media($mobile) {
          @include span-columns(4 of 4);
        }
      }

      #content-area {
        // @include span-columns(8 of 12);

        @include media($mobile) {
          @include span-columns(4 of 4);
        }
      }
    }
  }

  #pre-footer-wrapper {
    margin: 40px 0 !important;
  }

  #pre-footer {
    padding: 0 20px !important;

    .ic-prefooter-left,
    .ic-prefooter-mid,
    .ic-prefooter-right {
      @include span-columns(4 of 12);

      @include media($tablet) {
        @include span-columns(8 of 8);
      }

      @include media($mobile) {
        @include span-columns(4 of 4);
      }
    }

    h2 {
      display: inline;
      padding: 0 15px;
      margin-left: 15px;
      background: #fff;
      position: relative;
      top: -0.6em;
      font-family: 'HelveticaNeueW01-57Cn';
      color: #6da5aa;
      font-size: 1.5em;
      line-height: 1em;
      letter-spacing: 1px;
    }

    .block-inner {
      border-top: 1px dotted $mint;
    }

    .teaser {
      width: 100%;

      .image-container {
        a {
          margin-right: 0;
        }

        height: 0;
      }

      img {
        float: left;
        margin-right: 20px;
        height: auto;
        padding-bottom: 50px;
      }

      p {
        font-size: 18px;
      }
    }

    #block-block-7 {
      p {
        font-size: 14px;

        a {
          color: $darkMint;
        }

        .date {
          color: #aeaeae;
        }
      }
    }

    .view-display-id-teaser_1_block {
      .image-container {
        img {
          width: 100%;
        }
      }

      p {
        display: none;

        a {
          height: 0;
        }
      }
    }

    #block-views-19d2cfd81efe6cb093c7463aeb7f03ae {
      .teaser p {
        display: none !important;
      }
    }

    .view-display-id-intercultural_events_display_3 {
      h3 {
        font-size: 20px;
        margin: 0;
      }

      .views-field-field-date-2 .field-content {
        margin-bottom: 0;
      }
    }

    .view-intercultural-center-slideshow {
      padding-bottom: 50px;

      img {
        width: 100%;
        height: auto;
        cursor: pointer;
      }
    }

    @include media($tablet) {
      padding: 0 20px !important;
    }

    @include media($mobile) {
      padding: 0 10px !important;
    }
  }

  #top-footer {
    margin-top: 0 !important;
  }

  /************ FIXES FOR MODAL FROM FRONT ***************/

  div[style] #modalContainer {
    width: auto !important;
    height: auto !important;
    padding: 15px !important;
  }

  #imageData #bottomNav {
    height: auto !important;
    margin-top: 0 !important;
  }
}

/*---------------------------------------------------------------------

EVENT FOOTER

---------------------------------------------------------------------*/
#event-footer-wrapper {
  width: 100%;
  background: #eaf2f2 url('../images/shadow.png') top center no-repeat;
  margin-top: 20px;

  #event-footer {
    @include outer-container;
    font-family: 'Helvetica W01 Roman';

    .region {

      #block-views-calendar-block_2,
      #block-views-calendar-block_3 {
        margin: 20px 0;
        position: relative;

        .pager {
          width: 100%;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;

          .pager-current {
            display: none;
          }

          .pager-next {
            position: absolute;
            top: 40px;
            right: -75px;
            width: 61px;
            height: 91px;

            a {
              display: block;
              width: 100%;
              height: 100%;
              background: url('../images/arrow-right.jpg') center center no-repeat;
              text-indent: -9999px;
            }
          }

          .pager-previous {
            position: absolute;
            top: 40px;
            left: -75px;
            width: 61px;
            height: 91px;

            a {
              display: block;
              width: 100%;
              height: 100%;
              background: url('../images/arrow-left.jpg') center center no-repeat;
              text-indent: -9999px;
            }
          }

          .ajax-progress .throbber {
            position: absolute;
            background: transparent url('../images/loading.gif') 0px 0px no-repeat;
          }
        }

        @include media($mobile) {
          @include pad(0 20px);
        }
      }
    }

    .block-title {
      @include row();
      @include span-columns(12 of 12);
      font-size: 1.85em;
      color: $green;
    }

    .event {
      border-left: 1px $lightGreen dotted;
      padding-left: 10px;
      min-height: 112px;
      @include span-columns(4 of 12);
      position: relative;

      .event-image {
        float: left;
        margin-right: 10px;

        img {
          margin-top: 3px;
        }
      }

      .event-text {
        .day {
          font-size: 0.92em;
          // font-weight: bold;
          font-family: 'Helvetica W01 Bold';
          color: $darkMint;
        }

        .title {
          font-size: 1.4em;
          font-weight: normal;
          color: $green;
          margin: 3px 0;
          line-height: 1em;
        }

        .body {
          font-size: 0.92em;
          line-height: 1.2em;
          margin-bottom: 13px;
          color: #000;
        }

        .duration {
          line-height: 1em;
          font-size: 0.92em;
          // font-weight: bold;
          font-family: 'Helvetica W01 Bold';
          color: $darkMint;
        }
      }

      @include media($tablet) {
        @include span-columns(8 of 8);
      }

      @include media($mobile) {
        .views-field-field-event-image {
          float: none;
        }

        @include row();
        @include span-columns(4 of 4);
        margin: 10px 0;
      }
    }
  }
}

/*---------------------------------------------------------------------

TOP FOOTER

---------------------------------------------------------------------*/
#block-views-63fde490140096f1b08404531b75f908 h2.block-title {
  padding-left: 20px;
}

#top-footer-outer {
  width: 100%;
  background-color: #d9d9d9;
  border-top: 1px solid #ccc;

  #top-footer {
    @include outer-container;

    @include media($laptop) {
      padding: 0 60px;
    }

    @include media($tablet) {
      padding-right: 20px;
      padding-left: 20px;
    }

    padding-top: 30px;
    padding-bottom: 30px;

    .block-title {
      font-family: 'Helvetica W01 Light Cn', helvetica, arial, sans-serif;
      color: $darkMint;
    }

    #block-block-2 {
      @include span-columns(3 of 12);

      .block-inner {
        p {
          font-size: 1em;
          line-height: 1.2em;
          color: #595959;
        }

        a {
          color: $lightGreen;
        }

        h2 {
          max-width: 70%;
          font-size: 1em;
          padding-top: 10px;
          font-weight: 700;
          color: $lightGreen;
        }
      }

      @include media($laptop) {
        padding-left: 20px;
      }

      @include media($tablet) {
        @include row();
        @include span-columns(6 of 8);
        padding-left: 20px;
      }

      @include media($mobile) {
        @include row();
        @include span-columns(4 of 4);
      }
    }

    #block-views-63fde490140096f1b08404531b75f908 {
      @include span-columns(9 of 12);

      @include media($tablet) {
        display: none;
      }

      .view-display-id-footer_interlinking {
        position: relative;
        overflow: hidden;
        @include span-columns(9 of 9);

        .interlinking {
          @include span-columns(3 of 9);
          padding-bottom: 1000px;
          margin-bottom: -1000px;
          padding-right: 20px;
          padding-left: 20px;
          border-left: 1px $lightGreen dotted;
          font-family: 'Helvetica W01 Roman', Helvetica, Arial, sans-serif;

          .block-title {
            font-size: 1em;
            margin-bottom: 5px;
            color: $lightGreen;
            font-weight: bold;
            @include row();
          }

          p {
            margin: 10px 0 40px 0;
            font-size: 1em;
            line-height: 1.2em;

            a {
              position: absolute;
              bottom: 0;
              margin-top: 30px;
              background: #2f7eb1;
              /* Old browsers */
              /* IE9 SVG, needs conditional override of 'filter' to 'none' */
              background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJmN2ViMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzVjODYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
              background: -moz-linear-gradient(top,
                  #2f7eb1 0%,
                  #1c5c86 100%);
              /* FF3.6+ */
              background: -webkit-gradient(linear,
                  left top,
                  left bottom,
                  color-stop(0%, #2f7eb1),
                  color-stop(100%, #1c5c86));
              /* Chrome,Safari4+ */
              background: -webkit-linear-gradient(top,
                  #2f7eb1 0%,
                  #1c5c86 100%);
              /* Chrome10+,Safari5.1+ */
              background: -o-linear-gradient(top,
                  #2f7eb1 0%,
                  #1c5c86 100%);
              /* Opera 11.10+ */
              background: -ms-linear-gradient(top,
                  #2f7eb1 0%,
                  #1c5c86 100%);
              /* IE10+ */
              background: linear-gradient(to bottom,
                  #2f7eb1 0%,
                  #1c5c86 100%);
              /* W3C */
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
                  endColorstr='#1c5c86',
                  GradientType=0);
              /* IE6-8 */
              color: #fff;
              text-transform: uppercase;
              padding: 5px;
              font-family: 'Helvetica W01 Bd Cn';
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}

/*---------------------------------------------------------------------



---------------------------------------------------------------------*/

#top-footer {
  @include media ($mobile) {
    padding: 0 20px;
  }
}

#footer-outer {
  overflow: hidden;
  width: 100%;

  footer {
    @include outer-container;

    @include media($tablet) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @include row();
    padding-top: 10px;

    @include media ($mobile) {
      padding: 10px 20px;
    }

    p {
      font-size: 0.8em;
      // color: $darkGrey;
    }

    #block-block-1 {
      // Copyright
      @include span-columns(12 of 12);
      display: table;
      margin-bottom: 10px;

      img {
        display: table-cell;
        vertical-align: middle;
        height: 30px;
        width: auto;
        display: inline;
        padding-right: 10px;
      }

      p {
        display: table-cell;
        vertical-align: middle;
        margin-top: -10px;
        display: inline;
      }

      padding-top: 10px;

      @include media($tablet) {
        @include span-columns(8 of 8);
      }

      @include media($mobile) {
        @include row();
        @include span-columns(4 of 4);
      }
    }

    #block-menu-menu-footer-menu {

      // margin-top: 8px;
      // padding-top: 10px;
      // width: inherit !important;
      // float: right !important;
      // @include span-columns(3 of 12);
      // @include shift(3);
      // @include media($tablet){
      // 	@include span-columns(3 of 8);
      // 	@include shift(0);
      // }
      // @include media($mobile) {
      // 	@include row();
      // 	@include span-columns(4 of 4);
      // }
      .block-title {
        display: none;
      }

      ul {
        list-style-type: none;

        li {
          padding-top: 0px;
          line-height: 1em;
          list-style: none;
          float: left;

          &:last-child {
            padding-right: 0;
          }

          &.sitemap {
            border-right: 1px solid $lightGreen;
          }

          a {
            text-transform: uppercase;
            font-size: 0.8em;
            // color: $darkGrey;
          }
        }
      }
    }
  }
}

/*---------------------------------------------------------------------

NO SIDEBARS

---------------------------------------------------------------------*/

.no-sidebars {
  #main {
    #content {
      margin-top: 0px;

      #content-header {
        @include row();
      }

      // TODO: this is breaking other pages
      /*
			.breadcrumb{
				@include span-columns(6 of 12);
				float: right;
			}
      */

      .content {
        clear: both;
      }
    }
  }

  /*
	#top-footer{
		margin-top: 100px;
	}
  */
  &.page-search {
    #main {
      margin-top: 145px;
    }

    #breadcrumb {
      display: none;
    }

    #edit-keys {
      color: $green;
    }
  }

  .search-results {
    font-size: 80%;
  }
}

/*---------------------------------------------------------------------

CONTENT

---------------------------------------------------------------------*/

.page-node-content-management {
  #breadcrumb {
    //display: none;
  }

  #highlighted {
    visibility: hidden;
    padding-top: 0;
  }

  #main #content {
    width: 100%;
  }

  #main #content-header h1.title {
    position: static;
  }

  a.tabledrag-handle {
    .handle {
      height: 22px;
      width: 22px;
    }
  }

  .view-content-management {
    margin-bottom: 40px;

    .view-header {
      font-size: 1.5em;
      line-height: 1.5em;
      color: $darkMint;
      margin-top: 15px;

      p {
        margin-bottom: 0;
      }
    }

    .cols-3 td {
      width: 33%;
    }

    .cols-4 td {
      width: 25%;
    }

    .cols-2 td {
      width: 20%;
    }

    .views-table tr.odd,
    .views-table tr.even {
      -webkit-transition: background-color 0.2s linear;
      -moz-transition: background-color 0.2s linear;
      -ms-transition: background-color 0.2s linear;
      -o-transition: background-color 0.2s linear;
      transition: background-color 0.2s linear;

      a {
        color: $mint;
      }

      &:hover {
        background-color: $mint !important;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }

    .views-field-edit-node {
      a {
        display: block;
        width: 100px;
        font-family: 'Helvetica W01 Roman', helvetica, arial, sans-serif;
        color: #fff !important;
        text-align: center;
        background-color: #e02d2d;
        margin: 10px 0;
        padding: 5px 10px;
        text-transform: uppercase;
      }
    }
  }

  #top-footer {
    display: none;
  }
}

/*---------------------------------------------------------------------

MAILCHIMP

---------------------------------------------------------------------*/
// override for logged
.logged-in .block-mailchimp-lists {
  label {
    display: inline !important;
  }
}

.block-mailchimp-lists {
  .form-item {
    width: 50%;
    margin: 5px;
    float: left;
  }

  #edit-mailchimp-lists-mailchimp-website-sign-ups-title {
    display: none !important;
  }

  h2 {
    display: inline;
    padding: 0 15px;
    margin-left: 15px;
    background: #fff;
    position: relative;
    top: -0.6em;
    font-family: 'HelveticaNeueW01-57Cn';
    color: $mint;
    font-size: 1.5em;
    line-height: 1em;
    letter-spacing: 1px;
  }

  label {
    display: none;
  }

  input[type="text"] {
    @include span-columns(3 of 4);
    float: left;
  }

  input[type="submit"] {
    @include button();
    // @include span-columns(1 of 4);
    float: right;
    line-height: 4px;
    padding: 15px 25px !important;
  }
}

/*---------------------------------------------------------------------

SUBPAGE

---------------------------------------------------------------------*/
// TODO: this is probably deprecated styles now
body.secondary,
body.section-blog {

  #block-views-85e5b8ef3ff910ca6d4790388209dd4c,
  #block-views-3b185aeaa17417702427aed55af57222,
  #block-views-c482b978b336d4ab70dfd861d08efe60,
  #block-views-dc33ca0a3fe97b0f9cf4134ee1b62d86 {
    margin-top: 30px;

    .block-inner {
      .teaser {
        img {
          float: left;
          margin-right: 20px;
        }

        p {
          padding-top: 10px;

          a {
            font-size: 1.5em;
            font-family: 'HelveticaNeueW01-57Cn';
            color: $mint !important;

            &:hover {
              text-decoration: none !important;
              color: $darkMint !important;
            }
          }

          span {
            display: block;
          }
        }
      }
    }
  }

  #block-block-7 {
    //
    @include span-columns(4 of 4);
    background: url('../images/twitter.jpg') left 0px no-repeat;

    .block-title {
      font-family: 'Helvetica W01 Bold', arial, helvetica, sans-serif;
      font-size: 1.2em;
      padding-left: 25px;
      padding-bottom: 10px;
      border-bottom: 1px $lightGreen dotted;
    }

    p {
      font-size: 1em;
      border-bottom: 1px $lightGreen dotted;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &:last-of-type {
        border-bottom: none;
      }
    }

    a {
      color: $green;
    }

    .date {
      color: #999999;
    }
  }
}

/*------------------------------------------------------------------------



-------------------------------------------------------------------------*/

#modalContainer {
  overflow: hidden;
}

#bottomNav {
  height: 0;
}

.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-draggable.ui-resizable {
  display: none !important;
}

/*------------------------------------------------------------------------

RESEARCH AND DEVELOPMENT LIST
GALLERY LIST

-------------------------------------------------------------------------*/

.page-research-development {
  #main {
    @include media($mobile) {
      padding-top: 0;
    }
  }
}

.page-research-development-projects-development,
.page-research-development-applied-research-projects,
.page-products-services,
.page-products,
.page-photos,
.page-who-we-are-photo-gallery,
.page-about-us-video-gallery {
  #main {
    #content {
      width: 100% !important;

      #breadcrumb {
        position: static;
        margin-top: 0;
        padding-top: 0;
        display: block;
        width: 100%;
      }

      h1.title {
        font-size: 3em;

        @include media($tablet) {
          margin-left: 20px;
        }

        @include media($mobile) {
          margin-left: 0;
          margin-bottom: 20px;
        }
      }

      ul {
        margin-left: 0;
      }

      .views-display-toggle-list {
        ul li.views-row {
          height: auto !important;
          margin-bottom: 20px;
        }

        .views-field-field-project-filter-thumbnail,
        .views-field-field-product-image {
          float: left;
          margin-right: 20px;
        }

        .views-field-title a {
          font-size: 26px;
          display: block;
          margin-bottom: 20px;
        }
      }

      .view-id-project_list.view-id-project_list,
      .view-products-services-big-filter.view-products-services-big-filter {
        .grid-container {
          transition: height 0.5s ease-in-out;
          position: relative;
          overflow: hidden;
        }

        li.cbp-item {
          height: 350px !important;
        }

        li.cbp-item-list-view {
          height: 250px !important;

          &.cbp-list-view-hidden {
            margin-top: 0;
            height: 0 !important;
          }
        }

        span.filter-text {
          font-size: 15px;
          font-family: 'HelveticaNeueW01-57Cn', arial, sans-serif;
          margin-right: 10px;
          color: $mint;

          @include media(max-width 1120px) {
            display: block;
          }
        }

        #filters-container {
          @include outer-container();
          max-width: 100%;
          width: 100%;
          border-top: 1px dotted $mint;
          border-bottom: 1px dotted $mint;
          margin: 20px 0;

          .chosen-container,
          .filter-text,
          .search-box {
            margin-top: 10px;
            margin-left: 10px;
            float: left;
          }

          .search-box {
            margin-left: 10px;

            input[type="text"] {
              margin-right: 5px;
              border: 1px #bcbcbc solid;
            }

            input[type="submit"] {
              height: 23px;
              @include button();
              display: inline !important;
              border-radius: 3px !important;
            }

            @include media(max-width 1120px) {
              margin-left: 0px;
            }
          }

          .mode-box {
            float: right;
            margin: 10px 0;

            a {
              margin-left: 10px;

              &.card-mode {
                background: transparent url('../images/card_inactive.jpg') 0 0 no-repeat;
                display: block;
                float: left;
                width: 20px;
                height: 20px;
                text-indent: -9999px;

                &.active,
                &:hover {
                  background-image: url('../images/card_active.jpg');
                }
              }

              &.list-mode {
                background: transparent url('../images/list_inactive.jpg') 0 0 no-repeat;
                display: block;
                float: left;
                width: 35px;
                height: 20px;
                text-indent: -9999px;

                &.active,
                &:hover {
                  background-image: url('../images/list_active.jpg');
                }
              }
            }
          }
        }

        .cbp-item-wrapper {
          overflow: hidden;

          .views-field-field-project-image {
            img {
              width: 100%;
              height: auto;
            }
          }

          .views-field-title {
            font-family: 'HelveticaNeueW01-57Cn', arial, sans-serif;
            padding: 20px 10px 10px 10px;
            font-size: 26px;
            text-decoration: none !important;

            a {
              line-height: 26px;
            }

            @include media($mobile) {
              line-height: 1.2em;
            }
          }

          .views-field-body {
            padding: 0 10px;
            font-size: 16px;
            text-decoration: none;

            .field-content,
            .field-content p {
              overflow: hidden;
              font-size: 16px;
              padding: 0 !important;
            }
          }
        }
      }

      .block-views,
      .view-id-gallery_list,
      .view-id-video_list {
        li.cbp-item {
          height: 200px !important;

          .gallery-preview-container {
            position: relative;

            img {
              width: 100%;
              height: auto;
            }

            .gallery-preview-overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              width: 100%;
              background-color: transparent;
              background-color: rgba(0, 0, 0, 0.7);
              -webkit-transition: background-color 0.2s ease-in-out;
              -moz-transition: background-color 0.2s ease-in-out;
              -o-transition: background-color 0.2s ease-in-out;
              transition: background-color 0.2s ease-in-out;

              &:hover {
                background-color: rgba(0, 0, 0, 0.3);
              }

              .gallery-preview-text {
                width: 100%;
                position: absolute;
                bottom: 20px;
                padding-left: 20px;

                span {
                  display: block;
                  width: 90%;
                  color: $mint !important;

                  &.gallery-title {
                    font-size: 22px !important;
                    line-height: 28px;
                    color: #fff !important;
                    margin-bottom: 5px !important;
                  }
                }
              }
            }
          }
        }

        span.filter-text {
          font-size: 12px;
          font-family: 'Helvetica W01 Bold', arial, sans-serif;
          text-transform: uppercase;
          margin-right: 10px;
          color: $darkGrey;

          @include media(max-width 1120px) {
            display: block;
          }
        }

        #filters-container,
        .fancyFilters {
          margin: 20px 0;

          .search-box {
            // max-width: 100.0625em;
            margin-top: 20px;
            padding: 15px;
            // margin-left: auto;
            // margin-right: auto;
            background: #275757;
            background: -moz-linear-gradient(270deg, #275757 0%, #083939 100%);
            background: -webkit-linear-gradient(270deg,
                #275757 0%,
                #083939 100%);
            background: -o-linear-gradient(270deg, #275757 0%, #083939 100%);
            background: -ms-linear-gradient(270deg, #275757 0%, #083939 100%);
            background: linear, 0deg, #275757 0%, #083939 100%;
            border-radius: 5px;

            input[type="text"] {
              margin-right: 5px;
              border-radius: 3px;
              color: #595959;
              width: 150px !important;
              font-size: 14px !important;
              border: none;
              background-color: #ced9d9;
              font-family: 'HelveticaNeueW01-57Cn';
            }

            input[type="submit"] {
              text-transform: uppercase;
              font-family: 'HelveticaNeueW01-57Cn';
              height: 25px;
              background-color: #abc761;
              color: #000;
              margin-bottom: 0;
              border-radius: 3px;
            }

            @include media(max-width 1120px) {
              margin-left: 0px;
            }
          }
        }

        button {
          display: inline-block;
          padding: 3px 8px;
          border-radius: 3px;
          color: #fff;
          background-color: #7daeb3;
          text-decoration: none;
          width: auto;
          font-family: 'HelveticaNeueW01-57Cn', helvetica, arial, sans-serif;
          font-size: 13px;
          line-height: 19.5px;
          margin-right: 4px;
          cursor: pointer;
          box-shadow: none;
          border: none;
          text-transform: uppercase;

          &:active {
            position: relative;
            top: 2px;
            left: 2px;
            box-shadow: none;
            border: none;
            background-color: $darkMint;
          }

          &:hover {
            background-color: $darkMint;
          }

          .cbp-filter-counter {
            display: inline;
            margin-left: 3px;

            &:before {
              content: '(';
            }

            &:after {
              content: ')';
            }
          }

          &.cbp-filter-item-active {
            background-color: $darkMint;
            color: #fff;

            &:active {
              // box-shadow: 0 0 $darkMint;
            }

            .cbp-filter-counter {
              color: #fff;
            }
          }

          @include media($mobile) {
            display: block;
            width: 100%;
            margin-bottom: 3px;

            &.hidden {
              display: none;
            }
          }
        }
      }
    }
  }

  #main #content .breadcrumb {
    float: none !important;
    clear: both !important;
  }

  #top-footer {
    margin-top: 0;
  }
}

.page-research-development-applied-research-projects #main #content .view-project-list .grid-container {
  @include media($mobile) {
    height: auto !important;
  }
}

.page-research-development-applied-research-projects #main #content .view-project-list .grid-container .cbp-wrapper {
  @include media($mobile) {
    position: static !important;
  }
}

.view-project-list .cbp-wrapper .cbp-item .views-field-field-project-filter-thumbnail img {
  height: calc(100% - 30px) !important;
  position: absolute;
}

.view-project-list .cbp-wrapper .cbp-item .views-field-field-project-short-title {
  bottom: 0;
  position: absolute;
  height: 30px;
}

.view-project-list .cbp-wrapper .cbp-item .views-field-field-project-short-title a {
  @media screen and (min-width: 768px) {
    font-size: 90%;
  }
}

.view-project-list .cbp-wrapper .cbp-item .views-field-body {
  display: none;
}

.page-research-development-applied-research-projects #main #content .view-project-list .views-row.cbp-item.cbp-item {
  @include media($mobile) {
    position: relative;
    float: left;
    width: 100%;
    max-height: 9999px !important;
    height: auto !important;
    transform: none !important;
  }
}

body[class*="page-research-development-projects"] {
  #content-area {
    margin-top: 50px;

    @include media($tablet) {
      margin-top: 20px;
    }
  }
}

.section-our-research {
  .views-field-field-project-filter-thumbnail {
    img {
      height: 165px !important;
      object-fit: cover;
    }
  }
}

.page-research-development {
  #breadcrumb {
    display: none;
  }
}

/*------------------------------------------------------------------------

GALLERY

-------------------------------------------------------------------------*/
.node-type-gallery,
.node-type-video-gallery-item {
  #main {
    width: 100% !important;
    position: relative;

    #content {
      @include span-columns(4 of 12);
      @include pad(0 40px 0 0);

      #block-system-main {
        position: static !important;
      }

      #content-header {
        margin-top: 90px !important;

        @include media($mobile) {
          margin-top: 40px !important;
        }

        h1.title {
          width: 100% !important;
          margin-bottom: 30px !important;
        }
      }

      .field-label {
        font-family: 'HelveticaNeueW01-57Cn' !important;
        color: #595959 !important;
        font-size: 16px !important;
        margin-bottom: 5px;
      }

      .field-name-field-gallery-date,
      .field-name-field-gallery-location {
        .field-label {
          float: left;
        }
      }

      .field-name-field-gallery-date,
      .field-name-field-gallery-location {
        .field-item {
          font-family: 'HelveticaNeueW01-57Cn' !important;
          color: #595959 !important;
          font-size: 16px !important;
          margin-bottom: 5px;
        }
      }

      .field-name-field-gallery-location {
        margin-top: 15px;
      }

      .field-name-field-related-galleries {
        >.field-label {
          font-size: 24px !important;
          font-family: 'HelveticaNeueW01-57Cn';
          margin: 25px 0 15px 0;
          color: $mint !important;
          font-weight: normal;
        }

        >.field-items>.field-item {
          position: relative;
          overflow: hidden;
          margin-bottom: 15px;
          @include span-columns(2 of 4);

          &.odd {
            @include omega();

            @include media($laptop) {
              margin-right: 2.22563% !important;
            }

            @include media($tablet) {
              margin-right: 0 !important;
            }
          }

          &.even {
            @include media($tablet) {
              // margin-right: 0 !important;
            }
          }

          &:last-of-type {
            @include media($laptop) {
              margin-right: 0 !important;
            }
          }

          header {
            position: absolute;
            z-index: 1;
            display: block;
            width: 100%;

            h2 a {
              font-size: 18px;
              font-family: 'HelveticaNeueW01-57Cn';
              background-color: transparent;
              background-color: rgba(0, 0, 0, 0.8);
              display: block;
              width: 100%;
              padding-top: 10px;
              padding-left: 10px;
              padding-right: 10px;
              padding-bottom: 200px;
              line-height: 1.4em;
              color: #fff !important;
              text-decoration: none !important;
            }
          }

          img {
            display: block;
            margin-bottom: 0 !important;
          }

          @include media($laptop) {
            @include span-columns(3 of 12);
            @include omega(4);
          }

          @include media(max-width 850px) {
            @include span-columns(2 of 4);
          }

          @include media($mobile) {
            width: 100%;
          }
        }
      }

      @include media($laptop) {
        @include span-columns(12 of 12);
        @include pad(0 0 0 20px);

        #content-area {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      // @include media($tablet) {
      // 	@include span-columns(2 of 8);
      // 	@include pad(0 0 0 20px);
      // 	h1.title {
      // 		padding-left: 0 !important;
      // 	}
      // 	#content-area{
      // 		padding-left: 0 !important;
      // 		padding-right: 0 !important;
      // 	}
      // }
      @include media($laptop) {
        @include span-columns(4 of 4);
        @include pad(0 10px);
        margin-left: 0 !important;
        padding-top: 70%;

        h1.title {
          padding-left: 0;
        }

        #main #content-area {
          padding: 0 !important;
        }
      }
    }

    #sidebar-second {
      @include span-columns(8 of 12);
      @include omega();
      padding-top: 90px !important;
      padding-left: 0 !important;
      position: relative;

      .breadcrumb {
        margin: 0;
      }

      .field-name-field-gallery-images {
        padding-top: 40px;

        img {
          max-width: 100%;
          width: 100% !important;
          width: auto;
          height: auto;
        }
      }

      .field-name-field-gallery-images-slider {
        margin-top: 30px !important;

        .flex-viewport {
          padding-top: 11px;
          overflow-y: visible !important;
        }

        .field-item {
          position: relative;
          padding: 10px;
          margin-right: 30px;
          background-color: transparent;
          -webkit-transition: background-color 0.3s ease;
          -moz-transition: background-color 0.3s ease;
          -o-transition: background-color 0.3s ease;
          transition: background-color 0.3s ease;
          overflow: visible;

          img {
            width: 100%;
            height: auto;
            display: block;
          }

          &::before {
            content: '';
            display: block;
            width: 15px;
            height: 11px;
            z-index: 5;
            position: absolute;
            top: -1px;
            left: 50%;
            margin-left: -7px;
            opacity: 0;
            background-color: transparent;
            background-image: none !important;
            -webkit-transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.2s;
            -moz-transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.2s;
            -o-transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.2s;
            transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.2s;
          }

          &.flex-active-slide {
            background-color: $mint;

            &::before {
              top: -11px;
              opacity: 1;
              background-image: url('../images/gal_thumb_arrow.png') !important;
            }
          }
        }
      }

      @include media($laptop) {
        .flex-next {
          right: 20px !important;
        }
      }

      @include media($tablet) {
        @include span-columns(5 of 8);
        @include pad(0 20px 0 0);

        .field-name-field-gallery-images {
          padding-top: 0 !important;
        }

        .flex-next {
          right: 20px !important;
        }

        .flex-prev {
          left: 0 !important;
        }

        .flex-direction-nav a {
          top: 40% !important;
        }
      }

      @include media($laptop) {
        @include span-columns(4 of 4);
        padding: 0 !important;
        margin: 0 !important;
        position: absolute;
        top: 0;

        .field-name-field-gallery-images {
          padding-top: 0;
        }

        .breadcrumb,
        .field-name-field-gallery-images-slider {
          display: none;
        }

        .flex-direction-nav {
          a {
            top: 50%;
            margin-top: -22px;
          }

          .flex-prev {
            left: 0 !important;
          }

          .flex-next {
            right: 0 !important;
          }
        }
      }
    }
  }

  #top-footer {
    margin-top: 0 !important;
  }
}

.node-type-video-gallery-item {
  @media screen and (max-width: 1200px) {
    #main #content {
      width: 100% !important;
      padding-top: 60%;
    }

    #main #sidebar-second {
      width: 100%;
      position: absolute;
      top: 0;
    }
  }

  @include media($tablet) {
    #main #content #content-header {
      margin-top: 0 !important;
    }
  }
}

/***************************************************************

Related galleries field on

 ***************************************************************/
#block-views-gallery-block {
  .block-inner {
    border-top: 1px dotted $mint;
    margin-top: 20px;

    &>h2 {
      display: inline;
      padding: 0 15px;
      margin-left: 15px;
      background: #fff;
      position: relative;
      top: -0.6em;
      font-family: 'HelveticaNeueW01-57Cn';
      color: #6da5aa;
      font-size: 1.5em;
      line-height: 1em;
      letter-spacing: 1px;
    }
  }

  .views-row {
    @include span-columns(2 of 4);
    margin-top: 20px;
    overflow: hidden;
    position: relative;

    header {
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;

      h2 a {
        font-size: 18px;
        font-family: 'HelveticaNeueW01-57Cn';
        background-color: transparent;
        background-color: rgba(0, 0, 0, 0.8);
        display: block;
        width: 100%;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 200px;
        line-height: 1.4em;
        color: #fff !important;
        text-decoration: none !important;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &.views-row-even {
      @include omega();
    }

    &.views-row-1,
    &.views-row-2 {
      margin-top: 10px;
    }
  }
}

/***************************************************************


Author:

 ***************************************************************/

.node-type-webform {
  input[type="submit"] {
    background: #2f7eb1;
    /* Old browsers */
    background: -moz-linear-gradient(top,
        #2f7eb1 0%,
        #1c5c86 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear,
        left top,
        left bottom,
        color-stop(0%, #2f7eb1),
        color-stop(100%, #1c5c86));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,
        #2f7eb1 0%,
        #1c5c86 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,
        #2f7eb1 0%,
        #1c5c86 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #2f7eb1 0%, #1c5c86 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #2f7eb1 0%, #1c5c86 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f7eb1',
        endColorstr='#1c5c86',
        GradientType=0);
    /* IE6-9 */
    bottom: 0;
    color: white;
    padding: 5px 10px;
    border-radius: 2px;
  }

  input:hover[type="submit"] {
    opacity: .8;
  }

  input[type="email"] {
    border: 1px solid #009899;
    border-radius: 5px;
    color: #595959;
    font-family: 'Helvetica W01 Roman', helvetica, arial, sans-serif;
    font-size: 1em;
    height: 25px;
    padding: 2px 5px;
  }

  #main #content .title {
    margin-bottom: 0;
    margin-top: 50px;
  }
}

.page-node-done {
  #main #content .title {
    margin-top: 50px;
  }
}

/***************************************************************

2nd Level Pages Large
Author:

 ***************************************************************/

.node-type-second-level-page {
  .field-name-field-slogan {
    font-size: 2em;
    font-family: 'HelveticaNeueW01-57Cn';
    letter-spacing: -0.1px;
  }

  #sidebar-first {
    margin-top: 20px !important;
  }

  #page #main {
    margin-top: 0;
  }

  .flexslider .slides img {
    margin-top: 45px;
  }
}

/* end of .node-type-second-level-page */

.secondary-language {
  .field-name-field-slogan {
    color: #accf4f;
  }
}

/***************************************************************

2nd Level Pages
Author:

 ***************************************************************/

@include media($laptop) {
  #page {
    padding: 40px;
  }

  #footer {
    padding: 40px;
  }

  .node-type-second-level-page {
    #main[style] {
      margin-top: -60px !important;
    }

    .flexslider .slides img {
      margin-top: 0px !important;
    }
  }
}

/* end of media query */

/***************************************************************

2nd Level Pages Mobile &
Author:

 ***************************************************************/

@include media(max-width 640px) {
  #page {
    padding: 0px;
  }
}

@include media($tablet) {
  .page-node.secondary #main #content p {
    padding: 0 !important;
  }

  .page-node.secondary #main #content .teaser a {
    display: block !important;
    height: auto !important;
    text-align: center !important;
    width: auto !important;
  }
}

/* end of media query */

/**********************************
 *
 *  Global
 *
 *********************************/
.chosen-container-single .chosen-single {
  border: 1px solid #bcbcbc !important;
  border-width: 0 1px 1px;
  box-shadow: none;

  div b {
    background: url('../images/arrow.png') !important;
  }
}

/***************************************************************
;
TEDxBVC form
Author:

 ***************************************************************/

.page-tedx {
  h1.title {
    width: 100% !important;
    margin-bottom: 0;
    margin-top: 20px !important;
  }
}

.page-tedx.no-sidebars #main #content {
  width: 100% !important;
}

// General chosen
#filters-container {
  font-family: 'Helvetica W01 Roman';
  color: $darkMint;
  font-size: 0.9em;
  //line-height: 40px;
  padding-right: 5px;

  input {
    color: $darkMint;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    &[type="text"] {
      border: 2px solid #cedcdf;
      outline: none;
      height: 23px;
      padding: 4px;
    }

    &[type="submit"] {
      background: #c8d9dc;
      color: $green;
      height: 23px;
      border: none;
      padding: 0 5px;
    }
  }

  .chosen-container-single .chosen-single {
    color: $darkMint;
  }

  .chosen-container {
    font-size: 13px;

    li {
      color: $darkMint !important;
      font-size: 13px !important;

      &.highlighted {
        color: #fff !important;
      }
    }
  }

  @include media($mobile) {
    padding: 20px 0;
  }
}

.chosen-drop {
  .chosen-search.chosen-search.chosen-search {
    padding: 3px 1px !important;

    input[type="text"] {
      width: 137px !important;
      margin: 1px 8px !important;
    }
  }

  .chosen-results.chosen-results.chosen-results {
    margin-left: 0 !important;
    margin-bottom: 0 !important;

    li {
      font-size: 13px !important;

      &.highlighted {
        background-color: $mint;
        color: #fff !important;
      }
    }
  }
}

/***************************************************

          Hovercard styles

***************************************************/
.hc-preview {
  position: relative;
}

.hc-name {
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.hc-details {
  left: -3px;
  margin-right: 80px;
  text-align: left;
  font-family: Sans-serif !important;
  font-size: 12px !important;
  color: #666 !important;
  line-height: 1.5em;
  position: absolute;
  top: -3px;
  -moz-box-shadow: 0px 0px 20px #aaa;
  -webkit-box-shadow: 0px 0px 20px #aaa;
  box-shadow: 0px 0px 20px #aaa;
  display: none;
}

.hc-pic {
  width: 70px;
  margin-top: -1em;
  float: right;
}

.hc-details-open-left {
  left: auto;
  right: -3px;
  text-align: right;
  margin-left: 80px;
  margin-right: 0;
}

.hc-details-open-left>.hc-pic {
  float: left;
}

.hc-details-open-top {
  bottom: -3px;
  top: auto;
}

.hc-details-open-top>.hc-pic {
  margin-top: 10px;
  float: right;
}

.hc-details .s-action {
  position: absolute;
  top: 8px;
  right: 5px;
}

.hc-details .s-card-pad {
  border-top: solid 1px #eee;
  margin-top: 10px;
  padding-top: 10px;
  overflow: hidden;
}

.hc-details-open-top .s-card-pad {
  border: none;
  border-bottom: solid 1px #eee;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.hc-details .s-card .s-strong {
  font-weight: bold;
  color: #555;
}

.hc-details .s-img {
  float: left;
  margin-right: 10px;
  max-width: 70px;
}

.hc-details .s-name {
  color: #222;
  font-weight: bold;
}

.hc-details .s-loc {
  float: left;
}

.hc-details-open-left .s-loc {
  float: right;
}

.hc-details .s-href {
  clear: both;
  float: left;
}

.hc-details .s-desc {
  float: left;
  font-family: Georgia;
  font-style: italic;
  margin-top: 5px;
  width: 100%;
}

.hc-details .s-username {
  text-decoration: none;
}

.hc-details .s-stats {
  display: block;
  float: left;
  margin-top: 5px;
  clear: both;
  padding: 0px;
}

.hc-details ul.s-stats li {
  list-style: none;
  float: left;
  display: block;
  padding: 0px 10px !important;
  border-left: solid 1px #eaeaea;
}

.hc-details ul.s-stats li:first-child {
  border: none;
  padding-left: 0 !important;
}

.hc-details .s-count {
  font-weight: bold;
}

/* TODO: Find a better solution to fixing padding when the highlighted region
 * is used on a page. */
#highlighted~#page {
  &>#main {
    margin-top: 20px;
  }
}

.krumo-root {
  img {
    width: auto !important;
    height: auto !important;
  }

  &>ul {
    margin: 0 !important;

    &>li>.krumo-nest>ul {
      margin-left: 0 !important;
      margin-bottom: 0 !important;

      ul {
        margin-bottom: 0 !important;
      }

      li.krumo-child {
        margin-bottom: 0 !important;
        font-size: 13px !important;

        em,
        strong {
          font-family: arial !important;
          color: #000 !important;
        }
      }
    }
  }
}

@import "_elements.scss";
@import "_blog.scss";
@import "_events.scss";
@import "_products.scss";
@import "_projects.scss";
@import "_viewsDisplayToggle.scss";
@import "_featured.scss";
@import "_departments.scss";
@import "_pageHeadings.scss";
@import "_fancyCategories.scss";
@import "elements/_buttons.scss";
@import "elements/_landing_banner.scss";
@import "elements/_page_attachments.scss";
@import "elements/_comments.scss";
@import "elements/_footer.scss";
@import "pages/_front.scss";
@import "pages/_top_level.scss";
@import "pages/_second_level.scss";
@import "pages/_tool.scss";
@import "pages/_resource_finder.scss";
@import "pages/_workshops.scss";
@import "pages/_user_profile.scss";
@import "pages/_user-register.scss";
@import "pages/_centre_members.scss";
@import "pages/_search-results.scss";
@import "_shame.scss";

/*test*/
.node-type-page.secondary #content-header {
  @include media($mobile) {
    padding: 0 20px;
  }
}

.node-type-blog.section-blog {
  h1.title {
    @include media($mobile) {
      padding: 0 20px !important;
    }
  }
}

.page-node.with-subnav {
  #content-header h1.title {
    @include media($tablet) {
      // padding-left: 0 !important;
    }
  }
}

// fix image stretch
img.media-image {
  width: 100%;
  height: auto;
}

.page-about-us-video-gallery {
  .left {
    width: 100%;
  }

  .right {
    display: none;
  }
}

.content h3 {
  margin-top: 0.3em;
  margin-bottom: 1.5em;
}

#main #content h1.title {
  margin-bottom: 0.75em;
}

.field-name-field-after-text {
  float: left;
  width: 100%;
  display: block;
}

.node-type-project #content-area {
  margin-top: 50px !important;
}

.sitemap-image img {
  width:100%;
}
