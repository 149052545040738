/* new footer styles */

#top-footer-outer {}

#top-footer {
  padding-top: 80px !important;
  padding-bottom: 80px !important;

  .address,
  .buttons {
    @include span-columns(3 of 12);
  }

  .main-links .link {
    @include span-columns(3 of 6);
    @include omega(2n);
  }

  .buttons {
    float: right !important;
  }

  @include media($tablet) {

    .address,
    .buttons {
      @include span-columns(4 of 8);
    }

    .buttons {
      @include omega();
    }

    .main-links {
      @include span-columns(8 of 8);
    }
  }

  @include media($mobile) {
    padding-top: 25px !important;
    padding-bottom: 25px !important;

    .address,
    .buttons,
    .main-links .link {
      @include span-columns(4 of 4);
      @include omega();
      margin-bottom: 1.6em;
    }
  }

  .address h4 {
    width: 90%;
    margin-bottom: 1em;
  }

  // type overrides
  h4 {
    font-family: 'Helvetica W01 Cn', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 1em;
  }

  p {
    font-family: 'Helvetica W01 Cn', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #595959;
  }

  .address p:last-of-type {
    margin-bottom: 0;
  }

  .main-links p {
    margin-top: 0;
  }

  a {
    color: #595959 !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h4 a:hover {
    color: #2067a9 !important;
    text-decoration: none;
  }

  // element overrides
  .button--large {
    width: 100%;
    max-width: 240px;
    font-size: 17px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #bbbbbb;
    color: #595959 !important;
    font-weight: 800;
    border: 4px solid #a9a9a9;
    text-transform: uppercase;
    letter-spacing: 3px;
    float: right;

    &:first-of-type {
      margin-top: 0;
    }

    @include media($mobile) {
      max-width: 100%;
    }
  }

}

#footer-outer {
  background: #2067a9;

  #footer {
    background: none;
    padding-top: 20px;

    @include media($tablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .region-region-footer {
    float: left;
    width: 100%;
  }

  // text overrides
  p,
  a {
    color: #fff;
  }

  #block-block-1 {
    display: block;
    margin-bottom: 0 !important;

    .content {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  #block-block-1 a {
    display: inline-block;
    // float: left;
    // margin-right: 20px;
  }

  #block-block-1 p {
    display: block !important;
    float: left;
    margin-top: 10px !important;
    line-height: 30px;
  }

  #block-menu-menu-footer-menu {
    display: none;
  }

  .social {
    width: 10%;
    margin-top: 15px;
    float: right;
  }

  @media screen and (max-width: 1055px) {
    .region-region-footer {
      width: 75%;

      #block-menu-menu-footer {
        float: left;
        padding-left: 20px;
      }
    }

    .social {
      width: 25%;
      text-align: right;
    }

  }

  @include media($tablet) {
    #block-block-1 {
      margin-left: 0 !important;
    }

    #block-menu-menu-footer-menu {
      float: left;
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 650px) {

    .region-region-footer,
    .social,
    #block-menu-menu-footer-menu {
      width: 100%;
      text-align: center;
      margin-right: 0 !important;
      padding-right: 0;
    }

    #block-block-1 {
      margin-bottom: 0;
    }

    #block-block-1 a {
      display: inline;
      float: none;
    }

    #block-block-1 p {
      float: none;
      margin-bottom: 0;
    }

    #block-menu-menu-footer-menu {
      margin-top: 0;
    }

    #block-menu-menu-footer-menu ul {
      width: 100%;
      text-align: center;
    }

    #block-menu-menu-footer-menu ul li {
      float: none !important;
    }
  }

  @media screen and (max-width: 450px) {}
}


.page-node .top-link.top-link {

  bottom: 15px;
  right: 15px;
  border-radius: 0;
  box-shadow: none;
}

.top-link a {
  display: block;
  width: 35px;
  height: 35px;
  background: url('../images/back-to-top.png') center center no-repeat;
  text-indent: -9999px;
}
