.secondary-header .flyout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 11;

  .container {
    @include outer-container;
  }
}
#react-megamenu {
  box-shadow: 0px 30px 50px rgba(0,0,0,0.7);
}
.block-centers-megamenu {
  $areas: (
    Language: $areaLanguage,
    English: $areaLanguage,
    Workforce: $areaWorkforce,
    Career: $areaWorkforce,
    Intercultural: $areaIntercultural,
    Research: $areaResearch
  );
  @each $name, $bgcolor in $areas {
    .background.#{$name} {
      background: darken($bgcolor, 5%);
      .departments, .sections {
        background: $bgcolor;
      }
    }
  }

  .bottom {
    height: 300px;
  }

  .departments,
  .sections,
  .posts {
    list-style-type: none;
  }

  .departments,
  .posts {
    li {
      @include span-columns(2.5 of 10);
    }
  }

  .departments,
  .sections {
    font-family: 'HelveticaNeueW01-57Cn';
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;

    li {
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
      cursor: pointer;

      a{
        color: #fff;
        text-decoration: none;
      }

      &.active {
        background: rgba(#fff, 0.2);
      }
    }
  }

  .departments {
    @include clearfix;

    ul {
      @include span-columns(10 of 12);
      @include shift(2);
    }

    li {
      text-align: center;
      cursor: pointer;
    }
  }

  .sections {
    @include span-columns(2 of 12);
    width: 14.46%;
    height: 100%;

    li {
      margin-bottom: 20px;
    }
  }

  .posts {
    @include span-columns(10 of 12);

    .post {
      background: rgba(#fff, 0.2);
      margin: 35px 12px;
      padding: 12px;

      img {
        max-width: 100%;
        width: 100%;
      }

      a, h4 {
        color: #fff;
        text-decoration: none;
      }

      h4{
        font-size: 14px;
        font-family: 'Helvetica W01 Bd Cn', sans-serif;
        font-weight: normal;
        margin-top: 10px;
      }

      .timestamp{
        font-style: italic;
        font-size: 12px;
      }
    }
  }
}

#block-centers_megamenu-engage{
  box-shadow: 0px 30px 50px rgba(0,0,0,0.7);
}

#flyout-discover {
  background: $areaWorkforce;
  box-shadow: 0px 30px 50px rgba(0,0,0,0.7);

  .left, .right {
    @include span-columns(6 of 12);
    padding: 36px 0 25px;
  }

  .column {
    @include span-columns(1 of 3);
  }

  .video-container {
    position: relative;
    /* Video is 16:9 with 30px for YouTube bars. */
    // padding-top: percentage(9/16);
    padding-bottom: 30px;
    background: url('../images/ring.gif') center center no-repeat;
    background-size: 30px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      // height: 100%;
    }
  }

  .menu {
    .menu {
      padding-top: 12px;
    }

    li {
      list-style: none;
      padding: 12px 0;
      margin: 0;
    }

    a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .column > .menu > li.first{
    padding-top: 0;
  }

  /* Submenu styling */
  .menu .menu {
    a {
      font-weight: normal;
      color: #4db9ff;
    }

    li {
      border-bottom: 1px dotted #fff;
    }

    li.first {
      border-top: 1px dotted #fff;
    }

    li.last {
      margin-bottom: 24px;
    }
  }
}
