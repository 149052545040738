@mixin border-image($images) {
  -webkit-border-image: border-add-prefix($images, webkit);
     -moz-border-image: border-add-prefix($images, moz);
       -o-border-image: border-add-prefix($images, o);
          border-image: border-add-prefix($images);
}

@function border-add-prefix($images, $vendor: false) {
  $border-image: ();
  $images-type:  type-of(nth($images, 1));
  $first-var:    nth(nth($images, 1), 1);          // Get type of Gradient (Linear || radial)

  // If input is a gradient
  @if $images-type == string {
    @if ($first-var == "linear") or ($first-var == "radial") {
      @for $i from 2 through length($images) {
        $gradient-type: nth($images, 1);           // Get type of gradient (linear || radial)
        $gradient-args: nth($images, $i);          // Get actual gradient (red, blue)
        $border-image:  render-gradients($gradient-args, $gradient-type, $vendor);
      }
    }

    // If input is a URL
    @else {
      $border-image: $images;
    }
  }

  // If input is gradient or url + additional args
  @else if $images-type == list {
    @for $i from 1 through length($images) {
      $type: type-of(nth($images, $i));           // Get type of variable - List or String

      // If variable is a list - Gradient
      @if $type == list {
        $gradient-type: nth(nth($images, $i), 1); // Get type of gradient (linear || radial)
        $gradient-args: nth(nth($images, $i), 2); // Get actual gradient (red, blue)
        $border-image:  render-gradients($gradient-args, $gradient-type, $vendor);
      }

      // If variable is a string - Image or number
      @else if ($type == string) or ($type == number) {
        $border-image: append($border-image, nth($images, $i));
      }
    }
  }
  @return $border-image;
}

//Examples:
// @include border-image(url("image.png"));
// @include border-image(url("image.png") 20 stretch);
// @include border-image(linear-gradient(45deg, orange, yellow));
// @include border-image(linear-gradient(45deg, orange, yellow) stretch);
// @include border-image(linear-gradient(45deg, orange, yellow) 20 30 40 50 stretch round);
// @include border-image(radial-gradient(top, cover, orange, yellow, orange));
