// Variables
// ---------
// Use the same color all over the place? Need to do
// some math with height and width and text size?
// Sass supports variables as well as basic math
// operations and many useful functions.
//
// For complete documentation:
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#variables_
//
// Available variables:
// SASS:				http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#variables_
// Bourbon:				http://bourbon.io/docs/

// Colour variables
$grey: #ccc;
$grey_light: lighten($grey, 20%);
$grey_dark: darken($grey, 20%);

// Margin/padding variables
$margin: $gutter;
$padding: $gutter;
