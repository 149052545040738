.page-workshops{
  #breadcrumb{
    display: none;
  }
  h1.title.title{
    margin-top: 40px;
    @include media($mobile){
      margin-top: 0 !important;
    }
  }
}

.node-type-workshop .node-workshop .content {
  /* Contains left/right columns and needs a reset. */
  &,
  .webinar-info {
    @include outer-container;
  }

  .left, .right {
    @include span-columns(6 of 12);
    @include media($tablet) {
      @include span-columns(8 of 8);
    }
  }

  .left {
    position: relative;
  }
  .field {
    margin-bottom: 20px;
  }
  .field-collection-container{
    border-bottom: none;
    
    .field-collection-view{
      margin: 0px;
    }
  }

  label {
    margin-bottom: 10px;
  }

  .session-name {
    font-style: italic;
  }

  .session-time {
    margin-bottom: 10px;
  }

  .overlay {
    width: 100%;
    padding-top: 45%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    z-index: 4;
    &:hover {
      opacity: 0.8;
    }
    span.status {
      text-transform: uppercase;
      display: block;
      width: 40%;
      left: 30%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px;
      border: 3px solid #fff;
      border: 3px solid rgba(255, 255, 255, 0.7);
      color: #fff;
      color: rgba(255, 255, 255, 0.7);
    }
    &.completed span.status {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.7);
      border: 3px solid transparent;
      color: #000;
      color: rgba(0,0,0, 0.7);
    }
  }

  .testimonials,
  .webinar-info,
  .participating-members {
    @include before-dotted();
  }

  .cta .button + .button {
    margin-left: 8px;
  }

  .webinar-info,
  .participating-members {
    h5 {
      color: #000;
    }
  }

  .participating-members {
    .user-picture {
      float: left;
      + .user-picture {
        padding-left: 8px;
      }
    }

    .count {
      line-height: normal;
      font-size: 5em;
      font-weight: bold;
      color: #128087;
    }

    .view-all {
      clear: both;
    }
  }

  .left .messages {
    padding: 2em;
    text-align: center;

    &.error {
      background: #ffdada;
      border: 1px solid #FF9B9B;
    }
    &.status {
      background: #E1FFDA;
      border: 1px solid #BAF0A0;
    }

    h3 {
      width: 100%;
      sup {
        margin-left: 2px;
        font-size: .6em;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
  .flexslider .flex-direction-nav {
    display: none;
  }
}

// Override some styles that are using IDs instead of classes.
#main #content .node-workshop .content {
  img.logo,
  .participating-members img {
    width: auto;
  }
}

// workshop views styles
.node-type-network .view-display-id-workshops_filter {
  margin-top: 20px;
}

.view-display-id-workshops_filter{
  .split-right a{
    text-decoration: none !important;
  }
  .views-display-toggle-card, .cbp{
    .cbp-item {
      height: 350px !important;
    }
    .split-left {
      height: 225px !important;
      overflow: hidden;
      img {
        margin: 0 auto;
        // min-height: 100%;
        // min-width: 100%;
        // max-width: none !important;
        height: auto !important;
        width: 100% !important;
      }
    }
    @media screen and (max-width: 593px)  {
      .split-left img {
        width: auto !important;
        min-width: 0;
        max-width: 100% !important;
        height: auto !important;
        max-height: 225px;
      }
    }
    .split-right {
      .overlay {
        width: 100%;
        height: 225px;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        span{
        }
        &:hover {
          opacity: 0.8;
        }
        span.status {
          text-transform: uppercase;
          display: block;
          width: 40%;
          margin: 0 auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 10px;
          border: 3px solid #fff;
          border: 3px solid rgba(255, 255, 255, 0.7);
          color: #fff;
          color: rgba(255, 255, 255, 0.7);
        }
        &.completed{
          opacity: 0; // client request
        }
        &.completed span.status {
          background-color: #fff;
          background-color: rgba(255, 255, 255, 0.7);
          border: 3px solid transparent;
          color: #000;
          color: rgba(0,0,0, 0.7);
        }
      }
      .date {
        margin-top: 7px;
      }
      .area-link {
        display: none;
      }
      .body p{
        display: block !important;
        font-size: 1em;
        line-height: 1.3em;
        color: #595959;
      }
    }
  }
  .views-display-toggle-list {
    .split-left{
      img{
        max-width: 300px;
      }
    }
    .split-right {
      .title a {
        font-size: 24px;
        text-decoration: none;
      }
      .date {
        margin-top: 7px;
        margin-bottom: 20px;
      }
      .status {
        margin-top: 7px;
        color: #fff;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 12px;
        width: 80px;
        display: block;
        text-align: center;
      }
      .completed span{
        background-color: #e65050;
      }
      .upcoming span {
        background-color: #1a9e98;
      }
    }
  }
}

.view-display-id-workshops_featured {
  .split-left, .split-right {
    @include span-columns(6 of 12);
  }
  .overlay {
    display: none;
  }
  li p{
    font-size: 1em;
  }
}

.view-display-id-workshops_featured, 
.view-display-id-workshops_filter {
  .date {
    margin-bottom: 7px;
    font-weight: bold;
    font-style: italic;
  }
  .title {
    margin: 7px 0;
    font-size: 18px;
  }
  .read-more {
    color: #595959;
  }
}

.view-display-id-workshops_filter .date{
  font-family: 'HelveticaNeueW01-57Cn', sans-serif;
  color: #000;
}

.view-display-id-workshops_featured{
  .title{
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    font-size: 2.3em;
    line-height: 1em;
    letter-spacing: -1px;
  }
  .title a{
    text-decoration: none;
  }
  .read-more{
    font-style: italic;
  }
  .date{
    font-family: 'HelveticaNeueW01-57Cn', sans-serif;
    color: #003333;
    font-style: normal;
  }
}

#block-views--exp-workshops-workshops_filter ~ .view-workshops {
  margin-top: 20px;
  .views-display-toggle-list{
    @include span-columns(9 of 12);
    float: none;
    @include media($laptop){
      @include span-columns(12 of 12);
    }
  }
}

.page-workshops {
  #highlighted ~ #page > #main{
    margin-top: 0;
  }
}
