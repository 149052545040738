.node-type-tool #content-header {
  @include media($tablet) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.node-type-resource-finder {
  #content-header {
    @include media($tablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  #main {
    background: none;

    #content .node .content .field-name-field-image img {
      width: auto;
      height: auto;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  &.page-workshops #main {
    background: none;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  h2 {
    color: #6DA5AA;
    font-weight: 300;
    letter-spacing: -1px;
    line-height: 1.3em;
    font-size: 2em;
    margin-bottom: 15px;
    font-family: 'HelveticaNeueW01-57Cn';
  }

  .left,
  .right {
    @include span-columns(6 of 12);

    @include media($tablet) {
      @include span-columns(8 of 8);
    }
  }

  .content {
    @include clearfix();
  }

  .view-display-id-tool_list {
    margin-top: 20px;
  }

  .network-finders {
    @include clearfix();

    .content {
      @include span-columns(12 of 12);
      margin: 60px 0;

      .finder {
        @include span-columns(2 of 12);
        @include shift(1.5);
        text-align: center;

        img {
          width: auto !important;
          height: auto;
          margin-bottom: 20px;
        }

        @include media($tablet) {
          @include span-columns(2 of 8);
          @include shift(0.5);
          width: 26.467%;
        }

        @include media($mobile) {
          @include span-columns(4 of 4);
          @include shift(0);
          margin-bottom: 20px;
        }
      }
    }

    .outline-button.green {
      display: block;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.9em;
      font-weight: bold;
      color: #7aafb5;
      width: auto;
      border: 5px solid #c3dade;
      padding: 10px;
      text-decoration: none;
      background-color: transparent;
      transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

      &:hover {
        color: #fff;
        background-color: $mint;
      }

      &.no-hover {
        &:hover {
          color: #7aafb5;
          background-color: transparent;
        }
      }
    }
  }

  .form-radios {
    margin-top: 3px;
  }

  .form-item-toolboxes {
    display: inline;

    label {
      margin-right: 5px;
      color: #fff;
      font-family: 'HelveticaNeueW01-57Cn';
      font-weight: normal;
    }
  }
}
