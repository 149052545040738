.field-name-field-testimonial-slider {
  .field-collection-item-field-testimonial-slider {
    background: transparent url('../images/testimonial-icon.png') bottom left no-repeat;
  }
  .flexslider {
    margin-bottom: 30px;
  }
  .flex-control-nav {
    width: auto;
    bottom: 0;
    right: 0;

    @include media($mobile){
      position: static;
      float: left;
      margin-top: 1em;
    }
  }
  .flex-direction-nav {
    display: none;
  }
  // make the speech bubble 
  .field-name-field-testimonial-text {
    position: relative;
    background-color: #ededed;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 30px;
    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 30px;
      width: 0; 
      height: 0; 
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 15px solid #ededed;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -17px;
      left: 28px;
      width: 0; 
      height: 0; 
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 17px solid #ccc;
    }
  }
  .field-name-field-testimonial-attribution {
    margin-left: 35px;
    font-weight: bold;
  }
  .field-name-field-attribution-role {
    margin-left: 35px;
    font-style: italic;
  }
}
