@mixin title-dotted() {
  font-family: 'HelveticaNeueW01-57Cn';
  font-size: 20px;
  color: $mint;

  background: #fff;
  display: inline-block;
  letter-spacing: 1px;
  line-height: 20px;
  margin-left: 15px;
  padding: 0 15px;
  position: relative;
  top: 5px;
  & ~ .content {
    @include before-dotted();
    margin-top: -20px;
    padding-top: 40px;
  }
}

@mixin before-dotted() {
  border-top: 1px dotted $mint;
  padding-top: 20px;
  margin-top: 20px;
}

/**
 * Also on:
 * .page-intercultural-development-intercultural-centre
 * .page-node-secondary
 * .node-type-product #sidebar-first
 * .node-type-event-calendar #sidebar-first
 */
[id^="block-bvc_page_attachments"],
#block-views--exp-blog_list-blog_filter,
.tools,
#block-views-blog_list-featured_blog,
#block-views-project_list-featured_projects,
#block-views-project_list-project_filter,
#block-views-5fc50df80949aae18915285b2a6d6d2c,
#block-views-d4ce81f15f49adaf4a42ec7ff5474179,
#sidebar-first .block {
  h2.block-title {
    @include title-dotted();
    @include media($mobile){
      width: 100%;
      margin: 0;
      padding: 0;
      letter-spacing: 0;
      border-top: none;
    }
  }
}

.title.dotted {
  @include title-dotted();
}

.page-blog.section-blog #sidebar-first {
  .block-title {
    margin-left: 0;
    padding: 0;
    top: 0;
  }

  .block-title ~ .content {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

#sidebar-first .block-title ~ .content > div {
  margin-top: 20px;
}

h1 {
  margin: 0 0 40px 0;
  padding: 0;
}

h3 {
  margin-top: 0px;
  margin-bottom: 15px;
}

/* Move the sidebar down, unless the highlighted region is there */
#sidebar-first { margin-top: 60px; }
#highlighted ~ #page #sidebar-first { margin-top: 20px; }
@include media ($tablet) {
  #page #sidebar-first { margin-top: 20px; }
}

// some fixes for pages with block attachments
.paragraphs-items-field-page-attachments-full {
  h2.block-title ~ .content {
    margin-top: -15px;
    padding-top: 5px;
  }
  h2.block-title {
    width: 100%;
    a {
      margin-left: 10px;
      font-size: 0.7em;
    }
  }
  .field-type-blockreference h2.block-title{
    width: auto;
  }
  & > .field-name-field-page-attachments > .field-items > .field-item.even h2.block-title {
    background-color: #f5f5f5;
  }
  @include media($tablet){
    .field-name-field-page-attachment{
      padding: 0 40px;
    }
  }
}
