#block-views-front_feature_slideshow-block {
  .container {
    @include outer-container();
    text-align: center;
    padding: 6.875% 0;

    @include media($tablet) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .button {
    // @include linear-gradient(#b4d462, #9dbc4a);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b4d462+0,9dbc4a+100 */
    background: #b4d462;
    /* Old browsers */
    background: -moz-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b4d462), color-stop(100%, #9dbc4a));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #b4d462 0%, #9dbc4a 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #b4d462 0%, #9dbc4a 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b4d462', endColorstr='#9dbc4a', GradientType=0);
    /* IE6-9 */
    /* We need to remove the important in _custom.scss for .button */
    // background-image: url('../images/lock.png');
    padding: 0 32px;
    color: #033 !important;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 50px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
  }

  h1 {
    font-size: 3em;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.7);
    letter-spacing: 0 !important;

    @include media($tablet) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  h1+p {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    font-style: italic;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);

    @include media($tablet) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
