.view-display-id-tool_list,
.view-display-id-tool_list_category,
.view-display-id-tool_list_tag,
.view-display-id-event_attach {

  table{
    margin: 0;
    width: 100%;
    border: none;
  }
  thead tr th {
    background-color: #859c9c;
    border-left: 5px solid #859c9c;
    font-weight: 300;
    height: 60px;
    border-bottom: 10px solid #fff;
    vertical-align: middle;
    color: #fff;
    font-family: 'Helvetica W01 Cn';
    font-size: 16px;
    padding-left: 10px;
  }
  tbody tr td {
    background-color: #dedede;
    font-weight: 300;
    vertical-align: middle;
    padding: 10px;
    font-family: 'Helvetica W01 Cn';
    font-size: 16px;
    letter-spacing: 1px;
    color: #595959;
    .tablesaw-cell-label {
      display: block;
    }
    a.button {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
  .tablesaw-stack tbody tr {
    border-bottom: 10px solid #fff;
  }
  td {
    height: 80px;
  }
  th, td {
    @include media(max-width 640px) {
      height: auto;
      border-right: none;
    }
    border-right: 10px solid #fff;
    &:last-of-type {
      border-right: none;
    }
  }
}
