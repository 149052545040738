/*----------------
  
  Temporary fixes
  to move

-----------------*/

// Title layout is inconsistent at tablet background-size: 

// .page-workforce-development-workforce-and-corporate-training #content-area h1.title,
// .page-workforce-development-workforce-and-corporate-training #content-area h1.title,
// .page-workforce-development-certificate-courses #content-area h1.title,
// .page-workforce-development-services #content-area h1.title,
// .page-language-development-ell-programs #content-area h1.title,
// .page-networks-intercultural-centre #content-area h1.title,
// .page-research-development-applied-research-projects #content-area h1.title,
// .page-publications #content-area h1.title,
// .page-workshops #content-area h1.title{
//   @include media($tablet){
//     padding-left: 0 !important;
//   }
// }

// look into content header vs content-area h1.title
// Done and moved. Diff in padding based on parent (content-header vs. content-area)

ol{
  li{
    margin-left: 20px;
  }
}

#main a:hover,
#attached-pages a:hover{
  opacity: 1 !important;
}

#main .split-right .area-link a:hover,
#attached-pages .split-right a:hover{
  opacity: 0.8 !important;
}

.video-embed{
  width: 100%;
  iframe{
    width: 100%;
  }
}

.node-type-second-level-page .top-link, .node-type-network .top-link{
  @include media($tablet){
    left: auto !important;
    z-index: 9999;
  }
}

.chosen-container-single .chosen-single div{
  @include media($tablet){
    width: 15px !important;
  }
}

#highlighted .page-banner {
  width: 100%;
  overflow: hidden;
}

#highlighted .img-banner {
  max-width: 100%;
}