@mixin image-rendering ($mode:optimizeQuality) {

  @if ($mode == optimize-contrast) {
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimize-contrast;
  }

  @else {
      image-rendering: $mode;
  }
}
